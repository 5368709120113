<template>
  <section class="projectFilters bg-white px-5 py-5 md:py-1 lg:py-5 rounded shadow-sm">
    <div class="w-full flex flex-wrap xs:justify-center sm:justify-between items-center gap-3">
      <div class="flex flex-grow-0 justify-center md:justify-start flex-wrap items-center gap-2">
        <h1 style="font-size: 1.25rem !important">{{$t('projects.CommercialProjects')}}</h1>
        <span class="h-3 w-[1px] bg-gray-200"></span>
        <span class="text-sm">
          {{$t('projects.Total')}}
          <span class="text-text-dark">{{recordsCount}} {{$t('projects.results')}}</span>
        </span>
      </div>
      <div class="flex flex-grow-0 justify-center md:justify-start items-center filterSortingLabelContainer">
        <span>{{$t('projects.SortBy')}}:</span>
        <custom-select
          :items="sortByItems"
          class="customSort max-w-[200px] -mb-2 h-auto min-h-fit text-text-dark"
          :item-value="sortByValue"
          :data="sortByValue"
          @data-changed="changeFilter"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CustomSelect from "@/components/shared/customSelect/CustomSelect.vue";
import { mapGetters } from "vuex";

export default {
  components: { CustomSelect },
  props: ['recordsCount'],
  data() {
    return {
      sortByItems: ["All", "Commercial", "Housing"],
      sortByValue: "All"
    };
  },
  computed: {
    ...mapGetters(["projects"]),
  },
  methods: {
    changeFilter(value) {
      this.$emit("handleFilter", value)
    },
  },
};
</script>