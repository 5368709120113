var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userMessages"},[_c('v-row',{staticClass:"h-full"},[_c('v-col',{class:['h-full pb-0', { 'pl-0': !_vm.isEn }, { 'pr-0': _vm.isEn }],attrs:{"md":"4"}},[_c('div',{class:['userMessages__friends-section', { en: _vm.isEn }]},[_c('div',{staticClass:"userMessages__friends-section__search"},[_c('TextField',{attrs:{"label":"","placeholder":_vm.$t('messages.search'),"data":_vm.searchText,"classes":"px-4 pt-4","click":_vm.searchForUser},on:{"data-changed":function($event){_vm.searchText = $event}}})],1),_vm._l((_vm.userChats),function(chat){return _c('div',{key:chat.id,class:[
            'userMessages__friends-section__friend',
            'd-flex justify-space-between',
            { active: _vm.openedChatId === chat.id } ],on:{"click":function($event){return _vm.openChat(chat.id, chat.receiver)}}},[_c('div',{staticClass:"d-flex"},[_c('img',{class:['friend-avatar ml-2', { 'ml-0 mr-2': _vm.isEn }],attrs:{"src":chat.last_message.avatar,"contain":"","alt":chat.last_message.name,"width":"40px","height":"40px"}}),_c('div',[_c('p',{class:[
                  'friend-name mb-1',
                  { 'only-name': !chat.last_message.content_message } ]},[_vm._v(" "+_vm._s(chat.last_message.name)+" ")]),(chat.last_message.content_message)?_c('p',{staticClass:"last-message mb-0"},[_vm._v(" "+_vm._s(chat.last_message.content_message.substring(0, 50))+" "+_vm._s(chat.last_message.content_message.length > 50 ? "..." : "")+" ")]):_vm._e()])]),_c('div',{staticClass:"text-start d-flex flex-column align-end"},[_c('p',{staticClass:"date mb-1",attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.formatTime(chat.last_message.created_at))+" ")]),(chat.unreadedMessages)?_c('p',{staticClass:"\n                unreadedMessages-number\n                mb-0\n                d-flex\n                justify-center\n                align-center\n                mx-2\n              "},[_vm._v(" "+_vm._s(chat.unreadedMessages)+" ")]):_vm._e()])])})],2)]),_c('v-col',{class:['h-full pb-0', { 'pr-0': !_vm.isEn }, { 'pl-0': _vm.isEn }],attrs:{"md":"8"}},[_c('div',{staticClass:"userMessages__messages-section h-full"},[_c('div',{staticClass:"userMessages__messages-section__inner h-full"},[_c('div',{class:[
              'userMessages__messages-section__heading d-flex align-center',
              { en: _vm.isEn } ]},[(this.openedChatId !== -1)?_c('img',{class:['friend-avatar ml-2', { 'ml-0 mr-2': _vm.isEn }],attrs:{"src":_vm.user.avatar,"contain":"","alt":_vm.user.user_name,"width":"48px","height":"48px"}}):_vm._e(),(this.openedChatId !== -1)?_c('p',{staticClass:"friend-name mb-0 mx-2"},[_vm._v(" "+_vm._s(_vm.selectedChat.last_message.name)+" ")]):_vm._e()]),_c('div',{class:[
              'userMessages__messages-section__messages',
              { 'waiting-upload': _vm.selectedImages.length || _vm.selectedFile } ],attrs:{"id":"section__messages"}},[_c('div',{staticClass:"messages-container",attrs:{"id":"section-messages-container"}},[(this.openedChatId !== -1)?_c('div',_vm._l((_vm.selectedMessages),function(message){return _c('div',{key:message.id,class:[
                    'message d-flex align-end',
                    { 'justify-end': message.sender_id == _vm.user.id } ]},[_c('img',{class:[
                      'mb-7',
                      { 'order-0': message.sender_id != _vm.user.id },
                      { 'order-1': message.sender_id == _vm.user.id } ],attrs:{"src":message.sender_id == _vm.user.id
                        ? _vm.user.avatar
                        : _vm.selectedChat.last_message.avatar,"contain":"","alt":_vm.selectedChat.last_message.name,"width":"32px","height":"32px"}}),_c('div',{class:[
                      'message__text-container mx-3 d-flex flex-column',
                      { 'order-0 align-end': message.sender_id == _vm.user.id },
                      { 'order-1 align-start': message.sender_id != _vm.user.id } ]},[(message.attachments)?_c('div',{staticClass:"message__attachment-container"},[(message.attachmentsType == 'images')?_c('v-row',{attrs:{"justify":"start","no-gutters":""}},_vm._l((message.attachments.slice(
                            0,
                            4
                          )),function(img,index){return _c('v-col',{key:img + index,staticClass:"\n                            message__attachment-column\n                            pr-0\n                            pl-1\n                            pt-0\n                            pb-1\n                          ",attrs:{"cols":message.attachments.length == 1 ? 12 : 6}},[_c('v-img',{class:[
                              'message__attachment',
                              {
                                'message__attachment-grid':
                                  message.attachments.length > 1,
                              },
                              {
                                'message__attachment-single':
                                  message.attachments.length == 1,
                              },
                              {
                                message__attachment__user:
                                  message.sender_id == _vm.user.id,
                              },
                              {
                                message__attachment__friend:
                                  message.sender_id != _vm.user.id,
                              },
                              { en: _vm.$i18n.locale != 'ar' } ],attrs:{"src":img},on:{"click":function($event){return _vm.openSliderModal(message.attachments, index)}}}),(
                              message.attachments.length > 4 && index == 3
                            )?_c('div',{staticClass:"message__extra-attachments",on:{"click":function($event){return _vm.openSliderModal(message.attachments)}}},[_c('div',{staticClass:"\n                                h-full\n                                d-flex\n                                align-center\n                                justify-center\n                              ",attrs:{"dir":"ltr"}},[_vm._v(" + "+_vm._s(message.attachments.length - index)+" ")])]):_vm._e()],1)}),1):_c('v-row',{attrs:{"justify":"start","no-gutters":""}},_vm._l((message.attachments),function(file,index){return _c('v-col',{key:file + index,staticClass:"\n                            message__attachment-column\n                            pr-0\n                            pl-1\n                            pt-0\n                            pb-1\n                          ",attrs:{"cols":"12"},on:{"click":function($event){return _vm.openLink(file.link)}}},[_c('div',{class:[
                              'message__text d-flex pa-2',
                              {
                                message__text__user:
                                  message.sender_id == _vm.user.id,
                              },
                              {
                                message__text__friend:
                                  message.sender_id != _vm.user.id,
                              } ]},[_c('v-icon',{class:[
                                'mr-1',
                                { 'order-1': _vm.$i18n.locale == 'ar' } ],attrs:{"size":"20"}},[_vm._v("mdi-attachment")]),_c('p',{staticClass:"message__attachment-column__name mb-0"},[_vm._v(" "+_vm._s(_vm.viewName(file.name, 30))+" ")])],1)])}),1)],1):_vm._e(),(message.content_message)?_c('p',{class:[
                        'message__text mb-2',
                        {
                          message__text__user: message.sender_id == _vm.user.id,
                        },
                        {
                          message__text__friend: message.sender_id != _vm.user.id,
                        } ]},[_vm._v(" "+_vm._s(message.content_message)+" ")]):_vm._e(),_c('p',{class:[
                        'message__time mb-0',
                        {
                          'text-end':
                            (message.sender_id != _vm.user.id &&
                              _vm.$i18n.locale == 'ar') ||
                            (message.sender_id == _vm.user.id &&
                              _vm.$i18n.locale == 'en'),
                        } ],attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm.formatDate(message.created_at))+" ")])])])}),0):_vm._e()])]),(_vm.selectedImages.length || _vm.selectedFile)?_c('div',{staticClass:"userMessages__viewed-files d-flex"},[_vm._l((_vm.selectedImages),function(image,index){return _c('div',{key:image.name + index,staticClass:"userMessages__viewed-files__container"},[_c('v-img',{staticClass:"rounded viewed-image",attrs:{"src":_vm.selectedImagesBlob[index],"width":"48px","height":"40px"}}),_c('v-icon',{staticClass:"close-icon",attrs:{"size":"10"},on:{"click":function($event){return _vm.removeImage(index)}}},[_vm._v(" mdi-close ")])],1)}),(_vm.selectedFile)?_c('div',{staticClass:"userMessages__viewed-files__container"},[_c('div',{staticClass:"viewed-file rounded"},[_vm._v(" "+_vm._s(_vm.viewName(_vm.selectedFile.name, 25))+" ")]),_c('v-icon',{staticClass:"close-icon",attrs:{"size":"10"},on:{"click":function($event){_vm.selectedFile = null}}},[_vm._v(" mdi-close ")])],1):_vm._e()],2):_vm._e(),(this.openedChatId !== -1)?_c('div',{class:[
              'userMessages__messages-section__send-message d-flex',
              { 'is-files-uploaded': _vm.selectedImages.length || _vm.selectedFile } ]},[_c('div',{staticClass:"userMessages__messages-section__send-message__message"},[_c('Textarea',{attrs:{"label":"","placeholder":_vm.$t('messages.writeMessage'),"data":_vm.message,"rows":1,"classes":"white-background message-area","isMessages":true,"messagesSelectImages":_vm.messagesSelectImages,"messagesSelectFile":_vm.messagesSelectFile},on:{"data-changed":function($event){_vm.message = $event}}}),_c('input',{staticClass:"d-none",attrs:{"id":"fileInput","type":"file","accept":_vm.selectingFileType,"multiple":_vm.selectingImages},on:{"input":function($event){return _vm.selectFile($event)}}})],1),_c('Button',{attrs:{"text":"","classes":"sendButton","click":_vm.sendMessage},scopedSlots:_vm._u([{key:"prefixSlot",fn:function(){return [_c('img',{attrs:{"src":require('@/assets/img/ico/paper-airplane.svg'),"contain":"","alt":"paper airplane","width":"30px","height":"30px"}})]},proxy:true}],null,false,539169481)})],1):_vm._e()])])]),(_vm.isSliderModalVisible)?_c('slider-modal',{attrs:{"classes":"userMessagesSlider","items":_vm.viewedImages,"isVisible":_vm.isSliderModalVisible,"selectedIndex":_vm.viewedImageIndex},on:{"close":function($event){_vm.isSliderModalVisible = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }