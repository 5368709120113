<template>
  <div class="mreq__cards--table overflow-x-auto">
    <table class="w-full">
      <thead>
        <tr class="text-text-light font-normal bg-bg-dark shadow-sm rounded-lg mb-4">
          <th class="w-[16.5rem] md:w-[14rem] truncate">{{$t('requests.PropertyName')}}</th>
          <th>{{$t('requests.Date')}}</th>
          <th>{{$t('requests.Status')}}</th>
          <th>{{$t('requests.Action')}}</th>
        </tr>
      </thead>
      <tbody v-if="data && data.length">
        <tr v-for="item in data" :key="item.id" class="bg-white rounded-lg mb-4">
          <td>
            <div :class="`flex items-center flex-grow-0 gap-2 `">
              <img :class="`w-9 h-9 rounded-lg object-cover `" :src="item.image" alt="title" />

              <div :class="`text-base w-full truncate   `">{{item.title}}</div>
            </div>
          </td>
          <td>{{item.date}}</td>
          <td
            :class="`text-sm truncate w-[60px]  ${item.status === 'Pending' ? 'text-orange-400' : 'text-success'} `"
          >{{$t('requests.' + item.status)}}</td>

          <td class="max-w-min">
            <v-btn
              small
              elevation="0"
              class="font-bold capitalize"
              @click="() => $emit('openReqDialog' , item.id)"
            >details</v-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: Array
  }
};
</script>

<style  scoped lang="scss">
table,
th,
td {
  border: none;
  border-collapse: unset;
  border-spacing: 0 0.75rem;
  text-align: start;
  font-weight: 300;
}
table {
  th,
  td {
    padding: 0.65rem;
    white-space: nowrap;
    &:first-child {
      border-start-start-radius: 8px;
      border-end-start-radius: 8px;
    }
    &:last-child {
      border-start-end-radius: 8px;
      border-end-end-radius: 8px;
    }
  }
}
</style> 
 