<template>
  <AuthLayout
    :sideTitle="$t('LogIn.ExploreFuture')"
    :sideSubTitle="$t('LogIn.EazySolution')"
  >
    <signUpComponent />
  </AuthLayout>
</template>

<script>
import signUpComponent from "@/components/signUpComponent/signUpComponent.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
export default {
  components: {
    signUpComponent,
    AuthLayout
  }
};
</script>
