<template>
  <div class="w-full max-w-sm xl:max-w-md mx-auto">
    <div>
      <h3 class="signin-header-wrapper">{{ $t("password.welcomeMessage") }}</h3>
      <p class="mt-3 signin-text-wrapper py-0">
        {{ $t("password.EnterYourDetailsLog") }}
      </p>
    </div>
    <v-form>
      <CustomTextInput
        placeholder="example@gmail.com"
        class="customTextFieldInput"
        :label="$t('password.email')"
        :data="formData.email"
        :rules="[rules.required, rules.email]"
      />

      <CustomTextInput
        placeholder="password"
        :data="formData.password"
        @data-changed="formData.password = $event"
        class="customTextFieldInput"
        :label="$t('password.password')"
        :rules="[rules.required, rules.min]"
        :type="showPass ? 'text' : 'password'"
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        :appendIconClick="showHidePassword"
      />

      <div class="remmeber">
        <CustomCheckbox :label="$t('password.rememberMe')" />
        <v-spacer></v-spacer>
        <router-link
          class="ForgotPassAncor"
          :to="`/forgotpassword/${$route.params.lang}`"
          tag="a"
          >{{ $t("password.ForgotPassword") }}</router-link
        >
      </div>

      <CustomButton
        large
        class="custom-btn"
        classes="text-base capitalize"
        block
        :click="Login"
        :text="$t('password.SignIn')"
      />

      <p class="HaveAcountMessage text-center mt-5">
        {{ $t("password.dontHaveAccountMessage") }}
        <router-link
          class="goToSignUpAncor"
          :to="`/signup/${$route.params.lang}`"
          tag="a"
          >{{ $t("password.SignUp") }}</router-link
        >
      </p>

      <SocialMediaRegister :subheader="$t('password.OrSignIn')" />
    </v-form>
  </div>
</template>

<script>
import SocialMediaRegister from "../shared/SocialMediaRegisterSection.vue";
import CustomButton from "../shared/Button/CustomButton.vue";
import CustomTextInput from "../shared/customTextInput/CustomTextInput.vue";
import CustomCheckbox from "../shared/customCheckbox/CustomCheckbox.vue";
import rules from "@/services/rules";
import { mapGetters } from "vuex";

export default {
  components: {
    CustomButton,
    CustomTextInput,
    CustomCheckbox,
    SocialMediaRegister
  },
  data() {
    return {
      rules,
      formData: {
        password: "",
        email: ""
      },
      rememberMe: "",
      showPass: false
    };
  },

  methods: {
    showHidePassword() {
      this.showPass = !this.showPass;
    },
    Login() {
      this.$store.commit("logIn");
      this.$router.push(`/${this.$route.params.lang}`);
    },
  },
  computed: {
   ...mapGetters(["isLoggedIn"])
  }
};
</script>

<style lang="scss" scoped>

.remmeber {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 0px;
}
.row + .row {
  margin-top: 8px;
}
.HaveAcountMessage {
  font-family: var(--font-en);
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: var(--text-mute);
}
.goToSignUpAncor {
  font-family: var(--font-en);
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 20px;
  color: var(--primary);
}
.ForgotPassAncor {
  color: var(--primary);
  font-family: var(--font-en);
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 15px;
}

@media only screen and (max-width: 959px) {
  
  .signin-header-wrapper {
    text-align: center;
  }
  .signin-text-wrapper {
    text-align: center;
  }
  .custom-btn {
    padding: 15px 0px 16px;
  }
}
</style>