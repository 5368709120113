<template>
  <v-row class="exprties-section">
    <v-col cols="12">
      <v-card-title class="text-h5">
        <span class="exprties-section__title">
          {{ $t("profile.WorkExperience") }}
        </span>
        <v-spacer></v-spacer>

        <v-btn @click="modalVisible = true" class="exprties-section__icon" icon>
          <img src="@/assets/img/ico/plus-circle.svg" alt="Edit.svg" />
          <span class="exprties-section__EditAncortitle">{{
              $t("profile.Add")
          }}</span>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-row class="exprties-section__information">
        <v-col cols="12">
          <v-row class="exprties-section__information__details">
            <v-col cols="12" v-for="item in data" :key="item.title">
              <img src="@/assets/profile/icons/pollet.svg" alt="" />
              <label class="workExp_label">{{  item.CompanyName }}</label>
              <p>{{ item.title }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <custom-modal
        @close="modalVisible = false"
        @confirm="addWorkExprience"
        :isVisible="modalVisible"
        :modalTitle="$t('profile.AddWorkExpr')"
        :okBtn="$t('profile.Save')"
        :cancelBtn="$t('profile.Cancel')"
      >
        <div class="px-5 py-2">
          <custom-text-input
            :label="$t('profile.Company')"
            :placeholder="$t('profile.AddCompany')"
            :isDense="true"
            @data-changed="(value) => (workEx.company = value)"
          />
        </div>
        <div class="px-5 py-2">
          <custom-text-input
            :label="$t('profile.Title')"
            :placeholder="$t('profile.AddTitle')"
            :isDense="true"
            @data-changed="(value) => (workEx.title = value)"
          />
        </div>
      </custom-modal>
    </v-col>
  </v-row>
</template>

<script>
import CustomModal from "@/components/shared/CustomModal.vue";
import customTextInput from "@/components/shared/customTextInput/CustomTextInput.vue";

export default {
  components: {
    CustomModal,
    customTextInput,
  },
  data() {
    return {
      modalVisible: false,
      workEx: {
        company:'',
        title:''
      }
    };
  },
  props: ["data"],
  methods: {
    addWorkExprience(){
      this.modalVisible = false
      if (this.workEx != "") {
        this.$store.commit("insertWorkExp", this.workEx);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.exprties-section {
  padding: 0px;
  font-family: var(--font-en);
  font-style: normal;
  background: var(--white);
  border: 1px solid rgba(232, 233, 234, 0.4);
  box-shadow: 0px 4px 14px rgba(233, 233, 233, 0.25);
  border-radius: 16px;
  margin-bottom: 24px;

  &__title {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 30px;
    color: var(--dark-primary);
  }

  &__icon {
    .v-btn--icon.v-size--default {
      height: 17px;
      width: 18px;
      margin-inline: 9px;
    }

    .theme--light.v-btn.v-btn--icon {
      color: transparent;
    }

    .theme--light.v-btn :hover {
      color: transparent;
    }
  }

  &__EditAncortitle {
    font-weight: 500;
    text-transform: capitalize;
    font-size: 0.875rem;
    line-height: 22px;
    color: var(--primary);
  }

  &__information {
    margin-top: 12px;
    &__title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 24px;
      color: var(--dark-primary);
    }
    &__subtitle {
      font-weight: 400;
      font-size: 1rem;
      line-height: 24px;
      color: var(--text-mute);
    }
    &__details {
      margin-top: 12px;
      margin-bottom: 4px;
      img {
        padding-inline: 20px;
      }

      .workExp_label {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        padding-inline: 10px;
        color: #1b2534;
      }

      label {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 20px;
        color: var(--text-mute);
      }
      p {
        font-weight: 400;
        font-size: 1rem;
        line-height: 22px;
        color: var(--text-color-04);
        padding-inline: 62px;
      }
    }
  }

  .v-btn__content {
    width: 16px;
    height: 16px;
    margin-right: 15.67px;

    i {
      color: var(--primary);
    }
  }
}

@media only screen and (max-width: 599px) {
  .exprties-section__information {
    &__details {
      text-align: center;
    }

    .v-list-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      min-height: 1px;
    }
  }
}

@media only screen and (max-width: 959px) {
  .exprties-section__information {
    &__details {
      text-align: center;
    }

    .v-list-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      min-height: 1px;
    }
  }
}
</style>