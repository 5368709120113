



























import { Component, Vue } from "vue-property-decorator";
@Component({
  props: {
    text: { default: "" },
    styles: { default: "" },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    Xlarge: {
      type: Boolean,
      default: false,
    },
    click: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {},
    },
    classes: { default: "" },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
export default class Button extends Vue {}
