<template>
  <div>
    <div class="container">
      <div class="bg-white rounded-lg p-6 md:p-7">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-8">
          <div class="contact_page--info">
            <ContactUsInfo />
          </div>
          <div class="contact_page--form">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3455.5898208919857!2d31.1308276848861!3d29.991215981901618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s3%20Saad%20area%2C%20Menkaure%20Gate%20%2CHadayek%20El%20ahram%2C%20Giza!5e0!3m2!1sar!2seg!4v1655304437790!5m2!1sar!2seg"
        width="100%"
        height="350"
        style="border:0;"
        frameborder="0"
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>
 
<script>
import ContactUsInfo from "./ContactUsInfo.vue";
import ContactUsForm from "./ContactUsForm.vue";
export default {
  components: {
    ContactUsInfo,
    ContactUsForm
  }
};
</script>