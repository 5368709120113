<template>
  <div>
    <h2
      class="text-lg"
      style="font-size: 1.125rem !important;"
    >{{$t('req_meeting.EnterPropertyCategory')}}</h2>
    <div class="mt-6 md:mt-8 mb-4 lg:mb-6">
      <label class="text-sm mb-2 block font-bold text-text">{{$t('req_meeting.PropertyCategory')}}</label>
      <v-select
        :items="items"
        transition="none"
        dense
        value="Commercial"
        outlined
        class="max-w-2xl"
      ></v-select>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: ["Commercial", "Private"]
    };
  }
};
</script>