<template>
  <v-container class="wishListContainer">
    <v-row>
      <v-col
        class="p-0"
        cols="12"
        md="9"
        xl="9"
      >
        <h5 class="text-primary">{{ $t("wishlist.myWishlists") }}</h5>
        <wish-list-header
          :title="wishlistTitle"
          @handleFilter="setCategory"
          @handleSort="setSortBy"
          class="mt-2"
          :sortBy="sortBy"
          :category="category"
        />
        <v-row
          no-gutters
          class="my-md-5 my-sm-3"
        >
          <v-col
            cols="12"
            v-for="card in displayedList"
            :key="card.id"
            class="mt-md-2 mb-md-5 pr-2 pl-2 mt-sm-2 mb-sm-10 my-xs-10"
          >
            <featured-card
              class="cardMargin"
              :imgmdCols="type == 'list' ? 4 : 12"
              :detailsmdCols="type == 'list' ? 8 : 12"
              :card="card"
              :titleStyle="type == 'list' ? 'titleStyle' : 'FeaturedTitleStyle'"
              contentStyle="contentStyle"
              :favIconStyle="type == 'list' ? '' : 'favIconStyle'"
              :specContainer="type == 'list' ? '' : 'specContainer'"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
        xl="3"
        class="wishListSearchContainer"
        :class="$i18n.locale == 'ar' ? 'containerMergeAr' : 'containerMergeEn'"
      >
        <img
          width="100%"
          :src="require(`@/assets/img/ads240.png`)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>


<style scoped>
.wishListContainer {
  background: var(--light-gray-bg-01);
}

@media only screen and (max-width: 768px) {
  .wishListSearchContainer {
    margin-bottom: 20px;
  }

  .cardMargin {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 1000px) {
  .wishListSearchContainer {
    margin-bottom: 20px;
  }

  .cardMargin {
    margin-bottom: 50px;
  }
}
</style>

<script >
import FeaturedCard from "@/components/homeComponents/featuredCards/FeaturedCard.vue";
import WishListHeader from "@/components/wishListComponents/WishListHeader.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    FeaturedCard,
    WishListHeader,
  },
  data() {
    return {
      modalVisible: false,
      sortByItems: ["Lowest Price", "Highest Price"],
      sortByValue: "Lowest Price",
      type: "list",
      recordsCount: 0,
      itemPanelSelected: 0,
      sortBy: "Highest Price",
      category: "All"
    };
  },
  computed: {
    ...mapGetters(["cards", "selectedWishlist", "wishlists"]),
    displayedList() {
      let filteredWishlist = this.wishlists.filter(
        (wishlist) => wishlist.id == this.selectedWishlist
      );

      let favCards = this.cards.filter((card) => card.isFav);

      if (filteredWishlist && filteredWishlist.length > 0) {
        favCards = this.cards.filter((card) =>
          filteredWishlist[0].cards.includes(card.id)
        );
      }

      if (this.category != 'All') {
        favCards = favCards.filter((card) =>
          card.unitService == this.category
        )
      }

      return this.sortElements(favCards);
    },
    wishlistTitle() {
      if (this.selectedWishlist == 0) {
        return "All Saved";
      }
      let foundWishlist = this.wishlists.find(
        (wishlist) => wishlist.id == this.selectedWishlist
      );
      if (foundWishlist) {
        return foundWishlist.title;
      }
      return "Untitled wishlist";
    },
  },
  mounted() {
    this.recordsCount = this.cards.length;
  },
  methods: {
    setSortBy(value) {
      this.sortBy = value;
    },
    setCategory(value) {
      this.category = value;
    },
    sortElements(values) {
      const newValues = [...values]
      if (this.sortBy == "Highest Price") {
        newValues.sort((a, b) => {
          return a.price < b.price ? 1 : -1;
        });
      } else if (this.sortBy == "Lowest Price") {
        newValues.sort((a, b) => {
          return a.price > b.price ? 1 : -1;
        });
      }
      return newValues;
    },
  },
};
</script>
