<template>
  <v-row
    class="searchHeader align-center"
    no-gutters
  >
    <v-col
      class="headerPadding"
      cols="12"
      md="6"
      sm="12"
      xs="12"
    >
      <h6 class="d-inline-flex">{{ $t("properties.properties") }}</h6>
      <span class="px-4 d-inline-flex">{{ $t("properties.total") }} {{recordsCount}} {{ $t("properties.results") }}</span>
    </v-col>
    <v-col
      class="headerPadding"
      cols="12"
      md="6"
      sm="12"
      xs="12"
    >
      <div class="content propertiesHeaderContent">
        <div class="filterSortingLabelContainer">
          <p class="w-full justify-start justify-md-end d-inline-flex ma-0">
            {{ $t("properties.sortby") }}
          </p>
          <custom-select
            :items="sortByItems"
            class="propertiesCustomSort w-full"
            :data="sortByValue"
            :item-value="sortByValue"
            @data-changed="changeSort"
          />
        </div>
        <div class="flex ltr:justify-end rtl:justify-start">
          <v-btn
            :color="type == 'list' ? 'primary' : 'white'"
            class="noShadow"
            small
            width="30"
            height="40"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
            @click="setFilterType('list')"
          >
            <img
              alt="search"
              class="shrink"
              contain
              width="17"
              :class="type == 'list' ? 'active' : 'inactive'"
              :style="{ transform: $i18n.locale == 'en' ? '' : 'scaleX(-1)' }"
              :src="require(`@/assets/img/ico/list.svg`)"
            />
          </v-btn>
          <v-btn
            :color="type == 'grid' ? 'primary' : 'white'"
            class="noShadow"
            small
            width="30"
            height="40"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
            @click="setFilterType('grid')"
          >
            <img
              alt="search"
              class="shrink"
              contain
              :class="type == 'grid' ? 'active' : 'inactive'"
              width="17"
              :style="{ transform: $i18n.locale == 'en' ? '' : 'scaleX(-1)' }"
              :src="require(`@/assets/img/ico/grid.svg`)"
            />
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
.back {
  text-decoration: none;
  color: var(--text-color-01);
}
.text {
  color: var(--text-color-02);
}
.searchHeader {
  background: var(--white);
  border-radius: 8px;
  padding-inline: 20px;
}
.searchHeader span {
  font-size: small;
}
.propertiesCustomSort {
  display: inline-flex;
  border: none !important;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.noShadow {
  box-shadow: none;
}
.inactive {
  filter: brightness(1) invert(0.5) grayscale(100%);
}
.active {
  filter: brightness(0) invert(1);
}

@media only screen and (max-width: 600px) {
  .searchHeader {
    padding-block: 15px;
  }
}
@media only screen and (max-width: 1000px) {
  .searchHeader {
    background: var(--white);
    border-radius: 8px;
    padding-inline: 0px;
  }
  .headerPadding {
    padding-inline: 20px !important;
  }
}
@media only screen and (min-width: 1564px) {
  .searchHeader {
    background: var(--white);
    border-radius: 8px;
    padding-inline: 0px;
  }
  .headerPadding {
    padding-inline: 20px !important;
  }
}
</style>

<script >
import CustomSelect from "@/components/shared/customSelect/CustomSelect.vue";
import { mapGetters } from "vuex";

export default {
  props: ['recordsCount'],
  data() {
    return {
      sortByItems: ["Lowest Price", "Highest Price"],
      sortByValue: "Lowest Price",
      type: "list",
    };
  },
  methods: {
    setFilterType(type) {
      this.type = type;
      this.$emit("setFilterType", this.type);
    },
    changeSort(value) {
      this.$emit("handleSort", value)
    },
  },
  components: {
    CustomSelect,
  },
  computed: {
    ...mapGetters(["cards"]),
  },
};
</script>
