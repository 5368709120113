<template>
  <div class="DetailsSlider my-5 md:mx-9">
    <h3 class="my-md-5 my-sm-3 text-center text-primary">{{$t('projects.otherProjectes')}}</h3>
    <carousel
      style="direction:ltr !important"
      id="unitsCarousel"
      :loop="true"
      :perPageCustom="[[0,1],[600,2],[960,4]]"
      :paginationEnabled="false"
      :autoplay="false"
      :centerMode="true"
      :navigationEnabled="true"
      navigationPrevLabel
      navigationNextLabel
    >
      <slide
        class="px-2"
        id="unitsSlides"
        v-for="(card, index) in displayedCards"
        :key="'unitDetailsView' + index"
      >
        <projectDetailsSlideCard
          :imgmdCols="12"
          :detailsmdCols="12"
          :card="card"
          titleStyle="FeaturedTitleStyle"
          contentStyle="contentStyle"
          favIconStyle="favIconStyle"
          specContainer="specContainer"
        />
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import projectDetailsSlideCard from "@/components/projects/projectDetails/projectDetailsSlideCard.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    Carousel,
    Slide,
    projectDetailsSlideCard
  },
  computed: {
    ...mapGetters(["projects"]),
  },
  data() {
    return {
      displayedCards: [],
    };
  },
  methods: {

  },
  mounted() {
    this.displayedCards = this.projects;
  }
}
</script>
