<template>
  <div class="userMessages">
    <v-row class="h-full">
      <v-col
        md="4"
        :class="['h-full pb-0', { 'pl-0': !isEn }, { 'pr-0': isEn }]"
      >
        <div :class="['userMessages__friends-section', { en: isEn }]">
          <div class="userMessages__friends-section__search">
            <TextField
              label=""
              :placeholder="$t('messages.search')"
              :data="searchText"
              @data-changed="searchText = $event"
              classes="px-4 pt-4"
              :click="searchForUser"
            />
          </div>
          <div
            v-for="chat in userChats"
            :key="chat.id"
            :class="[
              'userMessages__friends-section__friend',
              'd-flex justify-space-between',
              { active: openedChatId === chat.id },
            ]"
            @click="openChat(chat.id, chat.receiver)"
          >
            <div class="d-flex">
              <img
                :class="['friend-avatar ml-2', { 'ml-0 mr-2': isEn }]"
                :src="chat.last_message.avatar"
                contain
                :alt="chat.last_message.name"
                width="40px"
                height="40px"
              />
              <div>
                <p
                  :class="[
                    'friend-name mb-1',
                    { 'only-name': !chat.last_message.content_message },
                  ]"
                >
                  {{ chat.last_message.name }}
                </p>
                <p
                  v-if="chat.last_message.content_message"
                  class="last-message mb-0"
                >
                  {{ chat.last_message.content_message.substring(0, 50) }}
                  {{
                    chat.last_message.content_message.length > 50 ? "..." : ""
                  }}
                </p>
              </div>
            </div>
            <div class="text-start d-flex flex-column align-end">
              <p class="date mb-1" dir="ltr">
                {{ formatTime(chat.last_message.created_at) }}
              </p>
              <p
                class="
                  unreadedMessages-number
                  mb-0
                  d-flex
                  justify-center
                  align-center
                  mx-2
                "
                v-if="chat.unreadedMessages"
              >
                {{ chat.unreadedMessages }}
              </p>
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        md="8"
        :class="['h-full pb-0', { 'pr-0': !isEn }, { 'pl-0': isEn }]"
      >
        <div class="userMessages__messages-section h-full">
          <div class="userMessages__messages-section__inner h-full">
            <div
              :class="[
                'userMessages__messages-section__heading d-flex align-center',
                { en: isEn },
              ]"
            >
              <img
                v-if="this.openedChatId !== -1"
                :class="['friend-avatar ml-2', { 'ml-0 mr-2': isEn }]"
                :src="user.avatar"
                contain
                :alt="user.user_name"
                width="48px"
                height="48px"
              />

              <p v-if="this.openedChatId !== -1" class="friend-name mb-0 mx-2">
                {{ selectedChat.last_message.name }}
              </p>
            </div>

            <div
              :class="[
                'userMessages__messages-section__messages',
                { 'waiting-upload': selectedImages.length || selectedFile },
              ]"
              id="section__messages"
            >
              <div id="section-messages-container" class="messages-container">
                <div v-if="this.openedChatId !== -1">
                  <div
                    v-for="message in selectedMessages"
                    :key="message.id"
                    :class="[
                      'message d-flex align-end',
                      { 'justify-end': message.sender_id == user.id },
                    ]"
                  >
                    <img
                      :class="[
                        'mb-7',
                        { 'order-0': message.sender_id != user.id },
                        { 'order-1': message.sender_id == user.id },
                      ]"
                      :src="
                        message.sender_id == user.id
                          ? user.avatar
                          : selectedChat.last_message.avatar
                      "
                      contain
                      :alt="selectedChat.last_message.name"
                      width="32px"
                      height="32px"
                    />
                    <div
                      :class="[
                        'message__text-container mx-3 d-flex flex-column',
                        { 'order-0 align-end': message.sender_id == user.id },
                        { 'order-1 align-start': message.sender_id != user.id },
                      ]"
                    >
                      <div
                        v-if="message.attachments"
                        class="message__attachment-container"
                      >
                        <v-row
                          v-if="message.attachmentsType == 'images'"
                          justify="start"
                          no-gutters
                        >
                          <v-col
                            v-for="(img, index) in message.attachments.slice(
                              0,
                              4
                            )"
                            :key="img + index"
                            :cols="message.attachments.length == 1 ? 12 : 6"
                            class="
                              message__attachment-column
                              pr-0
                              pl-1
                              pt-0
                              pb-1
                            "
                          >
                            <v-img
                              @click="
                                openSliderModal(message.attachments, index)
                              "
                              :class="[
                                'message__attachment',
                                {
                                  'message__attachment-grid':
                                    message.attachments.length > 1,
                                },
                                {
                                  'message__attachment-single':
                                    message.attachments.length == 1,
                                },
                                {
                                  message__attachment__user:
                                    message.sender_id == user.id,
                                },
                                {
                                  message__attachment__friend:
                                    message.sender_id != user.id,
                                },
                                { en: $i18n.locale != 'ar' },
                              ]"
                              :src="img"
                            />
                            <div
                              v-if="
                                message.attachments.length > 4 && index == 3
                              "
                              class="message__extra-attachments"
                              @click="openSliderModal(message.attachments)"
                            >
                              <div
                                class="
                                  h-full
                                  d-flex
                                  align-center
                                  justify-center
                                "
                                dir="ltr"
                              >
                                + {{ message.attachments.length - index }}
                              </div>
                            </div>
                          </v-col>
                        </v-row>

                        <v-row v-else justify="start" no-gutters>
                          <v-col
                            v-for="(file, index) in message.attachments"
                            :key="file + index"
                            cols="12"
                            class="
                              message__attachment-column
                              pr-0
                              pl-1
                              pt-0
                              pb-1
                            "
                            @click="openLink(file.link)"
                          >
                            <div
                              :class="[
                                'message__text d-flex pa-2',
                                {
                                  message__text__user:
                                    message.sender_id == user.id,
                                },
                                {
                                  message__text__friend:
                                    message.sender_id != user.id,
                                },
                              ]"
                            >
                              <v-icon
                                :class="[
                                  'mr-1',
                                  { 'order-1': $i18n.locale == 'ar' },
                                ]"
                                size="20"
                                >mdi-attachment</v-icon
                              >
                              <p class="message__attachment-column__name mb-0">
                                {{ viewName(file.name, 30) }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                      <p
                        v-if="message.content_message"
                        :class="[
                          'message__text mb-2',
                          {
                            message__text__user: message.sender_id == user.id,
                          },
                          {
                            message__text__friend: message.sender_id != user.id,
                          },
                        ]"
                      >
                        {{ message.content_message }}
                      </p>
                      <p
                        :class="[
                          'message__time mb-0',
                          {
                            'text-end':
                              (message.sender_id != user.id &&
                                $i18n.locale == 'ar') ||
                              (message.sender_id == user.id &&
                                $i18n.locale == 'en'),
                          },
                        ]"
                        dir="ltr"
                      >
                        {{ formatDate(message.created_at) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="selectedImages.length || selectedFile"
              class="userMessages__viewed-files d-flex"
            >
              <div
                v-for="(image, index) in selectedImages"
                :key="image.name + index"
                class="userMessages__viewed-files__container"
              >
                <v-img
                  class="rounded viewed-image"
                  :src="selectedImagesBlob[index]"
                  width="48px"
                  height="40px"
                />
                <v-icon
                  class="close-icon"
                  size="10"
                  @click="removeImage(index)"
                >
                  mdi-close
                </v-icon>
              </div>

              <div
                v-if="selectedFile"
                class="userMessages__viewed-files__container"
              >
                <div class="viewed-file rounded">
                  {{ viewName(selectedFile.name, 25) }}
                </div>
                <v-icon
                  class="close-icon"
                  size="10"
                  @click="selectedFile = null"
                >
                  mdi-close
                </v-icon>
              </div>
            </div>
            <div
              v-if="this.openedChatId !== -1"
              :class="[
                'userMessages__messages-section__send-message d-flex',
                { 'is-files-uploaded': selectedImages.length || selectedFile },
              ]"
            >
              <div
                class="userMessages__messages-section__send-message__message"
              >
                <Textarea
                  label=""
                  :placeholder="$t('messages.writeMessage')"
                  :data="message"
                  @data-changed="message = $event"
                  :rows="1"
                  classes="white-background message-area"
                  :isMessages="true"
                  :messagesSelectImages="messagesSelectImages"
                  :messagesSelectFile="messagesSelectFile"
                />

                <input
                  class="d-none"
                  id="fileInput"
                  type="file"
                  @input="selectFile($event)"
                  :accept="selectingFileType"
                  :multiple="selectingImages"
                />
              </div>
              <Button text="" classes="sendButton" :click="sendMessage">
                <template v-slot:prefixSlot>
                  <img
                    class=""
                    :src="require('@/assets/img/ico/paper-airplane.svg')"
                    contain
                    alt="paper airplane"
                    width="30px"
                    height="30px"
                  />
                </template>
              </Button>
            </div>
          </div>
        </div>
      </v-col>
      <slider-modal
        classes="userMessagesSlider"
        v-if="isSliderModalVisible"
        :items="viewedImages"
        :isVisible="isSliderModalVisible"
        :selectedIndex="viewedImageIndex"
        @close="isSliderModalVisible = false"
      />
    </v-row>
    <!--
    <SearchedForUserModal
      :dialog="isSearchResultsViewed"
      @close="isSearchResultsViewed = false"
      @selectUser="selectUserFromSearchToChat"
    />
    -->
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import TextField from "@/components/shared/customTextInput/CustomTextInput.vue";
import Textarea from "@/components/shared/customTextarea/CustomTextarea.vue";
import Button from "@/components/shared/Button/CustomButton.vue";
import moment from "moment";
import SliderModal from "./sliderModal.vue";

// import {
//   GET_FRIEND_CHAT,
//   GET_USER_CHATS,
//   SEARCH_USER,
//   SEND_MESSAGE,
// } from "@/store/actions.type";
// import SearchedForUserModal from "@/components/user/SearchedForUserModal.vue";

@Component({
  components: {
    TextField,
    Button,
    Textarea,
    SliderModal,
    // SearchedForUserModal,
  },
})
export default class UserMessages extends Vue {
  get isEn() {
    return this.$route.params.lang == "en";
  }

  get user() {
    // return this.$store.getters["user/user"];
    return {
      id: 1,
      name: "current user",
      avatar:
        "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    };
  }

  get userChats() {
    // return this.$store.getters["user/userChats"];
    return [
      {
        id: 1,
        receiver: 2,
        last_message: {
          id: 1,
          chat_id: 1,
          sender_id: 1,
          content_message: "hello dude",
          receiver_id: 2,
          created_at: "2022-07-07T14:23:01.243Z",
          name: "ahmed",
          avatar:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        },
        unreadedMessages: 5,
      },
      {
        id: 2,
        receiver: 1,
        last_message: {
          id: 2,
          chat_id: 2,
          sender_id: 3,
          content_message: "hello dude",
          receiver_id: 2,
          created_at: "2022-07-07T14:23:01.243Z",
          name: "ahmed 1",
          avatar:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        },
        unreadedMessages: 3,
      },
      {
        id: 4,
        receiver: 4,
        last_message: {
          id: 4,
          chat_id: 4,
          sender_id: 1,
          content_message: "hello dude",
          receiver_id: 4,
          created_at: "2022-07-07T14:23:01.243Z",
          name: "ahmed4",
          avatar:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        },
        unreadedMessages: 0,
      },
      {
        id: 5,
        receiver: 5,
        last_message: {
          id: 5,
          chat_id: 5,
          sender_id: 1,
          content_message: "hello dude",
          receiver_id: 5,
          created_at: "2022-07-07T14:23:01.243Z",
          name: "ahmed5",
          avatar:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        },
        unreadedMessages: 2,
      },
      {
        id: 6,
        receiver: 6,
        last_message: {
          id: 6,
          chat_id: 6,
          sender_id: 1,
          content_message: "hello dude",
          receiver_id: 6,
          created_at: "2022-07-07T14:23:01.243Z",
          name: "ahmed6",
          avatar:
            "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        },
        unreadedMessages: 0,
      },
    ];
  }

  get friendChat() {
    return this.$store.getters["user/friendChat"];
  }

  get selectedChat() {
    if (this.openedChatId !== -1) {
      // return this.userChats.find((chat) => chat.id === this.openedChatId);
      return this.userChats[0];
    }
    return null;
  }

  get selectedMessages() {
    // return this.friendChat ? [...this.friendChat].reverse() : [];
    return [
      {
        id: 1,
        receiver_id: 2,
        sender_id: 1,
        content_message:
          "this is some text to be viewed here this is some text to be viewed here this is some text to be viewed here",
        created_at: "2022-07-07T17:13:42.832Z",
      },
      {
        id: 2,
        receiver_id: 2,
        sender_id: 1,
        content_message: "this is some text to be viewed here",
        created_at: "2022-07-07T16:10:42.832Z",
      },
      {
        id: 3,
        receiver_id: 1,
        sender_id: 2,
        content_message:
          "this is some text to be viewed here this is some text to be viewed here this is some text to be viewed here this is some text to be viewed here this is some text to be viewed here this is some text to be viewed here",
        created_at: "2022-07-06T10:19:42.832Z",
      },
      {
        id: 4,
        receiver_id: 1,
        sender_id: 2,
        attachments: [
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        ],
        attachmentsType: "images",
        created_at: "2022-07-06T10:19:42.832Z",
      },
      {
        id: 5,
        receiver_id: 1,
        sender_id: 2,
        attachments: [
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          "https://media.istockphoto.com/photos/taj-mahal-mausoleum-in-agra-picture-id1146517111?k=20&m=1146517111&s=612x612&w=0&h=vHWfu6TE0R5rG6DJkV42Jxr49aEsLN0ML-ihvtim8kk=",
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        ],
        content_message: "this is some text to be viewed here",
        attachmentsType: "images",
        created_at: "2022-07-06T10:19:42.832Z",
      },
      {
        id: 8,
        receiver_id: 2,
        sender_id: 1,
        content_message: "this is some text to be viewed here",
        attachments: [
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        ],
        attachmentsType: "images",
        created_at: "2022-07-06T10:19:42.832Z",
      },
      {
        id: 8,
        receiver_id: 2,
        sender_id: 1,
        attachments: [
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        ],
        attachmentsType: "images",
        created_at: "2022-07-06T10:19:42.832Z",
      },
      {
        id: 9,
        receiver_id: 2,
        sender_id: 1,
        attachments: [
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          "https://media.istockphoto.com/photos/taj-mahal-mausoleum-in-agra-picture-id1146517111?k=20&m=1146517111&s=612x612&w=0&h=vHWfu6TE0R5rG6DJkV42Jxr49aEsLN0ML-ihvtim8kk=",
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        ],
        attachmentsType: "images",
        created_at: "2022-07-06T10:19:42.832Z",
      },
      {
        id: 9,
        receiver_id: 2,
        sender_id: 1,
        content_message: "this is some text to be viewed here",
        attachments: [
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          "https://media.istockphoto.com/photos/taj-mahal-mausoleum-in-agra-picture-id1146517111?k=20&m=1146517111&s=612x612&w=0&h=vHWfu6TE0R5rG6DJkV42Jxr49aEsLN0ML-ihvtim8kk=",
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        ],
        attachmentsType: "images",
        created_at: "2022-07-06T10:19:42.832Z",
      },
      {
        id: 6,
        receiver_id: 1,
        sender_id: 2,
        content_message: "this is some text to be viewed here",
        attachments: [
          {
            name: "file name",
            link: "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          },
        ],
        attachmentsType: "file",
        created_at: "2022-07-06T10:19:42.832Z",
      },
      {
        id: 7,
        receiver_id: 2,
        sender_id: 1,
        attachments: [
          {
            name: "file name",
            link: "https://images.pexels.com/photos/1172253/pexels-photo-1172253.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
          },
        ],
        attachmentsType: "file",
        created_at: "2022-07-06T10:19:42.832Z",
      },
    ];
  }

  get friendChatPage() {
    return this.$store.getters["user/friendChatPage"];
  }

  get lastPage() {
    return this.$store.getters["user/friendChatLastPages"];
  }

  get selectingFileType() {
    return this.selectingImages
      ? "image/*"
      : ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.txt, application/vnd.ms-exce";
  }

  searchText = "";
  openedChatId = 1;
  userFriendId = -1;
  message = "";
  isSearchResultsViewed = false;
  file = null;

  isSliderModalVisible = false;
  viewedImages = [];
  viewedImageIndex = 0;

  selectingImages = true;
  selectedFile = null;
  selectedImages = [];
  selectedImagesBlob = [];

  created() {
    // this.$store.dispatch("user/" + GET_USER_CHATS);
  }

  mounted() {
    this.scrollMessagesToBottom();
    this.checkIsScrolledTopMessages();
  }

  scrollMessagesToBottom() {
    const messagesSection = document.getElementById("section__messages");
    const scrollHeight = messagesSection.scrollHeight;

    setTimeout(() => {
      document.getElementById("section__messages").scrollTop = scrollHeight;
    }, 50);
  }

  checkIsScrolledTopMessages() {
    const messagesSection = document.getElementById("section__messages");
    const clientHeight = messagesSection.clientHeight;

    const container = document.getElementById("section-messages-container");

    messagesSection.onscroll = () => {
      if (
        messagesSection.scrollTop == 0 &&
        container.clientHeight > clientHeight &&
        this.friendChatPage < this.lastPage
      ) {
        // this.$store.dispatch("user/" + GET_FRIEND_CHAT, {
        //   isNewChat: false,
        //   receiver_id: this.userFriendId,
        // });
      }
    };
  }

  openSliderModal(images, index) {
    this.viewedImages = images;
    this.viewedImageIndex = index;
    this.isSliderModalVisible = true;
  }

  openChat(id, friendId) {
    if (id !== this.openedChatId) {
      this.openedChatId = id;
      this.userFriendId = friendId;
      // this.$store
      //   .dispatch("user/" + GET_FRIEND_CHAT, {
      //     isNewChat: true,
      //     receiver_id: friendId,
      //   })
      //   .then(() => {
      //     this.scrollMessagesToBottom();
      //   });
    }
  }

  formatTime(date) {
    let formatedTime = "";
    if (date) {
      formatedTime = moment(String(date)).format("h:mm a");
    }
    return formatedTime;
  }

  formatDate(date) {
    if (date) {
      const today = moment().endOf("day");
      const yesterday = moment().subtract(1, "day").endOf("day");

      if (date < today && date > yesterday)
        return "Today at " + moment(String(date)).format("h:mm a");
      if (date < yesterday)
        return "Yesterday at " + moment(String(date)).format("h:mm a");
      return moment(String(date)).format("MMM DD, YYYY h:mm a");
    }
  }

  sendMessage() {
    // this.$store
    //   .dispatch("user/" + SEND_MESSAGE, {
    //     receiver_id: this.selectedChat.receiver,
    //     message: this.message,
    //   })
    //   .then(() => {
    //     this.scrollMessagesToBottom();
    //   });

    this.message = "";
  }

  searchForUser() {
    if (this.searchText) {
      // this.$store.dispatch("user/" + SEARCH_USER, this.searchText).then(() => {
      //   this.isSearchResultsViewed = true;
      // });
    }
  }

  selectUserFromSearchToChat(user) {
    const chat = this.isUserHaveChat(user.id);
    if (chat) {
      this.openChat(chat.id, user.id);
    } else {
      this.userChats.unshift({
        id: `${user.id}${user.name}`,
        receiver: user.id,
        last_message: {
          id: 0,
          chat_id: `${user.id}${user.name}`,
          sender_id: this.user.id,
          content_message: "",
          created_at: "",
          name: user.name,
          avatar: user.avatar,
        },
      });
      this.openChat(`${user.id}${user.name}`, user.id);
    }
    this.isSearchResultsViewed = false;
  }

  isUserHaveChat(id) {
    return this.userChats.find((chat) => chat.receiver === id);
  }

  messagesSelectImages() {
    this.selectingImages = true;
    this.openFileInput();
  }
  messagesSelectFile() {
    this.selectingImages = false;
    this.openFileInput();
  }
  openFileInput() {
    this.selectedFile = null;
    this.selectedImages = [];
    this.selectedImagesBlob = [];

    setTimeout(() => {
      document.getElementById("fileInput").click();
    }, 0);
  }
  selectFile(event) {
    const files = event.target?.files;
    if (this.selectingImages) {
      this.handleSelectedImages(files);
    } else {
      this.handleSelectedFile(files[0]);
    }
  }
  handleSelectedImages(files) {
    if (files.length) {
      this.selectedImages = Object.entries(files).map((e) => e[1]);
      files.forEach((file) => {
        this.selectedImagesBlob.push(window.URL.createObjectURL(file));
      });
    }
  }
  handleSelectedFile(file) {
    if (file) {
      this.selectedFile = file;
      // this.fileLink = window.URL.createObjectURL(this.file);
    }
  }
  removeImage(fileIndex) {
    const newSelectedImages = this.selectedImages.filter(
      (_, index) => index != fileIndex
    );
    const newSelectedImagesBlob = this.selectedImagesBlob.filter(
      (_, index) => index != fileIndex
    );

    this.selectedImages = [...newSelectedImages];
    this.selectedImagesBlob = [...newSelectedImagesBlob];
  }

  openLink(link) {
    if (link) {
      window.open(this.getLink(link), "_blank");
    }
  }
  getLink(link) {
    if (typeof link == "string" && link.length > 0) {
      if (link.startsWith("http")) {
        return link;
      } else {
        return `//${link}`;
      }
    }
  }

  viewName(name, length) {
    if (name.length <= length) {
      return name;
    } else {
      return `${name.substring(0, length)}...`;
    }
  }
}
</script>

<style lang="scss" scoped>
.userMessages {
  height: 840px;
  border-radius: 20px;
  border: 1px solid var(--border-color-02);
  background-color: var(--white);

  &__friends-section {
    border-radius: 0 0 20px 0;
    background: var(--background-color05);
    overflow-y: auto;
    border: 0px solid var(--input-field-border);
    border-left-width: 1px;
    height: 100%;

    &.en {
      border-radius: 0 0 0 20px;
      border-left-width: 0px;
      border-right-width: 1px;
    }

    &__search {
      border: 0px solid var(--input-field-border);
      border-bottom-width: 1px;
      padding: 11px 4px 4px;
    }

    &__friend {
      padding: 16px 12px;
      margin: 20px;
      cursor: pointer;
      border: 1px solid var(--input-field-border);
      border-radius: 4px;

      &.active {
        background: var(--bg-light-01);
        border-width: 0;
      }

      .friend-avatar {
        border-radius: 50%;
      }

      .friend-name {
        color: var(--text-color-01);
        font-size: 0.75rem;
        font-weight: 700;

        &.only-name {
          margin-top: 15px;
        }
      }

      .last-message {
        color: var(--text-color-02);
        font-size: 0.75rem;
      }

      .date {
        color: var(--text-color-02);
        font-size: 0.75rem;
      }
      .unreadedMessages-number {
        color: white;
        font-size: 0.75rem;
        font-weight: 500;
        background-color: var(--primary);
        width: 19px;
        height: 19px;
        border-radius: 50%;
      }
    }
  }

  &__messages-section {
    position: relative;
    padding: 8px;

    &__inner {
      background-color: var(--bg-light-01);
      border-radius: 12px;
      overflow: hidden;
    }
    &__heading {
      padding: 23px 20px;
      border-radius: 20px 0 0 0;
      border-bottom: 1px solid var(--input-field-border);

      &.en {
        margin-right: -1px;
        border-radius: 0 20px 0 0;
      }

      img {
        border-radius: 50%;
      }

      .friend-name {
        color: var(--text-color-01);
        font-size: 1.125rem;
        font-weight: 600;
      }

      .icon-buttons {
        img {
          cursor: pointer;
        }
      }
    }

    &__messages {
      padding: 32px 32px 0;
      max-height: 622px;
      overflow-y: auto;

      &.waiting-upload {
        max-height: 572px;
      }

      .message {
        margin-bottom: 40px;

        img {
          border-radius: 50%;
        }

        &__text-container {
          background: var(--background-color04);
        }

        &__text {
          padding: 14px 25px;
          font-size: 0.75rem;
          line-height: 24px;
          max-width: 290px;
          border-radius: 10px;
          line-height: 20px;

          i {
            transform: rotate(-30deg);
          }

          &__user {
            background: var(--primary);
            color: white;

            i {
              color: white;
            }
          }
          &__friend {
            background: var(--text-mute-05);
            color: var(--dark-primary);
          }
        }

        &__attachment-container {
          max-width: 218px;
        }
        &__attachment-column {
          position: relative;
          cursor: pointer;

          &__name {
            font-size: 0.75rem;
          }
        }
        &__attachment {
          &__user {
            border-radius: 16px 16px 16px 0px;

            &.en {
              border-radius: 16px 16px 0px 16px;
            }
          }
          &__friend {
            border-radius: 16px 16px 0px 16px;

            &.en {
              border-radius: 16px 16px 16px 0px;
            }
          }
        }
        &__attachment-grid {
          width: 104px;
          height: 104px;
        }
        &__attachment-single {
          width: 180px;
          height: 150px;
        }
        &__extra-attachments {
          position: absolute;
          width: 104px;
          height: 104px;
          top: 0;
          right: 0;
          background: rgba(1, 1, 1, 0.5);
          border-radius: 16px 16px 0px 16px;
          color: white;
          font-size: 0.875rem;
        }

        &__time {
          color: var(--text-mute-04);
          font-size: 0.75rem;
        }
      }
    }

    &__send-message {
      padding-top: 20px;
      margin-inline: 20px;

      &.is-files-uploaded {
        padding-top: 10px;
      }
      &__message {
        width: fill;
      }
    }
  }

  &__viewed-files {
    margin-inline: 20px;
    background-color: white;
    padding-block: 10px;
    padding-inline: 5px;
    border-radius: 10px;

    &__container {
      margin-inline: 5px;
      position: relative;

      .viewed-image {
        border: 1px solid var(--input-field-border);
      }
      .viewed-file {
        border: 1px solid var(--input-field-border);
        width: 200px;
        height: 32px;
        background: var(--text-mute-05);
        font-size: 0.75rem;
        padding-inline: 10px;
        padding-top: 5px;
      }
      .close-icon {
        background-color: white;
        border-radius: 50%;
        color: red;
        position: absolute;
        top: 3px;
        right: 3px;
        border: 1px solid var(--input-field-border);
        cursor: pointer;
      }
    }
  }
}
</style>