<template>
  <v-app id="app" :key="$i18n.locale">
    <v-main>
      <router-view />
    </v-main>

    <v-btn
      @click="scrollToTop"
      v-if="showScrollTop"
      width="35"
      height="35"
      x-small
      color="white"
      style="position: fixed; bottom: 15px; right: 1%"
    >
      <v-icon color="primary">mdi-chevron-up</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  name: "App",

  components: {},
  created() {
    this.setInitLang();
    window.addEventListener("scroll", this.handleScroll);
  },
  data: () => ({
    showScrollTop: false,
  }),

  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      this.showScrollTop = window.scrollY > 50;
    },
    setInitLang: function () {
      const lang = this.$route?.params?.lang || "ar";
      this.$vuetify.rtl = lang !== "en";
      this.$i18n.locale = lang;

      if (typeof window != "undefined") {
        document
          .querySelector("body")
          .setAttribute("dir", lang == "ar" ? "rtl" : "ltr");
      }

      ApiService.setHeaderLang(lang);

      const newPath = this.getNewPath(lang);

      if (this.$route.fullPath !== newPath) {
        this.$router.replace(this.getNewPath(lang));
      }
    },
    getNewPath: function (lang) {
      const fullPath = this.$route.fullPath;
      const route = fullPath.split("/");

      if (route[route.length - 1] === "") {
        route.pop();
      }

      const oldLang = route[route.length - 1];

      if (oldLang === "ar" || oldLang === "en") {
        return fullPath;
      }

      route.push(lang);

      return route.join("/");
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Regular.ttf");
  font-weight: 300;
}
@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Almarai;
  src: url("./assets/fonts/Almarai-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Almarai;
  src: url("./assets/fonts/Almarai-Bold.ttf");
  font-weight: 700;
}

#app {
  position: relative;
}

/* *** RESET CODE *** */

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --basic-color-01: #220054;
  --primary: #220054;
  --secondary: #c8a45d;
  --white: #ffffff;
  --btn-bg: #220054;
  --bg-light: #e5e5e5;
  --bg-light-01: #f6f7fb;
  --text-mute: #777e90;
  --text-mute-01: #737680;
  --text-mute-02: #48546d;
  --text-mute-03: #bdc0cc;
  --text-mute-04: #737680;
  --text-mute-05: #e4e9ef;
  --text-mute-06: #cdd2d9;

  --color-gold: #c8a45d;
  --light-gray-01: #eceff1;
  --dark-primary: #1b2534;
  --input-field-border: #dedede;
  --input-field-color: #f1f1f1;

  --bg-blue: #f2f6ff;

  --color-text: #484b50;
  --color-text-dark: #1b2534;
  --color-text-light: #878787;

  --color-success: #5fbb85;

  --text-color-01: #202020;
  --text-color-02: #5d5a5a;
  --text-color-04: #49505b;
  --text-color-03: #548e2d29;

  --text-color-05: #152c5b;
  --text-color-06: #152c7b;

  --border-color-01: #dfe7df;
  --border-color-02: #eeeeee;
  --border-color-03: #eceff1;

  --shadow-color-01: #0000001a;

  --gradient-color-start: #00a49b;
  --gradient-color-end: #0871b7;

  --error-font: #e83a3a;
  --error-border: #e83a3a;
  --error-background: #e83a3a26;

  --font-en: "Inter", sans-serif;
  --font-ar: "Almarai", serif;

  --light-gray-bg: #eef1f2;
  --light-gray-bg-01: #f5f8fa;
  --light-gray-bg-02: #ececec;
  --dark-gray: #777e90;
  --bar-header-bg: #eef2f3;
}

html[dir="rtl"],
[dir="rtl"] body,
[dir="rtl"] .v-application {
  font-family: var(--font-ar) !important;
  --font-en: "Almarai", serif;
}

.v-application,
body,
html {
  // BASE FONT SIZE
  font-size: 16px !important;
  font-family: var(--font-en) !important;
  font-weight: 300 !important;
  letter-spacing: 0px !important;
  text-align: start;
  @media only screen and (max-width: 992px) {
    font-size: 14px !important;
  }
  @media only screen and (max-width: 768px) {
    font-size: 13px !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: var(----text-color-01);
}

h1 {
  font-size: 3.4375rem !important;
}
h2 {
  font-size: 2.25rem !important;
}
h3 {
  font-size: 1.75rem !important;
}
h4 {
  font-size: 1.5rem !important;
}
h5 {
  font-size: 1.25rem !important;
}
h6 {
  font-size: 1.125rem !important;
}

p {
  font-size: 1rem;
}

.v-main {
  padding: 0 !important;
}
a {
  text-decoration: none !important;
}
.row {
  justify-content: center;
}
.v-otp-input {
  .v-input__slot {
    width: 60px !important;
    height: 60px !important;
  }

  .v-input {
    border-radius: 16px;
    .v-input__control {
      justify-content: center;
      align-items: center;
    }
    fieldset {
      border: 1px solid #eeeeee !important;
    }

    &.v-input--is-focused {
      fieldset {
        border-color: var(--primary) !important;
      }
    }
  }
  .v-date-picker-header .v-btn {
    color: rgba(0, 0, 0, 0.54);
    background: #f3f3f3 !important;
  }
}
</style>