export default {
  header: {
    main: "Home",
    about: "About us",
    properties: "Properties",
    faq: "FAQs",
    contact: "Contact us",
    login: "Login",
  },
  footer: {
    underIconText:
      "ZRealtors.com is a double-sided platform that offers a smart way to buy real estate,",
    underIconText2: " Made by Trend",
    importantLinks: "Important links",
    about: "About us",
    properties: "Properties",
    faq: "FAQs",
    contact: "Contact us",
    privacyPolicy: "Privacy policy",
    termsAndConditions: "Terms and Conditions",
    contactInfo: "Contact info",
    copyRights: "All rights reserved.",
    Login: "Login",
    projects: "Projects",
    developers: "Developers",
    realEstateGuide: "Real Estate Guide",
    commercialServices: "Commercial Services",
    partnerWithUs: "Partner with us",
    becomePartner: "Become A Partner",
    reserveYourProperty: "Reserve Your Property",
    footerLocation: "3 Saad area, Menkaure Gate ,Hadayek El ahram, Giza",
  },
  LogIn: {
    ExploreFuture: "Explore the future with us",
    EazySolution: "Zrealtors provides an easy solution!",
  },
  password: {
    password: "Password",
    newpassword: "New Password",
    confirmpassword: "Confirm Password",
    welcomeMessage: "Hi, Welcome Back!",
    ResetPassword: "Reset Password",
    CreateAccount: "Create an Account",
    email: "Email address *",
    phone: "Mobile Number",
    name: "Name",
    SignIn: "Sign In",
    SignUp: "Sign Up",
    OrSignUp: "Or Sign Up",
    OrSignIn: "Or Sign In",
    rememberMe: "Remember Me",
    HaveAccountMessage: "Already have an account? ",
    dontHaveAccountMessage: "Don't have an account? ",
    SendOTP: "Send OTP",
    SendLink: "Send Link",
    EmailVerification: "Email Verification",
    NewPassword: "New password",
    ConfirmPassword: "Confirm password",
    PassValdate: "At least 8 characters",
    CreatePassword: "Create Password",
    CreateNewPassword: "Create New Password",
    EnterYourDetailsLog: "Enter details to Log In",
    EnterYourDetails: "Enter details to create your account",
    EnterYourEmail: "Enter your register email address",
    ForgotPassword: "Forgot password ?",
    SentOTPMessage: " We have sent to 4 digit code on your email",
    DidntReceiveMessage: "Didn’t receive code? ",
    Resend: "Resend",
    Successfully: "Successfully",
    SuccessMessage: " Your can now use your new password ",
    SuccessMessage2: "  to sign in to your account",
    BackToSignIn: "Back to Sign In",
    text: "............",
  },
  errors: {
    required: "Required",
    minLength: "Characters number must be more than {num}",
    maxLength: "Characters number must be less than {num}",
    email: "Wrong email",
    passwordMatch: "Password Didn`t Matching",
    phone: "Enter valid phone number",
  },
  home: {
    findProperty: "Find a Property",
    makeRequest: "Make a Request",
    priceRange: "Price Range",
    category: "Category",
    location: "Location",
    RecentlySearchLocations: "Recently search locations",
    post1: `Learn about your home's value and selling options.`,
    post2: "Looking to Buy a new property or Sell an existing one?",
    postDescription2: "ZRealtors provides an easy solution!",
    readMore: "Read More",
    featuredProperties: "Featured Properties",
    viewAll: "View All",
    viewDetails: "View Details",
    ReadMore: "Read More >",
    contactUs: "Contact Us",
    newsUpdate: "News Update",
    newsUpdate2: "Latest News & Expos",
    premium: "Premium",
    beds: "beds",
    baths: "baths",
    sqft: "sqft",
    recentLocationSearch: "Recent Search Locations",
    post2Description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry. \n
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy.`,
    searchLocation: "Search a location",
  },
  properties: {
    home: "Home",
    properties: "Properties",
    total: "Total",
    results: "results",
    sortby: "sort by",
    lowestPrice: "Lowest Price",
    highestPrice: "Highest Price",
    advancedSearch: "Advanced Search",
    propertyCategory: "Property Category",
    propertyLocation: "Property Location",
    propertyFloor: "Property Floor",
    propertyType: "Property Type",
    view: "View",
    finishing: "Finishing",
    paymentMethod: "Payment Method",
    deliveryDate: "Delivery Date",
    reset: "reset",
    search: "Search",
    priceRange: "Price Range",
    area: "Area",
    bedrooms: "Bedrooms",
    bathrooms: "Bathrooms",
    saveSearch: "Save Search",
    FeaturedPropertiesInLocation: "Featured Properties in this Location",
    Residential: "Residential",
    Administrative: "Administrative",
    Commercial: "Commercial",
    TotalResult: "Total 15 result",
  },
  profile: {
    profile: "Profile",
    myProfile: "My Profile",
    myWishlists: "My Wishlists",
    ContactedUnits: "Contacted Units",
    MyRequests: "My Requests",
    Logout: "Logout",
    profileInfo: "Personal Info",
    EditProfile: "Edit Profile",
    name: "Ahmed Mohamed",
    Email: "Email",
    Password: "Password",
    MobileNumber: "Mobile Number",
    Language: "Language",
    City: "City",
    Gender: "Gender",
    Cairo: "Cairo",
    Alex: "Alexandria",
    Male: "Male",
    Female: "Famale",
    English: "English",
    Married: "Married",
    Single: "Single",
    DateOFBirthe: "Date of Birthe",
    MaritalStatue: "Marital Statue",
    NumberOfChildern: "Number Of Childern",
    statistics: "statistics",
    OpenedRequests: "Opened Requests",
    ClosedRequests: "Closed Requests",
    SavedSearch: "Saved Search",
    SearchName: "Search Name",
    Action: "Action",
    SearchText: "Properties in cairo less than 700k",
    ApplySearch: "Apply search",
    ViewProfile: "View Profile",
    UploadPicture: "Upload your picture",
    UploadPictureText:
      "For best results, use an image at least 300px by 300px in either JPEG or PNG",
    Upload: "Upload",
    Remove: "Remove",
    Save: "Save",
    Cancel: "Cancel",
    FirstName: "First Name",
    LastName: "Last Name",
    selectDate: "Select Date",
    MyLeads: "My Leads",
    Leads: "Leads",
    MyCILs: "My CILs",
    CILs: "CILs",
    MyProperties: "My Properties",
    Properties: "Properties",
    Messages: "Messages",
    Settings: "Settings",
    edit: "Edit",
    Expertise: "Expertise",
    ExpertiseArea: "Expertise Area:",
    ExpertiseAreaText: "From 300,000 to 300,500 LE",
    Categories: "Categories:",
    CategoriesText: "Commercial,Adminstartive",
    ExpertIn: "Expert In:",
    ExpertInText: "Emaar,TMG",
    WorkExperience: "Work Experience",
    Add: "Add",
    RealEstateCompany: "Real Estate Company",
    RealEstateStartup: "Real Estate Startup",
    SeniorConsultant: "Senior Consultant",
    SeniorRealtor: "Senior Realtor",
    BuyerAgent: "Buyer Agent",
    EditExpertise: "Edit Expertise",
    AddWorkExpr: "Add Work Experience",
    Title: "Title",
    AddTitle: "Add The Title",
    Company: "Company",
    AddCompany: "Write The Company Name",
    emptySavedSearch: "Looks like you've not added any saved search yet!",
  },
  blogs: {
    home: "Home",
    blogs: "Blogs",
    properties: "Properties",
    All: "All",
    News: "News",
    login: "Login",
    Tips: "Tips",
    Guide: "Guide",
    ReadMore: "Read More >",
    Search: "Search",
    SearchKeywords: "Search Keywords",
    LatestArticles: "Latest Articles",
    BlogPreview: "Blog Preview",
    BlogPreviewText:
      "The Cityscape Virtual Conference is the real estate conference Cityscape Virtual Conference is the real...",
  },

  shared: {
    backToHome: "Back to home",
    home: "Home",
    ourProjects: "Our Projects",
    Developers: "Developers",
    ContactUs: "Contact Us",
    AboutUs: "About Us",
    Terms: "Terms And Conditions",
    privacy: "Privacy Policy",
    projects: "Projects",
    CommercialService: "Commercial Service",
  },
  req_meeting: {
    title: "Request a Meeting",
    steps: {
      step_1_title: "Area",
      step_2_title: "Property Category",
      step_3_title: "Property Type",
      step_4_title: "Pricing",
      step_5_title: "Personal Info",
    },
    btns: {
      skip: "Skip",
      next: "Next",
      previous: "Previous",
      submit: "Submit",
    },
    Area: "Area",
    EnterArea: "Enter Area",
    Sqft: "Sqft",
    EnterPropertyCategory: "Enter Property Category",
    PropertyCategory: "Property Category",
    EnterPropertyType: "Enter Property Type",
    PropertyType: "Property Type",
    EnterPriceRange: "Enter Price Range",
    PriceRange: "Price Range",
    EnterPersonalInfo: "Enter Personal Info",
    Name: "Name",
    MobileNumber: "Mobile Number",
    Email: "Email",
    Comment: "Comment",
    WriteComment: "Write Comment ...",
  },
  unit: {
    unitDetails: "Unit Details",
    otherPropertiesFrom: "Other Properties From",
    registerInterest: "Register an Interest",
    floor: "Floor",
    addWishList: "add wishlist",
  },
  projects: {
    AdvancedSearch: "Advanced Search",
    ProjectType: "Project Type",
    projectLocation: "Project Location",
    PriceRange: "Price Range",
    Area: "Area",
    Sqft: "Sqft",
    Finishing: "Finishing",
    Reset: "Reset",
    Search: "Search",
    SaveSearch: "Save Search",
    CommercialProjects: "Commercial Projects",
    Total: "Total",
    results: "results",
    SortBy: "Sort By",
    StartingPrice: "Starting Price",
    Downpayment: "Down payment",
    over: "over",
    projects: "projects",
    projectDetails: "Project Details",
    downpayment: "Down payment",
    installmentYears: "Installment Years",
    Years: "Years",
    PaymentPlans: "Payment Plans",
    plan1: "Plan 1",
    plan2: "Plan 2",
    plan3: "Plan 3",
    viewMore: "View More",
    Share: "Share :",
    otherProjectes: "You also may like",
  },
  developers: {
    ShowMore: "Show More",
  },

  meetingDialog: {
    dialogTitle: "Your Gateway to a Richer Life",
    SimilarUnitsIn: "Similar Units In",
    Name: "Name",
    MobileNumber: "Mobile Number",
    EmailAddress: "Email Address",
    Project: "Project",
    ProjectType: "Project Type",
    Boker: "Boker",
    CallTime: "Call Time",
    Communication: "Communication",
    PaymentMethod: "Payment Method",
    Cash: "Cash",
    Installments: "Installments",
    Comment: "Comment",
    WriteComment: "Write Comment",
    RegisterNow: "Register Now",
  },
  modal: {
    saveSearch: "Save Search",
    save: "Save",
    cancel: "Cancel",
    enterSearchName: "Enter Search Name",
    search: "Search",
    addWishList: "Add Wishlist",
    enterNewName: "Enter New Name",
    or: "or",
    selectFromList: "Select From List",
    newWishList: "new wishlist",
    confirmMessage: "Are you sure?",
    delete: "Delete",
  },
  requests: {
    MyRequests: "My Requests",
    Requests: "Requests",
    Total: "Total",
    results: "results",
    Pending: "Pending",
    Offered: "Offered",
    PropertyName: "Property Name",
    Date: "Date",
    Status: "Status",
    Action: "Action",
    Details: "Details",
    RequestDetails: "Request Details",
    Name: "Name",
    Price: "Price",
    Type: "Type",
    Area: "Area",
    View: "View",
  },
  wishlist: {
    myWishlists: "My Wishlists",
    addNewList: "Add New List",
  },
  faq: {
    frequentlyAskedQuestions: "Frequently Asked Questions",
    FAQ: "FAQ",
    FAQText:
      "This page contains a collection of frequently asked questions and answers. If you do not find the answer to your question here, you can contact us through the contact page.",
  },
  about: {
    aboutHeader: "Cityscape Egypt 2022",
    aboutText1:
      "Zrealtos is your first destination for real estate in Egypt in the easiest way. View real estate and real estate picture details. Compare and contact the real estate developer for free.",
    aboutText2:
      "Gain insight into assets in development Buying, selling and renting real estate We strive to achieve and fulfill your dreams. It strives to work it out and continues to work in the future. . Do not hesitate to contact us if you have any comments or questions.",
    aboutText3:
      "Zrealtos provides you with leading data to help you find real estate all over Egypt making it as easy and convenient as possible. Connect with the best local professionals and browse our wide range of commercial and residential listings. You can buy all kinds of real estate; From new developments to resale, and everything in between. We will even help you sell your property. At Iskan.com, we are determined to simplify the process, while providing you with everything the industry has to offer.",
  },
  contact: {
    ContactInformation: "Contact Information",
    Email: "Email",
    Phone: "Phone",
    Location: "Location",
    Name: "Name",
    Message: "Message",
    YourMessage: "Your message",
    Send: "Send",
    getTouchMessage:
      "Want to get in touch? We'd love to hear from you. Here's how you can reach us...",
  },
  messages: {
    myMessages: "My Messages",
    searchMessages: "Search Messages",
    createNewMessage: "Create New Message",
    admins: "Admins",
    clients: "Clients",
    filterby: "Filter by",
    Send: "Send",
    getTouchMessage:
      "Want to get in touch? We'd love to hear from you. Here's how you can reach us...",
    recievedMessages: "Recieved Messages",
    sentMessages: "Sent Messages",
    search: "Search People",
    writeMessage: "Type message here",
  },
  Terms: {
    TermsHeader: "Terms And Conditions",
    TermsHead1: "Terms and conditions of use of ZRealtors official website",
    Termstext:
      "Welcome to the official company website (ZRealtors.), also referred to as (ZRealtors) on the internet referred to as (Site) in this document.",
    TermsHead2:
      "Reviewing an Reading the Terms and Conditions of Using the Site:",
    Termstext2:
      'The process of accessing the site, browsing and downloading materials or copying contents is referred to with the word "use" of this site. Please carefully read the terms and conditions set forth below and in particular the privacy and confidentiality statements before using this site.',
    Termstext3:
      "To access this site and use it, the user is supposed to have read these terms and conditions and understood its content, and the use of this site is acceptable subject to the provisions of these terms and conditions, and in the case of non-compliance with these conditions, the user must cease the use the site immediately.",
    Termstext4:
      "Please note that these conditions may change from time to time, at our discretion. Users are advised to read the site conditions and potential changes regularly, in case you need a hard copy you can send a request by email including your contact information.",
  },
  policy: {
    privacyPolicy: "Privacy policy",
    policyHead1: "Information collection and use:",
    policyText1:
      "We collect a large number of miscellaneous information for various purposes in order to provide and improve the services available on the Site to you, including but not limited to:",
    Email: "Email",
    name: "First and Last Name",
    phonenumber: "phone number",
    address: "Address, region, county, zip code, and city.",
    Cookies: "Cookies and usage data.",
    Data: "Data from social networks If you use your social network accounts to register and create an account on the Site, information used from social networking sites such as Facebook, and Google+ includes:",
    username: "The username of the social network.",
    Location: "Location",
    sex: "Sex",
    DateBirth: "Date Of Birth",
  },
  dataTable: {
    Date: "Date",
    Name: "Name",
    StartingDate: "StartingDate",
    EndDate: "EndDate",
    Selling: "Selling",
    Status: "Status",
  },
  notifications: {
    notifications: 'Notifications',
    viewMore: "View more",
    noNotifications: 'There are no notifications at the moment',
    loadMore: 'Load More'
  },
};
