











export default {
  name: 'LoadingComponent',
  props: {
    size: {
      type: Number,
      default: 100
    }
  },
}
