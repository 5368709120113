<template>
  <div
    class="w-full border border-solid border-gray-200 bg-white rounded-md shadow-xl shadow-gray-100 p-6 md:p-7"
  >
    <v-form>
      <CustomTextInput
        :placeholder="$t('contact.Name')"
        class="customTextFieldInput"
        :label="$t('contact.Name')"
        :data="formData.name"
        :rules="[rules.required ]"
        isDense
      />
      <CustomTextInput
        placeholder="example@gmail.com"
        class="customTextFieldInput"
        :label="$t('contact.Email')"
        :data="formData.email"
        :rules="[rules.required, rules.email]"
        isDense
      />

      <CustomTextarea
        :placeholder="$t('contact.YourMessage')  + '...'"
        class="customTextFieldInput"
        :label="$t('contact.Message')"
        :data="formData.message"
        :rules="[rules.required ]"
      />

      <v-btn
        isDense
        elevation="0"
        class="flex-grow-0 rounded-lg px-10 md:px-12 leading-tight normal-case"
        color="primary"
        large
        block
      >{{$t('contact.Send')}}</v-btn>
    </v-form>
  </div>
</template>

<script>
import CustomTextInput from "@/components/shared/customTextInput/CustomTextInput.vue";
import CustomTextarea from "@/components/shared/customTextarea/CustomTextarea.vue";
import rules from "@/services/rules";

export default {
  components: { CustomTextInput, CustomTextarea },
  data() {
    return {
      rules,
      formData: {
        name: "",
        email: "",
        message: ""
      }
    };
  }
};
</script>