<template>
  <v-row
    class="searchHeader align-center px-5"
    no-gutters
  >
    <v-col
      cols="12"
      md="7"
      sm="8"
      lg="8"
      xl="9"
    >
      <h6>{{ $t("profile."+ title) }}</h6>
      <p class="totalResult">{{ $t("properties.total") }} {{ items.length }}
        {{ $t("properties.results") }}</p>
    </v-col>
    <v-col
      cols="12"
      md="5"
      sm="4"
      lg="4"
      xl="3"
    >
      <v-row
        class="content myLeadsHeaderContent"
        no-gutters
      >
        <v-col
          cols="12"
          class="filterSortingLabelContainer"
        >
          <p class="inline-flex">{{ $t("properties.sortby") }}</p>
          <custom-select
            class="customSort"
            appendedIcon="mdi-chevron-down"
            :items="sortList"
            :data="sortColumn"
            @data-changed="changeSort"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script >
import CustomSelect from "@/components/shared/customSelect/CustomSelect.vue";
export default {
  props: [
    "title", "items", 'sortColumn', 'sortList'
  ],
  data() {
    return {
      sortByItems: [
        {
          text: this.$i18n.locale == "ar"
            ? "التاريخ"
            : "Date"
        },
        {
          text: this.$i18n.locale == "ar"
            ? "اقل سعر"
            : "Lowest Price"
        },
        {
          text: this.$i18n.locale == "ar"
            ? "اعلى سعر"
            : "Highest Price"
        },
      ],
      sortByValue:
        this.$i18n.locale == "ar"
          ? "التاريخ"
          : "Date",
    };
  },
  methods: {
    changeSort(value) {
      this.$emit("handleSort", value)
    },
  },
  components: {
    CustomSelect,
  },
};
</script>


<style lang="scss" scoped>
.searchHeader {
  background: var(--white);
  border-radius: 8px;
  h6 {
    display: inline-flex;
    font-weight: 600;
    line-height: 28px;
    color: var(--dark-primary);
  }
  p {
    font-weight: 500;
    line-height: 22px;
    color: var(--dark-primary);
    margin-bottom: 0px;
  }
  .totalResult {
    display: inline-flex;
    margin-inline: 20px;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 15px;
  }

  .customSort {
    display: inline-flex;
    width: min-content;
    border: none !important;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .searchHeader {
    padding-block: 20px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  .searchHeader {
    padding-block: 20px;
    margin-bottom: 20px;
  }
}
</style>
