<template>
  <div class="featuredContainer">
    <div class="featuredTitle">
      <h2>{{$t('home.featuredProperties')}}</h2>
      <v-row>
        <v-col>
          <p
            class="d-inline-flex"
          >{{this.$i18n.locale == 'ar' ? 'وحدات سكنية متميزة مساحات متنوعة' : 'Lorem Ipsum is simply dummy text of the printing.'}}</p>
        </v-col>
        <v-col>
          <router-link :class="$i18n.locale=='ar'?'float-left':'float-right'"  :to="`/properties/${$route.params.lang}`">{{$t('home.viewAll')}}</router-link>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        sm="12"
        xs="12"
        v-for="card in displayedCard"
        :key="card.id"
        class="m-0 mt-md-5 mt-sm-2 mb-md-5 mb-sm-2"
      >
        <featured-card :imgmdCols="4" :detailsmdCols="8" :card="card" />
      </v-col>
    </v-row>
  </div>
</template>

<script >
import FeaturedCard from "./FeaturedCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "FeaturedContainer",
  components: {
    FeaturedCard
  },
  computed:{
    ...mapGetters(["cards"]),
    displayedCard () {
      return this.cards.filter(card => card.id < 4).map(item => {
        item.priceOffer = null;
        return item;
      });
    }
  },
  data() {
    return {
    };
  }
};
</script>

<style scoped lang="scss">
.featuredContainer {
  background: var(--light-gray-bg);
  padding: 50px 150px;
}
.featuredTitle {
  position: relative;
}
.featuredTitle h2 {
  color: var(--primary);
}
.featuredTitle p {
  color: var(--dark-gray);
}
.featuredTitle a {
  color: var(--primary);
  bottom: 0;
}

@media only screen and (max-width: 600px) {
  .featuredContainer {
    padding: 50px 30px;
  }
}
</style>
