<template>
  <div
    class="dev_card bg-white rounded-lg px-14 py-12 md:px-16 md:py-14 flex items-center justify-center"
  >
    <img
      :src="require(`@/assets/img/developers/${developer.image}`)"
      :alt="developer.name"
      class="w-full"
    />
  </div>
</template>

<script>
export default {
  props: {
    developer: Object
  }
};
</script>