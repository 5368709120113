<template>
  <div class="max-w-sm mx-auto">
    <div class="text-center mb-6">
      <h4>{{ $t("password.EmailVerification") }}</h4>

      <p class="mt-4 text-text-light">
        {{ $t("password.SentOTPMessage") }}
        <br />
        <span class="text-text-dark font-semibold">mohamed95@gmail.com</span>
      </p>
    </div>

    <v-form>
      <div class="ma-auto">
        <div class="verivication-input-wrapper">
          <v-otp-input
            class="otp-verifiction-input"
            outlined
            required
            v-model="otpValues"
            :length="4"
          />
        </div>
      </div>

      <div class="ma-auto text-center mt-4">
        <CustomButton
          :classes="newsletter + ' text-base capitalize'"
          :click="submitHandler"
          large
          block
          :text="$t('password.SendOTP')"
        />
      </div>
    </v-form>

    <div class="resend-verification-ancor mt-8 text-center text-text-light">
      <p>
        {{ $t("password.DidntReceiveMessage") }}
        <a class="font-semibold text-text-dark">{{ $t("password.Resend") }}</a>
      </p>
    </div>

    <div class="pa-0 mt-4 text-center">
      <div class="backToSignAncor">
        <router-link :to="`/login/${$route.params.lang}`" tag="a">{{
          $t("password.BackToSignIn")
        }}</router-link>
      </div>
    </div>
  </div>
</template>
 
<script>
import CustomButton from "../shared/Button/CustomButton.vue";
export default {
  components: {
    CustomButton
  },
  data() {
    return {
      otpValues: ""
    };
  },
  methods: {
    submitHandler() {
      this.$router.push({ path: `/newpassword/${this.$route.params.lang}` });
    }
  }
};
</script>

<style lang="scss" scoped>

.backToSignAncor {
  margin: 5px 0px;
  a {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: var(--secondary) !important;
    text-decoration: none !important;
  }
}
</style>