<template>
  <div>
    <CustomLabel
      v-if="label !== ''"
      :value="label"
      class="mb-2"
    />
    <div>
      <date-picker
        @input="handleChange"
        format="YYYY-MM-DD"
        type="date"
        :value="data"
        :default-value="defaultValue"
        :placeholder="placeholder"
        :lang="lang"
        :multiple="multiple"
      >
        <i slot="icon-calendar">
          <img
            width="20"
            alt="date"
            class="shrink"
            :class="$i18n.locale == 'ar' ? 'ml-2 dateImgAr' : 'mr-2 dateImg'"
            contain
            :src="require(`@/assets/img/ico/calendar.svg`)"
          />
        </i>
      </date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/zh-cn";

import "vue2-datepicker/index.css";
import CustomLabel from "@/components/shared/customLabel/CustomLabel.vue";

export default {
  components: { DatePicker, CustomLabel },
  props: {
    label: { default: "" },
    defaultValue: {
      type: Date,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    data: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      time1: new Date(1995, 7, 17),
      lang: "en",

      direction: "ltr",
      separator: " - ",
      applyLabel: "Apply",
      cancelLabel: "Cancel",
      weekLabel: "W",
      customRangeLabel: "Custom Range",
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ]
    };
  },
  methods: {
    dateFormat(classes, date) {
      return classes;
    },
    handleChange(value) {
      this.$emit("data-changed", value);
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },

    changeLocale() {
      this.lang = this.lang === "en" ? "ar" : "en";
    }
  }
};

</script>

<style >
.mx-input {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 54px !important;
  color: #555;
  background-color: var(--white);
  border: 1px solid var(--border-color-02);
  border-radius: 8px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
}
.mx-input::placeholder {
  color: var(--text-mute-03) !important;
}
.mx-input:hover {
  border: 1px solid var(--border-color-02);
}
.mx-datepicker {
  width: 100%;
}
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dateIconAr {
  position: absolute;
  top: 50%;
  left: 8px;
}
</style>