<template class="customHeader" >
  <span>
    <transition name="slide-fade">
      <v-app-bar
        app
        :color="scrollPosition > 50 ? 'primary' : headerColor"
        class="site-app-bar"
      >
        <v-container class="d-flex align-center">
          <router-link
            class="flex flex-grow-0 flex-shrink w-fit"
            :to="`/${$route.params.lang}`"
            tag="a"
          >
            <img
              alt="Logo"
              class="shrink w-fit h-6"
              :class="$i18n.locale == 'ar' ? 'md:ml-16' : 'md:mr-16'"
              contain
              :src="require(`@/assets/logo/primaryLogo.svg`)"
            />
          </router-link>
          <v-list nav dense v-if="$vuetify.breakpoint.lgAndUp">
            <v-list-item-group
              v-model="group"
              :class="[$vuetify.breakpoint.lgAndUp ? 'd-flex' : '']"
            >
              <v-list-item
                :class="[{ 'site-app-bar--active': $route.name == 'home' }]"
              >
                <router-link :to="`/${$route.params.lang}`" tag="a">
                  <v-list-item-title>{{ $t("header.main") }}</v-list-item-title>
                </router-link>
              </v-list-item>

              <v-list-item
                :class="[{ 'site-app-bar--active': $route.name == 'about' }]"
              >
                <router-link :to="`/about/${$route.params.lang}`" tag="a">
                  <v-list-item-title>
                    {{ $t("header.about") }}
                  </v-list-item-title>
                </router-link>
              </v-list-item>

              <v-list-item
                :class="[
                  { 'site-app-bar--active': $route.name == 'properties' },
                ]"
              >
                <router-link :to="`/properties/${$route.params.lang}`" tag="a">
                  <v-list-item-title>
                    {{ $t("header.properties") }}
                  </v-list-item-title>
                </router-link>
              </v-list-item>

              <v-list-item
                :class="[{ 'site-app-bar--active': $route.name == 'faq' }]"
              >
                <router-link :to="`/faq/${$route.params.lang}`" tag="a">
                  <v-list-item-title>{{ $t("header.faq") }}</v-list-item-title>
                </router-link>
              </v-list-item>

              <v-list-item
                :class="[{ 'site-app-bar--active': $route.name == 'contact' }]"
              >
                <router-link :to="`/contact/${$route.params.lang}`" tag="a">
                  <v-list-item-title>
                    {{ $t("header.contact") }}
                  </v-list-item-title>
                </router-link>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-spacer></v-spacer>

          <v-menu
            v-if="this.$store.state.isAuth"
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <v-img
                  alt="calender"
                  class="shrink mr-1 ml-1"
                  contain
                  :src="require(`@/assets/img/ico/light-calendar.svg`)"
                />
              </v-btn>
            </template>
            <v-date-picker
              no-title
              class="customDatePic"
              prev-icon="mdi-arrow-left"
              next-icon="mdi-arrow-right"
              scrollable
            ></v-date-picker>
          </v-menu>

          <notifications-dropdown v-if="this.$store.state.isAuth" />

          <v-btn @click="changeLanguage" text color="info">{{
            $i18n.locale == "ar" ? "en" : "ar"
          }}</v-btn>

          <v-menu
            v-if="this.$store.state.isAuth"
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">
                <v-img
                  alt="calender"
                  class="shrink mr-1 ml-1"
                  contain
                  :src="require(`@/assets/img/Avatar.png`)"
                />
                <div class="d-flex align-center">
                  <div class="drowpdown-profile">Hi Ahmed</div>
                  <div>
                    <v-img
                      alt="calender"
                      width="12"
                      height="10"
                      :src="require(`@/assets/img/ico/ArrowRectangle.svg`)"
                    />
                  </div>
                </div>
              </v-btn>
            </template>
            <div class="dropdown">
              <v-card-title class="text-h5">
                <p class="dropdown__title">{{ $t("profile.ViewProfile") }}</p>
                <v-spacer></v-spacer>
                <v-btn class="dropdown__icon" icon>
                  <img src="@/assets/img/ico/Cheveron.svg" alt="Edit.svg" />
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <div class="account-dropdown">
                <router-link :to="`/user/profile/${$route.params.lang}`">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img src="@/assets/img/Avatar.png" alt="user" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="account-dropdown__title">{{
                        $t("profile.name")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
              </div>
              <div class="account-dropdown">
                <router-link :to="`/realtor/profile/${$route.params.lang}`">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img src="@/assets/img/Avatar2.png" alt="user" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="account-dropdown__title"
                        >Asma Naser</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
              </div>
              <v-divider></v-divider>
              <div>
                <v-btn class="logout-dropdown" @click="Logout">
                  <v-list-item>
                    <v-list-item-avatar size="16.67">
                      <v-img src="@/assets/profile/icons/logout.svg"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="dropdown__logout">
                        {{ $t("profile.Logout") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-btn>
              </div>
            </div>
          </v-menu>

          <v-btn
            v-if="!this.$store.state.isAuth"
            text
            color="info"
            @click="openLogin"
          >
            <v-img
              alt="user"
              class="shrink mr-1 ml-1"
              contain
              :src="require(`@/assets/img/ico/user.svg`)"
            />
            {{ $t("header.login") }}
          </v-btn>

          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            @click.stop="drawer = !drawer"
          />
        </v-container>
      </v-app-bar>
    </transition>

    <v-navigation-drawer
      class="drawerStyle"
      v-model="drawer"
      absolute
      temporary
      :right="$i18n.locale == 'ar'"
    >
      <SideMenu @closeDrawer="log" />
    </v-navigation-drawer>
  </span>
</template>

<script>
import ApiService from "@/services/api.service";
import { mapGetters } from "vuex";
import SideMenu from "./SideMenu.vue";
import NotificationsDropdown from "@/components/notificationsComponents/NotificationsDropdown.vue";

export default {
  name: "CustomHeader",
  components: {
    SideMenu,
    NotificationsDropdown,
  },
  data: () => ({
    drawer: false,
    group: null,
    scrollPosition: null,
    data: "",
  }),
  props: ["headerColor", "auth"],
  methods: {
    log: function () {
      this.drawer = !this.drawer;
    },
    changeLanguage: function () {
      const currentLang = this.$i18n.locale;
      const lang = currentLang == "ar" ? "en" : "ar";
      this.$i18n.locale = lang;
      this.$vuetify.rtl = lang === "ar";
      document
        .querySelector("body")
        .setAttribute("dir", currentLang == "ar" ? "ltr" : "rtl");
      ApiService.setHeaderLang(lang);

      this.$router.replace({
        path: this.getNewPath(lang),
        params: {
          ...this.$route.params,
          lang: lang,
        },
      });
    },
    getNewPath: function (lang) {
      const fullPath = this.$route.fullPath;
      const oldLang = fullPath.split("/").pop();
      const route = fullPath.split("/");

      if (oldLang === "ar" || oldLang === "en" || fullPath === "/") {
        route.pop();
      }

      route.push(lang);

      return route.join("/");
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    openLogin() {
      this.$router.push(`/login/${this.$route.params.lang}`);
    },
    Logout() {
      this.$store.commit("logout");
      this.$router.push(`/${this.$route.params.lang}`);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
};
</script>

<style scoped lang="scss">
.drawerStyle {
  background-color: var(--primary) !important;
  transform: unset !important;
  height: 100vh !important;
  position: fixed;
}
.v-sheet.v-app-bar.v-toolbar.site-app-bar {
  transition: background-color 0.3s;
  box-shadow: none;
}

.v-list {
  background: transparent !important;
}

.v-list-item-group {
  &.hover {
    &::before {
      opacity: 0;
    }
  }

  .v-list-item {
    justify-content: center;
    height: 40px;
    padding: 18px;
    margin-top: 4px;

    .v-list-item__title {
      font-size: 1rem;
      line-height: 1.5rem;
      color: white;
    }
  }

  .v-list-item--active {
    &::before {
      content: none;
    }
  }
}
.dropdown {
  background: var(--white);
  box-shadow: 20px 50px 80px rgba(8, 20, 48, 0.09);
  border-radius: 10px;
  &__logout {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #737680;
  }
}
.account-dropdown {
  margin: 24px 20px;
  background: var(--bg-light-01);
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.02),
    inset 0px -1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  &__title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 22px;
    color: #101018;
  }
}
.logout-dropdown {
  width: 100%;
  overflow: hidden;
}
.drowpdown-profile {
  margin-inline: 5px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 22px;
  color: var(--white);
}
.v-menu__content--fixed {
  top: 45px !important;
  margin-left: -20px;
}
.mdi-menu::before {
  color: white;
}

a {
  text-decoration: none;
  color: var(--text-color-01) !important;
  text-align: center;
}

.site-app-bar--active {
  a {
    color: var(--basic-color-01) !important;
  }
}

.small-menu {
  width: 100%;
  margin-top: 104px;
  text-align: center;
  transform: 0.3s ease-in-out all;
  animation: slidein 0.4s ease-in-out;
}

@keyframes slidein {
  from {
    transform: translateY(-200px);
  }

  to {
    transform: translateY(0%);
  }
}

.slide-fade-enter-active {
  opacity: 1;
  z-index: 10;
}

.slide-fade-leave-active {
  opacity: 1;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
