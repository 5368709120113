<template>
  <div class="mb-8">
    <div class="container">
      <div class="bg-white rounded-lg p-6 md:p-7">
        <h2 class="text-3xl mb-6" style="font-size: 1.875rem !important">
          {{ $t("policy.privacyPolicy") }}
        </h2>
        <div v-for="x in 3" :key="x" class="text-text">
          <ol>
            <li>
              <h6>{{ $t("policy.policyHead1") }}</h6>
              <p>{{ $t("policy.policyText1") }}</p>

              <ul>
                <li>{{ $t("policy.Email") }}</li>
                <li>{{ $t("policy.name") }}</li>
                <li>{{ $t("policy.phonenumber") }}</li>
                <li>{{ $t("policy.address") }}</li>
                <li>{{ $t("policy.Cookies") }}</li>
                <li>
                  {{ $t("policy.Data") }}
                  <ul>
                    <li>{{ $t("policy.username") }}</li>
                    <li>{{ $t("policy.Location") }}</li>
                    <li>{{ $t("policy.sex") }}</li>
                    <li>{{ $t("policy.DateBirth") }}</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
.v-application ul,  .v-application ol {
    padding-inline-start: 24px;
}
</style>