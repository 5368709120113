<template>
  <div v-if="breadcrumbs && breadcrumbs.length" class="flex items-center gap-2">
    <div
      v-for="(item , ix) in breadcrumbs"
      :key="ix+ '-breadcrumb'"
      class="flex items-center flex-grow-0 gap-2 text-text-light"
    >
      <router-link v-if="item.slug" :to="item.slug" class="text-text-dark">{{item.title}}</router-link>
      <span v-else>{{item.title}}</span>
      <span v-if="ix !== breadcrumbs.length - 1" class="flex items-center flex-grow-0">
        <PhCaretDoubleRight class="rtl:hidden" />
        <PhCaretDoubleLeft class="hidden rtl:inline-block" />
      </span>
    </div>
  </div>
</template>
<script>
import { PhCaretDoubleRight, PhCaretDoubleLeft } from "phosphor-vue";
export default {
  props: {
    breadcrumbs: Array
  },
  components: {
    PhCaretDoubleRight,
    PhCaretDoubleLeft
  }
};
</script>