<template>
  <AuthLayout
    :sideLogo="false"
    :backgroundImage="require('@/assets/login/signUp_Partner.png')"
  >
    <PartenerSignUpComponent />
  </AuthLayout>
</template>

<script>
import PartenerSignUpComponent from "@/components/PartenerSignUpComponent/PartenerSignUpComponent.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  components: {
    PartenerSignUpComponent,
    AuthLayout
  }
};
</script>