<template>
  <section class="mt-8">
    <h2
      class="text-[1.75rem] text-center mb-6 text-text-dark"
      style="font-size: 1.75rem !important;"
    >Other Properties From (Project Name)</h2>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <div v-for="card in cards" :key="card.id">
        <!-- <featured-card :imgmdCols="4" :detailsmdCols="8" :card="card" /> -->
        <featured-card
          :imgmdCols="12"
          :detailsmdCols="12"
          :card="card"
          titleStyle="FeaturedTitleStyle"
          contentStyle="contentStyle"
          favIconStyle="favIconStyle"
          specContainer="specContainer"
        />
      </div>
    </div>
  </section>
</template>

<script>
import FeaturedCard from "@/components/homeComponents/featuredCards/FeaturedCard.vue";
export default {
  components: { FeaturedCard },
  data() {
    return {
      cards: [
        {
          id: 0,
          cardImage: ["apartment1", "apartment2"],
          title:
            this.$i18n.locale == "ar"
              ? "شقة تم تجديدها"
              : "Renovated Apartment",
          location: "New Cairo, Egypt",
          specs: [
            { icon: "bed", info: { value: "4", key: "beds" } },
            { icon: "bath", info: { value: "2", key: "baths" } },
            { icon: "squareft", info: { value: "2500", key: "sqft" } }
          ],
          newPrice: "$15000",
          oldPrice: "$12000"
        },
        {
          id: 1,
          cardImage: ["apartment2", "apartment1"],
          title:
            this.$i18n.locale == "ar"
              ? "شقة تم تجديدها"
              : "Renovated Apartment",
          location: "New Cairo, Egypt",
          specs: [
            { icon: "bed", info: { value: "4", key: "beds" } },
            { icon: "bath", info: { value: "2", key: "baths" } },
            { icon: "squareft", info: { value: "2500", key: "sqft" } }
          ],
          newPrice: "$15000",
          oldPrice: "$12000"
        },
        {
          id: 2,
          cardImage: ["villa1", "apartment2"],
          title:
            this.$i18n.locale == "ar"
              ? "شقة تم تجديدها"
              : "Renovated Apartment",
          location: "New Cairo, Egypt",
          specs: [
            { icon: "bed", info: { value: "4", key: "beds" } },
            { icon: "bath", info: { value: "2", key: "baths" } },
            { icon: "squareft", info: { value: "2500", key: "sqft" } }
          ],
          newPrice: "$15000",
          oldPrice: "$12000"
        },
        {
          id: 3,
          cardImage: ["villa2", "apartment1"],
          title:
            this.$i18n.locale == "ar"
              ? "شقة تم تجديدها"
              : "Renovated Apartment",
          location: "New Cairo, Egypt",
          specs: [
            { icon: "bed", info: { value: "4", key: "beds" } },
            { icon: "bath", info: { value: "2", key: "baths" } },
            { icon: "squareft", info: { value: "2500", key: "sqft" } }
          ],
          newPrice: "$15000",
          oldPrice: "$12000"
        }
      ]
    };
  }
};
</script>