<template>
  <div
    class="customdate"
    ref="customdate"
  >
    <CustomLabel
      v-if="label !== ''"
      :value="label"
      labelClass="mb-0"
      class=" mb-2"
    />
    <date-range-picker
      ref="dpicker"
      :class="$i18n.locale == 'ar' ? 'dpickerAr' : 'dpickerEn'"
      :showDropdowns="true"
      :dateFormat="dateFormat"
      :dateRange="dateRange"
      :ranges="false"
      :append-to-body="true"
      :calculate-position="calculatePosition"
      v-model="selectedDate"
      @update="()=>$emit('updateValue',selectedDate)"
    >
      <div
        id="rangeCustomContainer"
        style="position: absolute"
      ></div>
    </date-range-picker>
    <img
      width="20"
      alt="date"
      class="shrink pointer-events-none"
      :class="$i18n.locale == 'ar' ? 'ml-2 dateImgAr' : 'mr-2 dateImg'"
      contain
      v-on:click="openPicker"
      :src="require(`@/assets/img/ico/calendar.svg`)"
      ref="busstop"
    />
  </div>
</template>

<script>
import { Vue } from "vue-property-decorator";

import DateRangePicker from "vue2-daterange-picker";
import PortalVue from "portal-vue";
Vue.use(PortalVue);

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import CustomLabel from "@/components/shared/customLabel/CustomLabel.vue";

export default {
  components: { DateRangePicker, CustomLabel },
  props: ["label", "dateValue"],
  data() {
    return {
      direction: "ltr",
      separator: " - ",
      applyLabel: "Apply",
      cancelLabel: "Cancel",
      weekLabel: "W",
      startDate: "2019-12-26",
      endDate: "2019-12-28",
      customRangeLabel: "Custom Range",
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      firstDay: 0,
      dateRange: {
        startDate: "2019-12-26",
        endDate: "2019-12-28"
      },
      selectedDate: {}
    };
  },
  watch: {
    dateValue() {
      this.selectedDate = this.dateValue;
    }
  },
  methods: {
    dateFormat(classes, date) {
      return classes;
    },
    openPicker() {
      setTimeout(() => {
        this.$refs.dpicker.togglePicker(true);
      }, 200);
    },
    calculatePosition(dropdownList, component, { width, top, left, right }) {
      const leftPos = this.$refs.busstop.getBoundingClientRect().left;
      if (window.innerWidth <= 600) {
        dropdownList.style.left = left + 'px'
        dropdownList.style.top = (top - dropdownList.clientHeight - (this.$refs.customdate.clientHeight / 1.5)) + 'px'
      } else {
        dropdownList.style.left = (leftPos + this.$refs.busstop.clientWidth / 2) + 'px'
        dropdownList.style.top = (top - dropdownList.clientHeight - (this.$refs.customdate.clientHeight / 1.5)) + 'px'
      }
    }
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },

};
</script> 

<style scoped>
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
.dateImg {
  position: absolute;
  right: 0px;
  top: 53%;
}
.dateImgAr {
  position: absolute;
  left: 0px;
  top: 53%;
}
</style>
<style>
.propertiesSearchContainer .glyphicon-calendar:before {
  display: none;
}
.propertiesSearchContainer .caret {
  display: none;
}
</style>
<style >
.dpickerAr .daterangepicker.openscenter {
  z-index: 1 !important;
  left: 11% !important;
  bottom: 130%;
}
.dpickerEn .daterangepicker.openscenter {
  z-index: 1 !important;
  bottom: 130%;
  left: 89%;
}
.daterangepicker:before {
  bottom: -7px;
  top: unset;
  transform: rotate(3.142rad);
}
.daterangepicker:after {
  display: none;
}
.propertiesSearchContainer .customdate {
  background: white;
  text-align: center;
  position: relative;
}
.propertiesSearchContainer .customdate .vue-daterange-picker {
  width: 100%;
}
.propertiesSearchContainer .customdate .reportrange-text {
  text-align: start;
  border-radius: 8px;
}
.daterangepicker.openscenter {
  z-index: 1 !important;
  direction: ltr;
}
</style>