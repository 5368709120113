<template>
  <div class="propertiesContainer">
    <CustomHeader headerColor="primary" />
    <v-container class="containerPadding mt-16">
      <section class="container md:pt-5">
        <BreadCrumb :breadcrumbs="breadcrumbs" />
      </section>

      <v-row class="mt-3 relative">
        <v-col
          cols="12"
          md="3"
          xl="3"
          class="propertiesSearchContainer"
        >
          <div class="custom__scroll w-full top-16">
            <properties-search-bar
              @search="searchHandle"
              @saveSearch="saveSearchHandle"
              :appliedSearch="appliedSearch"
            />

            <div class="h-20"></div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="9"
          xl="9"
        >
          <properties-header
            @setFilterType="
              (GridType) => {
                type = GridType;
              }
            "
            @handleSort="sortElements"
            :recordsCount="recordsCount"
          />
          <v-row
            no-gutters
            class="justify-start mt-md-5 mt-sm-3 mb-md-5 mb-sm-3"
          >
            <v-col
              cols="12"
              :md="type == 'list' ? 12 : 4"
              sm="12"
              xs="12"
              v-for="card in displayedCards"
              :key="card.id"
              class="
                mt-md-2
                pr-2
                pl-2
                mt-sm-2
                mb-md-5 mb-sm-10
                mt-xs-10
                mb-xs-10
              "
            >
              <featured-card
                :class="type != 'list' ? 'pb-15' : ''"
                :imgmdCols="type == 'list' ? 4 : 12"
                :detailsmdCols="type == 'list' ? 8 : 12"
                :card="card"
                :titleStyle="
                  type == 'list' ? 'titleStyle' : 'FeaturedTitleStyle'
                "
                contentStyle="contentStyle"
                :favIconStyle="type == 'list' ? '' : 'favIconStyle'"
                :specContainer="type == 'list' ? '' : 'specContainer'"
              />
            </v-col>
          </v-row>

          <v-pagination
            v-model="page"
            :length="Math.ceil(recordsCount / itemsPerPage)"
            @input="setPaginateItems"
            class="my-7"
          ></v-pagination>
          <img
            width="100%"
            :src="require(`@/assets/img/ads919.png`)"
          />
          <featured-properties />
        </v-col>
      </v-row>
    </v-container>

    <CustomFooter />
  </div>
</template>


<style scoped>
.propertiesContainer {
  background: var(--light-gray-bg-01);
}
.propertiesContainer .breadcrumb {
  margin-bottom: 46px !important;
  margin-top: 40px;
}
.back {
  text-decoration: none;
  color: var(--text-color-01);
}
.text {
  color: var(--text-color-02);
}
.searchHeader {
  background: var(--white);
  border-radius: 8px;
}
.searchHeader h6 {
  display: inline-flex;
}
.searchHeader p {
  display: inline-flex;
  margin: 0;
}
.searchHeader span {
  font-size: small;
  display: inline-flex;
}
.customSelect {
  display: inline-flex;
  width: min-content;
  min-width: 150px;
  border: none !important;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.noShadow {
  box-shadow: none;
}
.inactive {
  filter: brightness(1) invert(0.5) grayscale(100%);
}
.active {
  filter: brightness(0) invert(1);
}

.merginAr {
  margin-left: 20px;
}
.merginEn {
  margin-right: 20px;
}

@media only screen and (max-width: 768px) {
  .propertiesSearchContainer {
    margin-bottom: 20px;
  }
  .merginAr {
    margin: 0 !important;
  }
  .merginEn {
    margin: 0 !important;
  }
}
@media only screen and (max-width: 1000px) {
  .propertiesSearchContainer {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 1564px) {
  .containerPadding p {
    margin-inline: 80px !important;
  }
}
</style>
<style lang="scss" src="@\assets\styles\common.scss"></style>



<script >
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import PropertiesHeader from "@/components/propertiesComponent/PropertiesHeader.vue";
import FeaturedCard from "@/components/homeComponents/featuredCards/FeaturedCard.vue";
import PropertiesSearchBar from "@/components/propertiesComponent/PropertiesSearchBar.vue";
import FeaturedProperties from "@/components/propertiesComponent/FeaturedProperties.vue";
import BreadCrumb from "@/components/shared/breadcrumb/BreadCrumb.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CustomHeader,
    PropertiesHeader,
    FeaturedCard,
    PropertiesSearchBar,
    FeaturedProperties,
    CustomFooter,
    BreadCrumb,
  },
  computed: {
    ...mapGetters(["cards", "appliedSearch"]),
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t("shared.home"),
          slug: `/${this.$route.params.lang}`,
        },
        {
          title: this.$t("properties.properties"),
        },
      ],
      page: 1,
      modalVisible: false,
      newWishlistModalVisible: false,
      wishlistName: "",
      sortByItems: ["Lowest Price", "Highest Price"],
      sortByValue: "Lowest Price",
      type: "list",
      itemsPerPage: 6,
      recordsCount: 0,
      displayedCards: [],
      filteredCards: [],
    };
  },
  mounted() {
    this.filteredCards = this.cards;
    this.recordsCount = this.filteredCards.length;
    this.displayedCards = this.filteredCards.filter(
      (x, index) => index < this.itemsPerPage
    );
    if (this.appliedSearch && this.appliedSearch.params) {
      this.searchHandle(this.appliedSearch.params)
    }
    this.sortElements("Lowest Price");
  },
  methods: {
    setPaginateItems(event) {
      this.page = event;
      this.displayedCards = this.filteredCards.filter(
        (x, index) =>
          index >= (event - 1) * this.itemsPerPage &&
          index < (event - 1) * this.itemsPerPage + this.itemsPerPage
      );
    },
    searchHandle(searchParams) {
      this.filteredCards = this.cards.filter(
        (card) =>
          (!searchParams["category"] ||
            (searchParams["category"] &&
              card.category == searchParams["category"])) &&
          (!searchParams["location"] ||
            (searchParams["location"] &&
              card.location == searchParams["location"])) &&
          (!searchParams["type"] ||
            (searchParams["type"] &&
              card.type.toLowerCase() == searchParams["type"].toLowerCase())) &&
          (!searchParams["floor"] ||
            (searchParams["floor"] && card.floor == searchParams["floor"])) &&
          (!searchParams["view"] ||
            (searchParams["view"] && card.view == searchParams["view"])) &&
          (!searchParams["finishing"] ||
            (searchParams["finishing"] &&
              card.finishing == searchParams["finishing"])) &&
          (!searchParams["paymentMethod"] ||
            (searchParams["paymentMethod"] &&
              card.paymentMethod == searchParams["paymentMethod"])) &&
          ((searchParams["priceRange"][0] == 0 &&
            searchParams["priceRange"][1] == 0) ||
            (card.price >= searchParams["priceRange"][0] &&
              card.price <= searchParams["priceRange"][1])) &&
          ((searchParams["bedsRange"][0] == 0 &&
            searchParams["bedsRange"][1] == 0) ||
            (card.specs[0].info.value >= searchParams["bedsRange"][0] &&
              card.specs[0].info.value <= searchParams["bedsRange"][1])) &&
          ((searchParams["bathsRange"][0] == 0 &&
            searchParams["bathsRange"][1] == 0) ||
            (card.specs[1].info.value >= searchParams["bathsRange"][0] &&
              card.specs[1].info.value <= searchParams["bathsRange"][1])) &&
          ((searchParams["areaRange"][0] == 0 &&
            searchParams["areaRange"][1] == 0) ||
            (card.specs[2].info.value >= searchParams["areaRange"][0] &&
              card.specs[2].info.value <= searchParams["areaRange"][1]))
      );
      this.recordsCount = this.filteredCards.length;
      this.setPaginateItems(1);

    },
    saveSearchHandle(searchParams) {
      this.$store.commit("saveSearchParams", searchParams);
    },
    sortElements(value) {
      if (value == "Highest Price") {
        this.displayedCards.sort((a, b) => {
          let aPrice = a.price;
          let bPrice = b.price;

          if (a.priceOffer) {
            aPrice = a.price - a.price * (a.priceOffer / 100);
          }
          if (b.priceOffer) {
            bPrice = b.price - b.price * (b.priceOffer / 100);
          }
          return aPrice < bPrice ? 1 : -1;
        });
      } else if (value == "Lowest Price") {
        this.displayedCards.sort((a, b) => {
          let aPrice = a.price;
          let bPrice = b.price;

          if (a.priceOffer) {
            aPrice = a.price - a.price * (a.priceOffer / 100);
          }
          if (b.priceOffer) {
            bPrice = b.price - b.price * (b.priceOffer / 100);
          }

          return aPrice > bPrice ? 1 : -1;
        });
      }
    },
  },
};
</script>
