
<template>
  <div class="max-w-sm mx-auto">
    <div class="text-center mb-8">
      <h4 class="text-text-dark">{{ $t("password.CreateNewPassword") }}</h4>
    </div>

    <v-form>
      <div class="ma-auto pb-0">
        <CustomTextInput
          :placeholder="$t('password.newpassword')"
          :data="formData.password"
          @data-changed="formData.password = $event"
          class="customTextFieldInput"
          :rules="[rules.required, rules.min]"
          :type="showPass ? 'text' : 'password'"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :appendIconClick="showHidePassword"
        />
      </div>
      <div class="ma-auto pb-0">
        <CustomTextInput
          :placeholder="$t('password.confirmpassword')"
          :data="formData.passwordConfirm"
          @data-changed="formData.passwordConfirm = $event"
          class="customTextFieldInput"
          :rules="[
            rules.required,
            rules.min,
            rules.confirmPassword(formData.password, formData.passwordConfirm),
          ]"
          :type="showConfirm ? 'text' : 'password'"
          :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          :appendIconClick="showHideConfirmPassword"
        />
      </div>

      <div class="ma-auto text-center pt-0">
        <CustomButton
          class="newsletter"
          classes="text-base capitalize"
          large
          block
          :click="submitHandler"
          :text="$t('password.ResetPassword')"
        />
      </div>
    </v-form>

    <div class="pa-0 mt-4 text-center">
      <div class="backToSignAncor">
        <router-link :to="`/login/${$route.params.lang}`" tag="a">{{
          $t("password.BackToSignIn")
        }}</router-link>
      </div>
    </div>
  </div>
</template>
 
<script>
import CustomButton from "../shared/Button/CustomButton.vue";
import CustomTextInput from "../shared/customTextInput/CustomTextInput.vue";
import rules from "@/services/rules";

export default {
  components: {
    CustomButton,
    CustomTextInput
  },
  data() {
    return {
      rules,
      formData: {
        password: "",
        passwordConfirm: ""
      },
      showPass: false,
      showConfirm: false
    };
  },
  methods: {
    showHidePassword() {
      this.showPass = !this.showPass;
    },
    showHideConfirmPassword() {
      this.showConfirm = !this.showConfirm;
    },
    submitHandler() {
      this.$router.push({ path: `/success/${this.$route.params.lang}` });
    }
  }
};
</script>

<style lang="scss" scoped>

.backToSignAncor {
  margin: 5px 0px;
  a {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: var(--secondary) !important;
    text-decoration: none !important;
  }
}
</style>