<template>
  <div class="customTabs">
    <v-tabs centered :value="1">
      <v-tab
        :class="activeTab == 0 ? 'activeTab' : 'inactiveTab'"
        class="rounded-tl"
      >
        <v-btn color="transparent">
          <img
            alt="Logo"
            class="shrink mr-2"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
            contain
            height="22"
            :style="{ transform: $i18n.locale == 'en' ? '' : 'scaleX(-1)' }"
            :src="require(`@/assets/img/ico/findProperty.svg`)"
          />
          <span class="rtl:tracking-normal capitalize">{{
            $t("home.findProperty")
          }}</span>
        </v-btn>
      </v-tab>
      <v-tab
        :class="activeTab == 1 ? 'activeTab' : 'inactiveTab'"
        class="rounded-tr"
        @click="handleGoMakeRequsts"
      >
        <v-btn color="transparent">
          <img
            alt="Logo"
            class="shrink"
            :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
            contain
            height="22"
            :style="{ transform: $i18n.locale == 'en' ? '' : 'scaleX(-1)' }"
            :src="require(`@/assets/img/ico/request.svg`)"
          />
          <span class="rtl:tracking-normal capitalize">{{
            $t("home.makeRequest")
          }}</span>
        </v-btn>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item></v-tab-item>
      <v-tab-item>
        <v-card flat>
          <FilterGroup
            @priceIsActive="
              (isActive) => {
                activePrice = isActive;
              }
            "
            :priceActive="activePrice"
            :priceValue="sliderValue"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <div
      dir="ltr"
      :class="$i18n.locale == 'ar' ? 'sliderContainer-ar' : 'sliderContainer'"
      v-if="activePrice"
    >
      <Slider
        :max="1000000"
        :min="0"
        :step="500"
        class="sliderTool"
        tooltipPosition="bottom"
        v-model="sliderValue"
      />
    </div>
  </div>
</template>

<script >
import FilterGroup from "@/components/homeComponents/filterGroup/FilterGroup.vue";
import Slider from "@vueform/slider/dist/slider.vue2";

export default {
  name: "CustomTabs",
  components: {
    FilterGroup,
    Slider,
  },
  data() {
    return {
      activeTab: 1,
      activePrice: false,
      sliderValue: [0, 1000000],
    };
  },
  methods: {
    handleGoMakeRequsts() {
      this.$router.push({
        path: `/request-meeting/${this.$route.params.lang}`,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.sliderTool {
  width: 80%;
  margin: auto;
  margin-top: 10%;
}
.activeTab {
  background-color: var(--primary);
  color: var(--white);
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
}
.activeTab span {
  color: white;
  font-weight: bold;
  vertical-align: middle;
}
.customTabs .v-tabs {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  overflow: hidden;
  width: 100%;
}

.inactiveTab {
  background-color: var(--white) !important;
  opacity: 1;
  color: var(--dark-primary) !important;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
}
.inactiveTab span {
  color: var(--dark-primary) !important;
  font-weight: bold;
  vertical-align: middle;
}

.v-btn {
  box-shadow: none;
}
.customTabs {
  padding-bottom: 20% !important;
}
</style>
<style>
@media only screen and (max-width: 600px) {
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
    > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
    .v-slide-group__prev {
    display: none;
  }

  .v-slide-group__content {
    width: 100%;
  }

  .v-slide-group__content {
    width: 100%;
  }
  .activeTab {
    width: 50%;
  }

  .inactiveTab {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .featuredcustomTabs .v-tabs .v-tab--active:hover:before,
  .theme--light.v-tabs .v-tab--active:before {
    opacity: 0 !important;
  }
}
</style>
