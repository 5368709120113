<template>
  <div class="profile-wrapper">
    <h4>{{ $t("profile.Expertise") }}</h4>
    <v-row>
      <EditExpertiseComponent />
      <v-col
        class="advertising-wrapper"
        cols="11"
        md="4"
        xl="3"
      >
        <!-- mobile add  -->
        <div class="md:hidden">
          <img
            width="100%"
            :src="require(`@/assets/img/ads919.png`)"
          />
          <img
            width="100%"
            :src="require(`@/assets/img/ads919.png`)"
          />
        </div>
        <!-- big screens  -->
        <div class="hidden md:block">
          <img
            width="100%"
            :src="require(`@/assets/img/ads293.png`)"
          />
          <img
            width="100%"
            :src="require(`@/assets/img/ads293.png`)"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EditExpertiseComponent from "@/components/userComponent/personalInfoSection/EditExpertise.vue";
export default {
  components: {
    EditExpertiseComponent,
  },
};
</script>

<style lang="scss" scoped>
.profile-wrapper {
  h4 {
    margin-bottom: 24px;
  }
  .advertising-wrapper {
    padding-top: 0px;
  }
}
@media only screen and (max-width: 1264px) {
  .advertising-wrapper {
    text-align: center;
    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 959px) {
  .profile-wrapper {
    h4 {
      text-align: center;
      margin-top: 24px;
    }
  }
  .advertising-wrapper {
    margin: auto;
  }
}
@media only screen and (max-width: 599px) {
  .advertising-wrapper {
    text-align: center;
    img {
      width: 100%;
    }
  }
}
</style>