<template>
  <section class="bg-white rounded">
    <div
      class="
        psearch__header
        p-4
        bg-bg-dark
        text-text-dark text-lg
        font-bold
        hidden
        md:block
      "
    >
      {{ $t("projects.AdvancedSearch") }}
    </div>
    <div class="psearch__body p-4">
      <div>
        <custom-select
          isDense
          :label="$t('projects.ProjectType')"
          :data="projectParams.prjectType"
          :items="prjectTypes"
          @data-changed="(value) => (projectParams.prjectType = value)"
          placeholder="Project"
          small
        />
      </div>
      <div>
        <custom-select
          isDense
          :label="$t('projects.projectLocation')"
          :data="projectParams.location"
          :items="locations"
          @data-changed="(value) => (projectParams.location = value)"
          placeholder="Cairo"
          small
        />
      </div>

      <div class="mb-5" dir="ltr">
        <div
          class="flex items-center justify-between text-sm text-text-light mb-3"
          :class="$i18n.locale == 'ar' ? 'flex-row-reverse' : ''"
        >
          <span>{{ $t("projects.PriceRange") }}</span>
          <span
            >{{ projectParams.priceValue[0] }} -
            {{ projectParams.priceValue[1] }} $</span
          >
        </div>
        <Slider
          :showTooltip="'drag'"
          :max="20000"
          :min="0"
          :step="1"
          class="sliderTool"
          tooltipPosition="bottom"
          v-model="projectParams.priceValue"
        />
      </div>

      <div class="mb-5" dir="ltr">
        <div
          class="flex items-center justify-between text-sm text-text-light mb-3"
          :class="$i18n.locale == 'ar' ? 'flex-row-reverse' : ''"
        >
          <span>{{ $t("projects.Area") }}</span>
          <span
            >{{ projectParams.areaValue[0] }} -
            {{ projectParams.areaValue[1] }} {{ $t("projects.Sqft") }}</span
          >
        </div>
        <Slider
          :showTooltip="'drag'"
          :max="1000"
          :min="0"
          :step="1"
          class="sliderTool"
          tooltipPosition="bottom"
          v-model="projectParams.areaValue"
        />
      </div>

      <div>
        <custom-select
          isDense
          :label="$t('projects.Finishing')"
          :items="finishings"
          :data="projectParams.finishing"
          @data-changed="(value) => (projectParams.finishing = value)"
          placeholder="Sea"
          small
        />
      </div>

      <div class="mt-5">
        <div class="flex gap-3 mb-3">
          <v-btn
            @click="resetSearch"
            elevation="0"
            class="flex-grow rounded-lg px-4 md:px-8 leading-tight normal-case"
            >{{ $t("projects.Reset") }}</v-btn
          >
          <v-btn
            depressed
            @click="$emit('search', projectParams)"
            elevation="0"
            class="flex-grow rounded-lg px-4 md:px-8 leading-tight normal-case"
            color="primary"
            >{{ $t("projects.Search") }}</v-btn
          >
        </div>
        <v-btn
          @click="handleSaveSearch"
          elevation="0"
          class="rounded-lg px-4 md:px-8 leading-tight normal-case"
          depressed
          outlined
          block
          >{{ $t("projects.SaveSearch") }}</v-btn
        >
      </div>
      <custom-modal
        @close="modalVisible = false"
        :isVisible="modalVisible"
        :modalTitle="$t('modal.saveSearch')"
        :okBtn="$t('modal.save')"
        :cancelBtn="$t('modal.cancel')"
        @confirm="emitSaveSearch"
        persistent
      >
        <div class="px-5 py-2">
          <custom-text-input
            :label="$t('modal.enterSearchName')"
            :isDense="true"
            :placeholder="$t('modal.search')"
            @data-changed="(value) => (saveSearchTitle = value)"
            :data="saveSearchTitle"
          />
        </div>
      </custom-modal>
    </div>
  </section>
</template>


<script>
import CustomSelect from "@/components/shared/customSelect/CustomSelect.vue";
import CustomModal from "@/components/shared/CustomModal.vue";
import customTextInput from "@/components/shared/customTextInput/CustomTextInput.vue";
import Slider from "@vueform/slider/dist/slider.vue2";
export default {
  components: {
    CustomSelect,
    Slider,
    CustomModal,
    customTextInput,
  },
  props: ["appliedSearch"],
  data() {
    return {
      modalVisible: false,
      saveSearchTitle: "",
      locations: ["Cairo", "Alex"],
      prjectTypes: ["Commercial", "Housing"],
      finishings: ["Sea", "Pool"],
      projectParams: {
        location: "",
        prjectType: "",
        finishing: "",
        priceValue: [6500, 15000],
        areaValue: [0, 250],
      },
    };
  },
  created() {
    this.resetSearch();
  },
  mounted() {
    if (this.appliedSearch && this.appliedSearch.params) {
      this.projectParams.location = this.appliedSearch.params.location || "";
      this.projectParams.prjectType =
        this.appliedSearch.params.prjectType || "";
      this.projectParams.finishing = this.appliedSearch.params.finishing || "";
      this.projectParams.priceValue = this.appliedSearch.params.priceValue || [
        6500, 15000,
      ];
      this.projectParams.areaValue = this.appliedSearch.params.areaValue || [
        0, 250,
      ];
      this.saveSearchTitle = this.appliedSearch.searchTitle;
    }
  },
  methods: {
    resetSearch() {
      this.projectParams.location = "";
      this.projectParams.prjectType = "";
      this.projectParams.finishing = "";
      this.projectParams.priceValue = [6500, 15000];
      this.projectParams.areaValue = [0, 250];
      this.$emit("search", this.projectParams);
    },
    handleSaveSearch() {
      this.modalVisible = true;
    },
    emitSaveSearch() {
      this.modalVisible = false;
      this.$emit("saveSearch", {
        searchTitle: this.saveSearchTitle,
        params: { ...this.projectParams },
      });
      this.saveSearchTitle = "";
    },
  },
};
</script>

<style scoped>
.sliderTool {
  margin: auto;
  --slider-connect-bg: var(--primary);
  --slider-tooltip-bg: var(--primary);
  --slider-handle-ring-color: var(--primary);
}
</style>