<template>
  <v-col
    cols="9"
    md="3"
    lg="2"
    class="tabs-wrapper"
  >
    <div class="tabs-wrapper__nav">
      <v-list
        flat
        nav
        dense
      >
        <v-list-item-group>
          <router-link :to="
              isRealtor
                ? `/realtor/profile/${this.$route.params.lang}`
                : `/user/profile/${$route.params.lang}`
            ">
            <v-list-item :class="[{ active: $route.fullPath.includes('/profile') }]">
              <v-list-item-avatar size="16.67">
                <v-img src="@/assets/profile/icons/profile-circle.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("profile.myProfile")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="isRealtor"
            :to="`/realtor/myleads/${$i18n.locale}`"
          >
            <v-list-item :class="[{ active: $route.fullPath.includes('/myleads') }]">
              <v-list-item-avatar size="16.67">
                <v-img src="@/assets/profile/icons/3User.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("profile.MyLeads")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="isRealtor"
            :to="`/realtor/myCIL/${$i18n.locale}`"
          >
            <v-list-item :class="[{ active: $route.fullPath.includes('/myCIL') }]">
              <v-list-item-avatar size="16.67">
                <v-img src="@/assets/profile/icons/PaperDownload1.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("profile.MyCILs")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="isRealtor"
            :to="`/realtor/myproperties/${$i18n.locale}`"
          >
            <v-list-item :class="[{ active: $route.fullPath.includes('/myproperties') }]">
              <v-list-item-avatar size="16.67">
                <v-img src="@/assets/profile/icons/Buildings.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("profile.MyProperties")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="isRealtor"
            :to="`/realtor/messages/${$i18n.locale}`"
          >
            <v-list-item :class="[{ 'active': $route.fullPath.includes('/messages') }]">
              <v-list-item-avatar size="16.67">
                <v-img src="@/assets/profile/icons/Message1.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("profile.Messages")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <v-expansion-panels
            v-if="!isRealtor"
            v-model="panel"
            class="wishListContainer shadow-none"
          >
            <v-expansion-panel class="shadow-none">
              <v-expansion-panel-header
                class="px-2 py-3 rounded-md"
                expand-icon="mdi-menu-down"
                :class="[
                  { expansionHeader: $route.fullPath.includes('/wishList') },
                ]"
              >
                <template v-slot:actions>
                  <img
                    :src="require('@/assets/img/ico/wishListHeart.svg')"
                    class="expansionHeartIcon ltr:mr-2 rtl:ml-2"
                  />
                </template>
                <span class="expansionHeaderText text-primary px-2">
                  {{ $t("wishlist.myWishlists") }}
                </span>
                <v-icon class="expansionIcon justify-end">
                  {{ panel != null ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <router-link
                  :to="`/user/wishList/${$i18n.locale}`"
                  v-for="(item, index) in displayedWishlist"
                  :key="item.title"
                >
                  <div
                    @click="selectWishList(index)"
                    class="grid grid-cols-12 py-2 mt-2 align-center"
                    :class="
                      $route.fullPath.includes('/wishList') &&
                      selectedWishlist == index
                        ? 'activePanelItem'
                        : ''
                    "
                  >
                    <p class="col-span-10 my-auto px-2 text-gray-500">
                      {{ item.title }}
                    </p>
                    <div
                      class="
                        flex
                        items-center
                        justify-center
                        m-auto
                        text-white
                        countClass
                        col-span-2
                        rounded-full
                      "
                      :class="
                         $route.fullPath.includes('/wishList') && selectedWishlist == index ? 'bg-primary' : 'bg-gray-500'
                      "
                    >
                      {{ item.cards.length }}
                    </div>
                  </div>
                </router-link>
                <v-btn
                  color="transparent shadow-none block w-full justify-start"
                  class="px-0 py-2 h-auto"
                  @click="$emit('addNewList')"
                >
                  <v-icon
                    color="primary"
                    class="ltr:mr-2 rtl:ml-2"
                  >mdi-plus-circle-outline</v-icon>
                  <span class="text-primary font-bold capitalize">
                    {{ $t("wishlist.addNewList") }}
                  </span>
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <router-link
            v-if="!isRealtor"
            :to="`/properties/${$i18n.locale}`"
          >
            <v-list-item :class="[{ active: $route.fullPath.includes('/properties') }]">
              <v-list-item-avatar size="16.67">
                <v-img src="@/assets/profile/icons/PaperDownload1.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("profile.ContactedUnits")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            v-if="!isRealtor"
            :to="`/user/requests/${$i18n.locale}`"
          >
            <v-list-item :class="[{ active: $route.fullPath.includes('/requests') }]">
              <v-list-item-avatar size="16.67">
                <v-img src="@/assets/profile/icons/Activity.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("profile.MyRequests")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <v-list-item @click="Logout">
            <v-list-item-avatar size="16.67">
              <v-img src="@/assets/profile/icons/logout.svg"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                  $t("profile.Logout")
                }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>

    <div class="tabs-wrapper__image mt-0">
      <div>
        <!-- mobile add  -->
        <div class="md:hidden">
          <img
            width="100%"
            :src="require(`@/assets/img/ads919.png`)"
          />
        </div>
        <!-- big screens  -->
        <div class="hidden md:block">
          <img
            width="100%"
            :src="require(`@/assets/img/ads184.png`)"
          />
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      right: null,
      panel: null,
    };
  },
  computed: {
    ...mapGetters(["wishlists", "cards", "selectedWishlist"]),
    displayedWishlist() {
      let displayedList = [...this.wishlists];
      return [
        {
          id: 0,
          title: "All Saved",
          cards: [...this.cards.filter((card) => card.isFav)],
        },
      ].concat(displayedList);
    },
    isRealtor() {
      return this.$route.path.includes("/realtor/");
    },
  },
  methods: {

    selectWishList(selectedIndex) {
      this.$store.commit('filterWishList', selectedIndex == 0 ? 0 : this.wishlists[selectedIndex - 1].id)
    },
    Logout() {
      this.$store.commit("logout");
      this.$router.push(`/${this.$route.params.lang}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs-wrapper {
  background-color: var(--white);
  border-radius: 16px;
  margin-bottom: auto;
  &__nav {
    border-radius: 16px;
    margin: auto;
    padding: 24px 0px;
    .active {
      background: var(--bg-light-01);
      box-shadow: inset 0px 0.5px 1px #ffffff,
        inset 0px -0.5px 1px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
  }
  &__image {
    text-align: center;
    margin-top: 130px;
  }
  .navigator {
    transform: none !important;
  }

  .v-list {
    .v-list-item__title {
      font-family: var(--font-en);
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 20px;
      color: var(--primary);
    }
    .v-list-item__icon {
      width: 16px;
      height: 16px;
      i {
        color: var(--primary);
      }
    }
    .v-list-item__icon:first-child {
      margin-right: 15.67px;
    }
  }
}
.activePanelItem {
  background-color: var(--bg-light-01);
  border-radius: 10px;
}
.expansionHeader {
  background-color: var(--bg-light-01);
}
.expansionHeaderText {
  order: 1;
  white-space: nowrap;
}
.expansionHeartIcon {
  order: 0;
}
.expansionIcon {
  order: 2;
}
.wishListContainer {
  background: var(--white);
  overflow: hidden;
}
.countClass {
  width: 15px;
  height: 15px;
  padding: 10px;
  font-size: 10px;
}
@media only screen and (max-width: 959px) {
  .tabs-wrapper {
    margin: auto;
    max-height: 1080px;
    &__nav {
      border-radius: 16px;
      margin: auto;
      padding: 24px 18px;
    }
    &__image {
      margin-top: 5px;
    }
  }
  .wishListContainer {
    margin-inline: 0 !important;
  }
}
@media only screen and (max-width: 599px) {
  .tabs-wrapper {
    margin: auto;
    overflow: hidden;
    &__nav {
      border-radius: 16px;
      margin: auto;
      padding: 24px 18px;
    }
    &__image {
      margin-top: 5px;
    }
  }
  .wishListContainer {
    margin-inline: 0 !important;
  }
}
</style>
<style src="@\assets\styles\expansion-panel.css">
</style>