<template>
  <div class="reqmeeting">
    <h1 class="mb-6" style="font-size: 1.5rem !important">{{$t('req_meeting.title')}}</h1>
    <div class="flex md:flex-col gap-4 md:gap-6">
      <div class="reqmeeting__steps">
        <StepsTabs :steps="steps" :activeStep="activeStep" />
      </div>

      <div class="w-full">
        <div class="reqmeeting__content">
          <div class="w-full bg-white rounded-[10px] shadow-sm p-4 md:p-5 lg:p-6">
            <ContentStep1 v-if="activeStep === 1" />
            <ContentStep2 v-if="activeStep === 2" />
            <ContentStep3 v-if="activeStep === 3" />
            <ContentStep4 v-if="activeStep === 4" />
            <ContentStep5 v-if="activeStep === 5" />
          </div>
        </div>
        <div class="reqmeeting__btns flex items-center justify-between mt-5 md:mt-6 lg:mt-8">
          <div>
            <v-btn
              v-if="activeStep !== 1"
              elevation="0"
              class="rounded-lg px-4 md:px-8 leading-tight normal-case"
              outlined
              large
              @click="goBack"
            >{{$t('req_meeting.btns.previous')}}</v-btn>
          </div>
          <div class="flex items-center gap-3 flex-grow-0">
            <v-btn
              v-if="activeStep !== steps.length"
              elevation="0"
              class="rounded-lg px-4 md:px-8 normal-case"
              large
              @click="skipStep"
            >{{$t('req_meeting.btns.skip')}}</v-btn>
            <v-btn
              v-if="activeStep !== steps.length"
              elevation="0"
              class="rounded-lg px-4 md:px-8 normal-case"
              color="primary"
              large
              @click="goNext"
            >{{$t('req_meeting.btns.next')}}</v-btn>

            <v-btn
              v-else
              elevation="0"
              class="rounded-lg px-4 md:px-8 normal-case"
              color="primary"
              large
              @click="goNext"
            >{{$t('req_meeting.btns.submit')}}</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepsTabs from "./steps/StepsTabs.vue";
import ContentStep1 from "./steps/ContentStep1.vue";
import ContentStep2 from "./steps/ContentStep2.vue";
import ContentStep3 from "./steps/ContentStep3.vue";
import ContentStep4 from "./steps/ContentStep4.vue";
import ContentStep5 from "./steps/ContentStep5.vue";

export default {
  components: {
    StepsTabs,
    ContentStep1,
    ContentStep2,
    ContentStep3,
    ContentStep4,
    ContentStep5
  },
  data() {
    return {
      activeStep: 1,
      steps: [
        {
          id: 1,
          title: this.$t("req_meeting.steps.step_1_title"),
          complete: false,
          data: {}
        },
        {
          id: 2,
          title: this.$t("req_meeting.steps.step_2_title"),
          complete: false,
          data: {}
        },
        {
          id: 3,
          title: this.$t("req_meeting.steps.step_3_title"),
          complete: false,
          data: {}
        },
        {
          id: 4,
          title: this.$t("req_meeting.steps.step_4_title"),
          complete: false,
          data: {}
        },
        {
          id: 5,
          title: this.$t("req_meeting.steps.step_5_title"),
          complete: false,
          data: {}
        }
      ]
    };
  },
  methods: {
    goNext() {
      if (this.activeStep === this.steps.length) {
        return;
      }

      this.steps = this.steps.map(step => {
        if (step.id === this.activeStep) {
          step.complete = true;
        }
        return step;
      });
      this.activeStep += 1;
    },
    skipStep() {
      if (this.activeStep === this.steps.length) {
        return;
      }
      this.activeStep += 1;
    },
    goBack() {
      if (this.activeStep === 1) {
        return;
      }
      this.activeStep -= 1;
    }
  }
};
</script>