<template>
  <div class="articlesCard">
    <v-col
      class="pa-0"
      cols="12"
      sm="12"
      xs="12"
    >
      <label>{{ $t("blogs.Search") }}</label>
      <v-text-field
        outlined
        :placeholder="$t('blogs.SearchKeywords')"
        prepend-inner-icon="mdi-magnify"
      >
        <v-icon>fas fa-search</v-icon>
      </v-text-field>
      <div class="mb-2">
        <span class="articlesCard__head">{{ $t("blogs.LatestArticles") }}</span><br />
      </div>

      <router-link
        v-for="article in this.articles"
        :key="article.articlesId"
        :to="`/blogs/${$route.params.lang}`"
      >
        <v-row class="align-center ma-0 px-0 pt-2">
          <v-col
            class="articleImg"
            cols="5"
          >
            <img
              width="100%"
              src="@/assets/img/last-article1.png"
              alt="user"
            />
          </v-col>
          <v-col
            cols="7"
            class="pa-0"
          >
            <span class="articles-title">{{ article.articleTitle }}</span>
            <div class="flex specDetails">
              <img
                alt="location"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                class="shrink"
                contain
                :src="require(`@/assets/img/ico/calendar-2.svg`)"
              />

              {{ article.date }}
            </div>
          </v-col>
        </v-row>
      </router-link>
    </v-col>
    <v-col
      class="TagsContainer"
      cols="12"
      sm="12"
      xs="12"
    >
      <div
        v-for="tag in details.tags"
        :key="tag"
        class="Tags"
      >
        {{ tag }}
      </div>
    </v-col>
  </div>
</template>

<script >

export default {
  props: ["blogDetails"],
  components: {},
  data() {
    return {
      currentIndex: 0,
      details: {},
      articles: {},
    };
  },
  mounted() {
    this.details = this.blogDetails[this.$route.params.id];
    this.articles = this.details.articles;
  },
};
</script>
<style scoped lang="scss">
.articlesCard {
  padding: 20px;
  &__head {
    font-weight: 600;
    font-size: 0.875rem;
    padding: 10px auto;
    line-height: 18px;
    color: #484b50;
    border-bottom: solid 2px #2a0166;
  }
  label {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 18px;
    color: var(--color-text);
  }
  .articles-title {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
    color: #1b2534;
  }

  .specDetails {
    font-size: small;
    color: #737680;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    padding-right: 5px;
    align-self: center;
  }
  .TagsContainer {
    display: flex;
    justify-content: center;
    .Tags {
      background: #e3f0fc;
      border-radius: 6px;
      padding: 4px 12px;
      text-align: center;
      margin-right: 10px;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 20px;
      color: var(--dark-primary);
    }
    .v-application--is-rtl .v-text-field--outlined fieldset {
      border-color: rebeccapurple !important;
    }
  }
  .row + .row {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .articleImg {
    padding-block: 0px;
  }
}
</style>