<template>
  <v-row class="Statistics-Section">
    <v-col cols="12">
      <v-card-title class="text-h5">
        <span class="Statistics-Section__title">{{
          $t("profile.statistics")
        }}</span>
      </v-card-title>
      <v-divider></v-divider>

      <v-col cols="12">
        <div class="statistics-Section-wrapper">
          <v-row>
            <v-col
              class="statistics-Section-wrapper__singleSection"
              cols="10"
              md="5"
            >
              <p>{{ $t("profile.OpenedRequests") }}</p>
              <div class="statistics-Section-wrapper__singleSection__numbers">
                <img src="@/assets/img/openRequest.png" alt="user" />
                <h4>80</h4>
              </div>
            </v-col>
            <v-col
              class="statistics-Section-wrapper__singleSection"
              cols="10"
              md="5"
            >
              <p>{{ $t("profile.ClosedRequests") }}</p>
              <div class="statistics-Section-wrapper__singleSection__numbers">
                <img
                  v-if="
                    this.$route.path === `/user/profile/${$route.params.lang}`
                  "
                  src="@/assets/img/closedRequest.png"
                  alt="user"
                />
                <img
                  v-if="
                    this.$route.path ===
                    `/realtor/profile/${$route.params.lang}`
                  "
                  src="@/assets/img/closed-request-rel.png"
                  alt="user"
                />
                <h4>72</h4>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.Statistics-Section {
  padding: 0px;
  background: var(--white);
  border: 1px solid rgba(232, 233, 234, 0.4);
  box-shadow: 0px 4px 14px rgba(233, 233, 233, 0.25);
  border-radius: 16px;
  margin-bottom: 24px;
  &__title {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 30px;
    color: var(--dark-primary);
  }
}
.statistics-Section-wrapper {
  padding: 24px 24px 32px 24px;
  &__singleSection {
    background: var(--white);
    border: 1px solid #eeeeee;
    border-radius: 8px;
    margin-right: 24px;
    width: 260px;
    display: inline-block;
    &__numbers {
      display: flex;
      align-items: center;

      h4 {
        display: inline-block;
        font-family: var(--font-en);
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 29px;
        margin-inline: 12px;
        color: var(--dark-primary);
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .statistics-Section-wrapper {
    &__singleSection {
      margin-top: 5px;
    }
  }
}
@media only screen and (max-width: 959px) {
  .statistics-Section-wrapper {
    &__singleSection {
      margin-top: 5px;
    }
  }
}
</style>