<template>
  <div class="w-full">
    <div
      class="mreq__card bg-white shadow-sm rounded-lg p-2 text-text min-w-fit"
      :class="`flex justify-between flex-col  items-stretch  gap-2  `"
    >
      <!-- // img title date status action -->
      <div :class="`flex flex-grow-0  flex-col items-stretch `">
        <img
          :class="` rounded-lg  w-full  h-auto  aspect-[3/2] object-cover  mb-3 `"
          :src="data.image"
          alt="title"
        />

        <div
          :class="`truncate max-w-[180px] xl:max-w-[245px] text-base text-text-dark  font-bold`"
        >{{data.title}}</div>
      </div>

      <div class="flex flex-grow-0 items-center gap-2">
        <img
          alt="location"
          contain
          :src="require(`@/assets/img/ico/calendar-2.svg`)"
          :class="` w-4  `"
        />
        <span :class="`truncate text-text-light text-sm `">{{data.date}}</span>
      </div>

      <div :class="`flex flex-grow-0 items-center justify-between  mt-2 `">
        <div
          :class="` text-sm   ${data.status === 'Pending' ? 'text-orange-400' : 'text-success'} ` "
        >{{$t('requests.' + data.status)}}</div>
        <div>
          <v-btn
            small
            elevation="0"
            class="font-bold capitalize"
            @click="$emit('openReqDialog' ,data.id )"
          >details</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardsType: String,
    data: Object,
    index: Number
  },
  computed: {
    isList() {
      return this.cardsType === "list";
    }
  }
};
</script>