<template>
  <AuthLayout :fullWidth="true">
    <AuthCard>
      <SuccessPassChange />
    </AuthCard>
  </AuthLayout>
</template>

<script>
import SuccessPassChange from "../../components/successPassChange/successPassChange.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import AuthCard from "@/components/auth/shared/AuthCard.vue";

export default {
  components: {
    SuccessPassChange,
    AuthLayout,
    AuthCard
  }
};
</script>