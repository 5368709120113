<template>
  <v-row class="profile-section">
    <v-col cols="12">
      <v-card-title class="text-h5">
        <span class="profile-section__title">
          {{ $t("profile.profileInfo") }}
        </span>
        <v-spacer></v-spacer>

        <router-link
          v-if="this.$route.path === `/user/profile/${$route.params.lang}`"
          :to="`/user/editProfile/${$route.params.lang}`"
        >
          <v-btn class="profile-section__icon" icon>
            <img src="@/assets/img/ico/Edit.svg" alt="Edit.svg" />
          </v-btn>
          <span class="profile-section__EditAncortitle">
            {{ $t("profile.EditProfile") }}
          </span>
        </router-link>
        <router-link v-else :to="`/realtor/editProfile/${$route.params.lang}`">
          <v-btn class="profile-section__icon" icon>
            <img src="@/assets/img/ico/Edit.svg" alt="Edit.svg" />
          </v-btn>
          <span class="profile-section__EditAncortitle">
            {{ $t("profile.edit")}}
          </span>
        </router-link>
      </v-card-title>
      <v-divider></v-divider>

      <v-row class="profile-section__information">
        <v-col cols="12">
          <v-list-item>
            <v-list-item-avatar size="100">
              <img src="@/assets/img/profileAvatar.png" alt="user" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="profile-section__information__title">
                {{  user.firstName }} {{  user.lastName }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="profile-section__information__subtitle">
                @{{ user.firstName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="12">
          <v-row class="profile-section__information__details">
            <v-col cols="12" sm="6" lg="4">
              <label>{{ $t("profile.Email") }}</label>
              <p>{{ user.email }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label>{{ $t("profile.Password") }}</label>
              <p>{{ user.password }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label>{{ $t("profile.MobileNumber") }}</label>
              <p>
                {{ user.mobile }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label>{{ $t("profile.Language") }}</label>
              <p>
                {{ user.language}}
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label>{{ $t("profile.City") }}</label>
              <p>{{  user.city  }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label>{{ $t("profile.Gender") }}</label>
              <p>
                {{  user.gender }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label>{{ $t("profile.DateOFBirthe") }}</label>
              <p>{{ user.dateOfBirth }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label>{{ $t("profile.MaritalStatue") }}</label>
              <p>
                {{ user.MaritalStatue}}
              </p>
            </v-col>
            <v-col cols="12" sm="6" lg="4">
              <label>{{ $t("profile.NumberOfChildern") }}</label>
              <p>{{ user.numberOfChilds }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["user"],
};
</script>

<style lang="scss" scoped>
.profile-section {
  padding: 0px;
  background: var(--white);
  border: 1px solid rgba(232, 233, 234, 0.4);
  box-shadow: 0px 4px 14px rgba(233, 233, 233, 0.25);
  border-radius: 16px;
  margin-bottom: 24px;

  &__title {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 30px;
    color: var(--dark-primary);
  }

  &__icon {
    .v-btn--icon.v-size--default {
      height: 17px;
      width: 18px;
      margin-inline: 9px;
    }

    .theme--light.v-btn.v-btn--icon {
      color: transparent;
    }

    .theme--light.v-btn :hover {
      color: transparent;
    }
  }

  &__EditAncortitle {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 22px;
    color: var(--primary);
  }

  &__information {
    margin-top: 12px;

    &__title {
      font-family: var(--font-en);
      font-style: normal;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 24px;
      color: var(--dark-primary);
    }

    &__subtitle {
      font-family: var(--font-en);
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 24px;
      color: var(--text-mute);
    }

    &__details {
      padding-inline: 32px;
      margin-top: 12px;
      margin-bottom: 4px;

      label {
        font-family: var(--font-en);
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 20px;
        color: var(--text-mute);
      }

      p {
        font-family: var(--font-en);
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 22px;
        color: var(--text-color-04);
      }
    }
  }

  .v-btn__content {
    width: 16px;
    height: 16px;
    margin-right: 15.67px;

    i {
      color: var(--primary);
    }
  }
}

@media only screen and (max-width: 599px) {
  .profile-section__information {
    &__details {
      text-align: center;
    }

    .v-list-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      min-height: 1px;
    }
  }
}

@media only screen and (max-width: 959px) {
  .profile-section__information {
    &__details {
      text-align: center;
    }

    .v-list-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      min-height: 1px;
    }
  }
}
</style>