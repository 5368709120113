<template>
  <div class="max-w-sm mx-auto">
    <div class="text-center mb-6">
      <h4 class="text-center">{{ $t("password.ForgotPassword") }}</h4>
      <p class="mt-4">{{ $t("password.EnterYourEmail") }}</p>
    </div>
    <v-form>
      <div class="verivication-input-wrapper">
        <CustomTextInput
          placeholder="example@gmail.com"
          class="customTextFieldInput"
          :rules="[rules.required, rules.email]"
        />
      </div>
      <CustomButton
        class="newsletter"
        classes="text-base capitalize"
        large
        block
        :click="ToEmailVerificationPage"
        :text="$t('password.SendLink')"
      />
    </v-form>
    <div class="pa-0 mt-4 text-center">
      <div class="backToSignAncor">
        <router-link :to="`/login/${$route.params.lang}`" tag="a">{{
          $t("password.BackToSignIn")
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "../shared/Button/CustomButton.vue";
import CustomTextInput from "../shared/customTextInput/CustomTextInput.vue";
import rules from "@/services/rules";

export default {
  components: {
    CustomButton,
    CustomTextInput
  },
  data() {
    return {
      rules,
      formValues: "",
      email: ""
    };
  },
  methods: {
    ToEmailVerificationPage() {
      this.$router.push({
        path: `/emailVerification/${this.$route.params.lang}`
      });
    }
  }
};
</script>

<style lang="scss" scoped>

.v-input__slot {
  min-height: 43px;
}

.backToSignAncor {
  margin: 5px 0px;
  a {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: var(--secondary) !important;
    text-decoration: none !important;
  }
}
</style>