<template>
  <div class="max-w-sm mx-auto xl:max-w-md w-full">
    <div>
      <h3 class="pt-5 signin-header-wrapper">{{ $t("password.SignUp") }}</h3>
      <p class="mt-3 signin-text-wrapper py-0">
        {{ $t("password.EnterYourDetails") }}
      </p>
    </div>

    <v-form>
      <CustomTextInput
        placeholder="Name"
        class="customTextFieldInput"
        :label="$t('password.name')"
        @data-changed="formData.name = $event"
        :value="formData.name"
        :rules="[rules.required]"
      />
      <CustomTextInput
        placeholder="Phone Number"
        class="customTextFieldInput"
        :label="$t('password.phone')"
        :value="formData.phone"
        @data-changed="formData.phone = $event"
        :rules="[rules.required]"
      />

      <CustomTextInput
        placeholder="example@gmail.com"
        class="customTextFieldInput"
        :label="$t('password.email')"
        @data-changed="formData.email = $event"
        :value="formData.email"
        :rules="[rules.required, rules.email]"
      />

      <CustomTextInput
        placeholder="password"
        :data="formData.password"
        :value="formData.password"
        @data-changed="formData.password = $event"
        class="customTextFieldInput"
        :label="$t('password.password')"
        :rules="[rules.required, rules.min]"
        :type="showPass ? 'text' : 'password'"
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        :appendIconClick="showHidePassword"
      />
      <CustomTextInput
        placeholder="Confirm password"
        :data="formData.passwordConfirm"
        :value="formData.passwordConfirm"
        @data-changed="formData.passwordConfirm = $event"
        class="customTextFieldInput"
        :label="$t('password.ConfirmPassword')"
        :rules="[
          rules.required,
          rules.min,
          rules.confirmPassword(formData.password, formData.passwordConfirm),
        ]"
        :type="showConfirm ? 'text' : 'password'"
        :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        :appendIconClick="showHideConfirmPassword"
      />
      <CustomButton
        block
        class="custom-btn"
        classes="text-base capitalize"
        large
        :click="CreateNewPartnerAccount"
        :text="$t('password.CreateAccount')"
      />

      <p class="HaveAcountMessage text-center mt-5">
        {{ $t("password.HaveAccountMessage") }}
        <router-link :to="`/login/${$route.params.lang}`" tag="a">
          {{ $t("password.SignIn") }}
        </router-link>
      </p>

      <SocialMediaRegister :subheader="$t('password.OrSignUp')" />
    </v-form>
  </div>
</template>
 


 
<script>
import SocialMediaRegister from "../shared/SocialMediaRegisterSection.vue";
import CustomTextInput from "../shared/customTextInput/CustomTextInput.vue";
import CustomButton from "../shared/Button/CustomButton.vue";
import rules from "@/services/rules";

export default {
  components: {
    CustomButton,
    CustomTextInput,
    SocialMediaRegister
  },
  data() {
    return {
      rules,
      formData: {
        name: "",
        phone: "",
        email: "",
        password: "",
        passwordConfirm: ""
      },
      showPass: false,
      showConfirm: false,
      password: "Password"
    };
  },
  methods: {
    showHidePassword() {
      this.showPass = !this.showPass;
    },
    showHideConfirmPassword() {
      this.showConfirm = !this.showConfirm;
    },
    CreateNewPartnerAccount() {
      console.log("CreateNewPartnerAccount");
    }
  }
};
</script>

<style lang="scss" scoped>
.signIn-warrper {
  background-color: var(--white);
  h3 {
    color: var(--btn-bg);
  }
  p {
    color: var(--text-mute-02);
  }
}
.signup-partener-background {
  min-height: 100vh;
  background-image: url(@/assets/login/signUp_Partner.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.signin-text-wrapper {
  font-family: var(--font-en);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;
  color: var(--text-mute-02);
}
.remmeber {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 0px;
}
.row + .row {
  margin-top: 8px;
}
.HaveAcountMessage {
  font-family: var(--font-en);
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: var(--text-mute);
}
@media only screen and (max-width: 959px) {
  .signup-partener-background {
    display: none;
  }
  .signin-header-wrapper {
    text-align: center;
  }
  .signin-text-wrapper {
    text-align: center;
  }
  .custom-btn {
    padding: 15px 0px 16px;
  }
}
</style>