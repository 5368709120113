






























import { Component, Vue } from "vue-property-decorator";
import CustomLabel from "@/components/shared/customLabel/CustomLabel.vue";

@Component({
  components: { CustomLabel },
  props: {
    label: { default: "" },
    data: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    mask: {
      type: String,
      default: "",
    },
    step: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    classes: { default: "" },
    inputId: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    prependInnerIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    appendIconClick: {
      type: Function,
      default: () => undefined,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
  },
})
export default class CustomTextInput extends Vue {
  handleChange(value: unknown) {
    this.$emit("data-changed", value);
  }

  keyup(event: unknown) {
    this.$emit("keyup", event);
  }

  handleBlur(e: any) {
    this.$emit("blur", e.target.value);
  }
}
