<template>
  <div class>
    <div
      class="filterLabel cursor-pointer"
      @click="showPriceRange"
    >
      <img
        alt="price"
        class="shrink"
        :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
        contain
        :src="require(`@/assets/img/ico/${icon}.svg`)"
      />
      <span class="text-base font-bold">{{ label }}</span>
      <v-icon>mdi-chevron-down</v-icon>
    </div>
    <div class="mt-2 text-center">{{ "$" + value[0] + "-" + "$" + value[1] }}</div>
  </div>
</template>

<script >
export default {
  components: {},
  props: ["label", "icon", "value", "activePrice"],
  name: "PriceFilter",
  data() {
    return {};
  },
  methods: {
    showPriceRange() {
      this.$emit("priceIsActive", !this.activePrice);
    }
  }
};
</script>

<style>
.sliderContainer {
  background: white;
  width: 48%;
  height: 95px;
  margin-right: -10%;
  margin-top: 1px;
  border-radius: 8px;
  float: right;
}
.sliderContainer-ar {
  background: white;
  width: 48%;
  height: 95px;
  margin-left: -10%;
  margin-top: 1px;
  border-radius: 8px;
  float: left;
}
@media only screen and (max-width: 600px) {
  .sliderContainer,
  .sliderContainer-ar {
    width: 100%;
    margin-inline: 0;
  }
}
.filterLabel {
  font-size: 15px;
  display: flex;
  font-weight: bold;
}
.filterLabel img {
  width: 10px;
}
.sliderContainer .slider-tooltip,
.sliderContainer-ar .slider-tooltip {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}
.sliderContainer .slider-connect,
.sliderContainer-ar .slider-connect {
  background: var(--primary) !important;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
