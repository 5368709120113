<template>
  <div v-if="loading" class="d-flex justify-center py-5">
    <LoadingComponent :size="fromPage ? 100 : 50" />
  </div>
  <v-row v-else>
    <v-col class="col-12">
      <v-list
        v-if="notifications.length"
        :class="['notifications__list', { notifications__scroll: !fromPage }]"
        :max-height="!fromPage ? 600 : undefined"
        :min-height="!fromPage ? 300 : undefined"
      >
        <v-list-item
          link
          v-for="notification in viewedNotifications"
          :key="notification.id"
          class="notifications__item"
          @click="openNotification(notification.link, notification.id)"
        >
          <v-list-item-avatar class="notifications__item__avatar">
            <v-badge
              top
              left
              overlap
              dot
              :offset-x="-1"
              :value="!notification.read_at"
              color="#152c5b"
            >
              <img
                :src="require(`@/assets/img/ico/bill.svg`)"
                alt="bill"
                width="40"
                height="40"
              />
            </v-badge>
          </v-list-item-avatar>
          <div>
            <p class="notifications__item__title mb-1">
              {{ notification.title }}
            </p>
            <p class="notifications__item__body mb-3">
              {{ notification.details }}
            </p>
            <img
              v-if="fromPage && notification.image"
              :src="MAIN_URL + notification.image"
              alt="notification image"
              cover
              class="notifications__item__image"
            />
            <p class="notifications__item__date mb-0">
              {{ formatDate(notification.created_at) }}
            </p>
          </div>
        </v-list-item>
      </v-list>

      <div v-else class="d-flex justify-center pt-2">
        <p>{{ $t("notifications.noNotifications") }}</p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_READ,
} from "@/store/actions.type";
import { mapActions, mapGetters } from "vuex";
import LoadingComponent from "@/components/shared/LoadingComponent.vue";
import moment from "moment";

export default {
  components: {
    LoadingComponent,
  },
  props: ["fromPage"],
  data() {
    return {
      MAIN_URL: process.env.VUE_APP_MAIN_URL,
    };
  },
  created() {
    if (this.page === 0 && !this.fromPage) {
      this.GET_NOTIFICATIONS();
    }
  },
  computed: {
    ...mapGetters("notifications", ["notifications", "loading", "page"]),
    viewedNotifications() {
      if (this.fromPage) {
        return this.notifications;
      }

      return this.notifications.slice(0, 10);
    },
  },
  methods: {
    ...mapActions("notifications", [GET_NOTIFICATIONS, MARK_NOTIFICATION_READ]),
    formatDate(value) {
      if (value) {
        const today = moment().endOf("day");
        const yesterday = moment().subtract(1, "day").endOf("day");

        if (value < today && value > yesterday)
          return "Today at " + moment(String(value)).format("h:mm a");
        if (value < yesterday)
          return "Yesterday at " + moment(String(value)).format("h:mm a");
        return moment(String(value)).format("MMM DD, YYYY h:mm a");
      }
    },
    openNotification(link, notificationId) {
      this.MARK_NOTIFICATION_READ(notificationId);
      if (link) {
        window.open(this.getLink(link), "_blank");
      }
    },
    getLink(link) {
      if (typeof link == "string" && link.length > 0) {
        if (link.startsWith("http")) {
          return link;
        } else {
          return `//${link}`;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  &__title {
    margin-bottom: 32px;
    color: var(--text-color-01);
    border-bottom: 1px solid var(--input-field-border);
    padding-bottom: 24px;
  }

  &__scroll {
    overflow-y: auto;
  }

  &__item {
    padding-top: 16px;
    padding-bottom: 16px;

    &__avatar {
      align-self: flex-start;
      margin-top: 0;
      overflow: initial;
    }

    &__title {
      font-weight: 700;
      color: var(--text-color-01);
      font-weight: 600;
      line-height: 32px;
    }

    &__body {
      font-size: 0.875rem;
      color: var(--text-color-02);
      max-width: 720px;
      line-height: 24px;
    }

    &__image {
      max-height: 300px;
      max-width: 100%;
    }

    &__date {
      font-size: 0.875rem;
      color: var(--dark-gray);
      line-height: 20px;
    }
  }
}
</style>