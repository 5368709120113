<template>
  <div class="flex flex-col items-center mt-4">
    <div class="flex items-center gap-3">
      <img
        v-if="icon != null"
        alt="location"
        contain
        :src="require(`@/assets/img/ico/${icon}.svg`)"
      />
      <span class="font-bold">{{label}}</span>
    </div>
    <div>
      <v-combobox
        class="custom__dropdown location__dropdown"
        :placeholder="$t('home.searchLocation')"
        :items="options"
        transition="none"
        flat
        dense
        solo
        :value="data"
        @keyup="keyup"
        @blur="handleBlur"
        @input="handleChange"
        append-icon=" "
      >
        <template v-slot:prepend-item>
          <div class="p-3 text-xs border-solid border-0 border-b border-gray-200">{{$t('home.RecentlySearchLocations')}}</div>
        </template>

        <template v-slot:item="{ item  }">
          <span class="text-sm">{{ item }}</span>
        </template>
      </v-combobox>
    </div>
  </div>
</template>

<script >
export default {
  name: "CustomFilter",
  props: {
    data: String,
    label: String,
    icon: String,
    options: []
  },
  emits: ["update:value"],
  data() {
    return {};
  },
  methods: {
    handleChange(value) {
      this.$emit("data-changed", value);
    },
    keyup(event) {
      this.$emit("keyup", event);
    },

    handleBlur(e) {
      this.$emit("blur", e.target.value);
    }
  }
};
</script>

<style scoped lang="scss">
.custom__dropdown {
  max-width: 150px;
}
</style>


<style  lang="scss" >
.location__dropdown {
  .v-input__append-inner {
    display: none;
  }
  input {
    text-align: center;
  }
  input::placeholder {
    font-size: 0.9rem !important;
  }
}
</style>
