<template>
  <div>
    <h2
      class="text-lg"
      style="font-size: 1.125rem !important;"
    >{{$t('req_meeting.EnterPriceRange')}}</h2>
    <div class="w-full" dir="ltr">
      <div class="mt-8 md:mt-10 flex items-center justify-between text-text-light text-sm">
        <span>{{$t('req_meeting.PriceRange')}}</span>
        <span>6,500 - 15,000 $</span>
      </div>
      <div class="mt-4 mb-12 md:mb-16">
        <Slider
          :showTooltip="'always'"
          :max="40000"
          :min="0"
          :step="100"
          class="sliderTool"
          tooltipPosition="bottom"
          :format="(v) => '$ ' +  v "
          v-model="priceValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@vueform/slider/dist/slider.vue2";
export default {
  components: { Slider },
  data() {
    return {
      priceValue: [6500, 15000]
    };
  }
};
</script>