<template>
  <div class="bg-white text-text overflow-y-auto shadow-2xl rounded-lg relative">
    <div class="flex items-center justify-between border-0 border-b border-gray-200 border-solid mb-4 py-5 lg:py-6 mx-5 lg:mx-6 sticky top-0 bg-white z-10">
      <h6>{{$t('meetingDialog.dialogTitle')}}</h6>
      <span
        class="cursor-pointer hover:scale-110 transform text-text-light hover:text-text-dark"
        @click="$emit('closeDialog')"
      >
        <PhX weight="bold" />
      </span>
    </div>

    <div class="grid grid-col-1 md:grid-cols-3 lg:grid-cols-7 gap-4 md:gap-5 grid-flow-row-dense p-5 lg:p-6">
      <!-- ---------------- form ---------------- -->
      <div class="col-span-1 md:col-span-2 lg:col-start-3 lg:col-end-6">
        <v-form>
          <div>
            <CustomTextInput
              placeholder="Name"
              class="customTextFieldInput"
              :label="$t('meetingDialog.Name')"
              :data="formData.name"
              :rules="[rules.required ]"
            />
          </div>
          <div>
            <CustomTextInput
              class="customTextFieldInput"
              :placeholder="$t('meetingDialog.MobileNumber')"
              :label="$t('meetingDialog.MobileNumber')"
              :data="formData.phone"
              :rules="[rules.required]"
            />
          </div>
          <div>
            <CustomTextInput
              placeholder="example@mail.com"
              class="customTextFieldInput"
              :label="$t('meetingDialog.EmailAddress')"
              :data="formData.email"
              :rules="[rules.required, rules.email]"
            />
          </div>

          <div class="flex flex-col md:flex-row items-start gap-4">
            <CustomSelect
              :placeholder="$t('meetingDialog.Project')"
              :label="$t('meetingDialog.Project')"
              class="flex-grow w-full"
              :data="formData.project"
              :items="items"
            />
            <CustomSelect
              :placeholder="$t('meetingDialog.ProjectType')"
              :label="$t('meetingDialog.ProjectType')"
              class="flex-grow w-full"
              :data="formData.projectType"
              :items="items"
            />
          </div>

          <div>
            <CustomSelect
              :placeholder="$t('meetingDialog.Boker')"
              :label="$t('meetingDialog.Boker')"
              class="flex-grow w-full"
              :data="formData.boker"
              :items="items"
            />
          </div>

          <div class="flex flex-col md:flex-row items-start gap-4">
            <CustomDatePicker
              :placeholder="$t('meetingDialog.CallTime')"
              :label="$t('meetingDialog.CallTime')"
              class="flex-grow w-full"
              :data="formData.callTime"
              :items="items"
            />
            <CustomSelect
              :placeholder="$t('meetingDialog.Communication')"
              :label="$t('meetingDialog.Communication')"
              class="flex-grow w-full"
              :data="formData.communication"
              :items="items"
            />
          </div>
          <div>
            <CustomRadioButton
              :label="$t('meetingDialog.PaymentMethod')"
              :options="paymentOptions"
              :data="formData.paymentMethod"
              groupClass="border-0"
            />
          </div>

          <div>
            <CustomTextarea
              :placeholder="$t('meetingDialog.WriteComment') + '...'"
              class="customTextFieldInput"
              :label="$t('meetingDialog.Comment')"
              :data="formData.comment"
            />
          </div>

          <div>
            <v-btn
              large
              block
              elevation="0"
              class="rounded-lg px-4 md:px-8 leading-tight normal-case"
              color="primary"
            >{{$t('meetingDialog.RegisterNow')}}</v-btn>
          </div>
        </v-form>
      </div>
      <!-- ---------------- Ads ---------------- -->
      <div class="md:col-span-1 lg:col-start-1 lg:col-end-3">
        <!-- mobile adv  -->
        <div class="md:hidden">
          <img
            width="100%"
            :src="require(`@/assets/img/ads919.png`)"
          />
        </div>
        <!-- > md screens  -->
        <div class="hidden md:block">
          <img
            width="100%"
            :src="require(`@/assets/img/adv-260-720.png`)"
          />
        </div>
      </div>
      <!-- ---------------- cards ----------------  -->
      <div class="md:col-span-3 lg:col-start-6 lg:col-end-8">
        <h6 class="mb-3">{{$t('meetingDialog.SimilarUnitsIn')}} New Cairo</h6>
        <div class="w-full gap-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-1">
          <div
            v-for="(card , ix) in cards.slice(0 ,3)"
            :key="card.id"
            class="mb-10"
            :class="`${ix > 1 && 'lg:hidden' }`"
          >
            <featured-card
              :imgmdCols="12"
              :detailsmdCols="12"
              :card="card"
              titleStyle="FeaturedTitleStyle"
              contentStyle="contentStyle"
              favIconStyle="favIconStyle"
              specContainer="specContainer"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhX } from "phosphor-vue";
import FeaturedCard from "@/components/homeComponents/featuredCards/FeaturedCard.vue";
import CustomTextInput from "../shared/customTextInput/CustomTextInput.vue";
import CustomSelect from "../shared/customSelect/CustomSelect.vue";
import CustomDatePicker from "../shared/customDatePicker/customDatePicker.vue";
import CustomRadioButton from "../shared/CustomRadioButton.vue";
import CustomTextarea from "../shared/customTextarea/CustomTextarea.vue";
import { mapGetters } from "vuex";

import rules from "@/services/rules";
export default {
  components: {
    PhX,
    FeaturedCard,
    CustomTextInput,
    CustomSelect,
    CustomDatePicker,
    CustomRadioButton,
    CustomTextarea
  },
  computed: {
    ...mapGetters(["cards"]),
  },
  data() {
    return {
      rules,
      formData: {
        name: "",
        phone: "",
        email: "",
        project: "",
        projectType: "",
        boker: "",
        callTime: "",
        communication: "",
        paymentMethod: "",
        comment: ""
      },
      paymentOptions: [
        {
          text: this.$t("meetingDialog.Cash"),
          value: "cash"
        },
        {
          text: this.$t("meetingDialog.Installments"),
          value: "installments"
        }
      ],
      items: ["item 1", "item 2", "item 3"],
    };
  }
};
</script>

