<template>
  <AuthLayout :fullWidth="true">
    <AuthCard>
      <EmailVerification />
    </AuthCard>
  </AuthLayout>
</template>

<script>
import EmailVerification from "@/components/emailVerification/emailVerificationComponent.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import AuthCard from "@/components/auth/shared/AuthCard.vue";

export default {
  components: {
    EmailVerification,
    AuthLayout,
    AuthCard
  }
};
</script>

