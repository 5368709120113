
























import { Component, Vue } from "vue-property-decorator";
import CustomLabel from "@/components/shared/customLabel/CustomLabel.vue";

@Component({
  components: { CustomLabel },
  props: {
    label: { default: "" },
    data: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    classes: { default: "" },
    rows: {
      type: Number,
      default: 7,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    appendIcon: {
      type: String,
      default: null,
    },
    appendIconClick: {
      type: Function,
      default: () => undefined,
    },
    isMessages: {
      type: Boolean,
      default: false,
    },
    messagesSelectImages: {
      type: Function,
      default: () => undefined,
    },
    messagesSelectFile: {
      type: Function,
      default: () => undefined,
    },
  },
})
export default class CustomTextarea extends Vue {
  handleChange(value: unknown) {
    this.$emit("data-changed", value);
  }
}
