<template>
  <div class="DetailsCard">
    <v-row no-gutters>
      <v-col cols="12" md="6" sm="12" xs="12">
        <img
          alt="img"
          class="shrink imgCard"
          :class="imageClass"
          contain
          :src="require(`@/assets/img/${post.cardImage}.png`)"
        />
      </v-col>
      <v-col cols="12" md="6" sm="12" xs="12">
        <div class="details" :class="contentClass">
          <h2>{{ $t("home." + post.title) }}</h2>
          <p>
            {{ $t("home." + post.description) }}
          </p>
          <router-link :to="`/contact/${$route.params.lang}`" tag="a">
            <v-btn color="primary" class="py-5 px-3">
              {{ $t("home." + post.buttonTitle) }}
            </v-btn>
          </router-link>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script >
export default {
  name: "DetailsCard",
  components: {},
  props: ["post", "imageClass", "contentClass"],
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.DetailsCard {
  margin: 50px 150px;
}
.imgCard {
  width: 85%;
}
.marginStyle {
  margin-top: 20%;
}
.shadowStyle {
  box-shadow: 20px -20px lightgray;
  margin-top: 20px;
}
.details h2 {
  font-weight: bold;
  color: var(--primary);
}
.details p {
  width: 85%;
  font-size: 16px;
  font-weight: normal;
  color: gray;
  white-space: pre-line;
  line-height: 28px;
}

@media only screen and (max-width: 600px) {
  .DetailsCard {
    margin: 50px 30px;
  }
  .imgCard {
    width: 100%;
  }
  .details h3 {
    font-size: 26px !important;
  }
  .shadowStyle {
    box-shadow: 10px -10px lightgray;
    margin-top: 20px;
  }
}
</style>
