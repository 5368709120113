<template>
  <v-row class="mt-3">
    <v-col
      cols="12"
      md="9"
      class
      :class="$i18n.locale == 'ar' ? '' : ''"
    >
      <unit-card
        :imgmdCols="12"
        :detailsmdCols="12"
        :card="sliderCard"
        contentStyle="contentStyle"
        premiumStyle="premiumStyle"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
      class="text-center"
    >
      <div class="buttonsContainer">
        <v-btn
          class="mb-5 search"
          width="100%"
          @click="
            () =>
              this.$router.push({
                path: `/projects/${this.$route.params.lang}`,
              })
          "
        >
          {{ $t("properties.advancedSearch") }}
          <v-icon>{{
            $i18n.locale == "en" ? "mdi-chevron-right" : "mdi-chevron-left"
          }}</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          width="100%"
          @click="openReqMeetingDialog"
        >
          {{ $t("unit.registerInterest") }}</v-btn>
      </div>
      <!-- mobile add  -->
      <div class="md:hidden mt-7">
        <img
          width="100%"
          :src="require(`@/assets/img/ads919.png`)"
        />
      </div>
      <!-- big screens  -->
      <div class="hidden md:block mt-7">
        <img
          width="100%"
          :src="require(`@/assets/img/ads293.png`)"
        />
      </div>
      <v-row class="mt-2">
        <v-col
          class="project-more-details text-start"
          cols="11"
        >
          <h6 class="my-2">{{ $t("projects.PaymentPlans") }}</h6>
          <v-divider></v-divider>
          <div class="my-4">
            <p class="mb-2 my-4">{{ $t("projects.plan1") }}</p>
            <div class="flex">
              <span
                class="align-self-center"
                :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
              >{{ $t("projects.downpayment") }}:</span>
              <h6 class="d-inline">10%</h6>
            </div>
            <div class="flex">
              <span
                class="align-self-center"
                :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
              >{{ $t("projects.installmentYears") }}:</span>
              <h6 class="d-inline">10 {{ $t("projects.Years") }}</h6>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="my-4">
            <p class="mb-2 my-4">{{ $t("projects.plan2") }}</p>
            <div class="flex mt-4">
              <span
                class="align-self-center"
                :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
              >
                {{ $t("projects.downpayment") }}:
              </span>
              <h6 class="d-inline">10%</h6>
            </div>
            <div class="flex">
              <span
                class="align-self-center"
                :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
              >{{ $t("projects.installmentYears") }}:</span>
              <h6 class="d-inline">10 {{ $t("projects.Years") }}</h6>
            </div>
          </div>
          <div class="saveSearch">
            <v-btn
              @click="showProjectDetails"
              depressed
              outlined
              block
              class
              color="primary"
            >{{ $t("projects.viewMore") }}</v-btn>
          </div>
          <v-dialog
            v-model="reqDialog"
            width="325px"
            max-width="1000"
          >
            <PlansDetails
              @closeDialog="closeProjectDetails"
              :data="sliderCard"
            />
          </v-dialog>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-model="reqMeetingDialog"
      width="80%"
      max-width="1000"
      persistent
    >
      <ReqMeetingDialog @closeDialog="closeReqMeetingDialog" />
    </v-dialog>
  </v-row>
</template>

<script >
import UnitCard from "@/components/unitComponents/UnitCard.vue";
import ReqMeetingDialog from "@/components/unitComponents/ReqMeetingDialog.vue";
import PlansDetails from "@/components/projects/projectDetails/PlansDetails.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    UnitCard,
    ReqMeetingDialog,
    PlansDetails,
  },
  props: ["sliderCard"],
  computed: {
    ...mapGetters(["projects"]),
  },
  data() {
    return {
      reqDialog: false,
      reqMeetingDialog: false,
    };
  },
  methods: {
    closeReqMeetingDialog() {
      this.reqMeetingDialog = false;
    },
    openReqMeetingDialog() {
      this.reqMeetingDialog = true;
    },
    showProjectDetails() {
      this.reqDialog = true;
    },
    closeProjectDetails() {
      this.reqDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.buttonsContainer {
  width: 100;
}

.project-more-details {
  background: #ffffff;
  border: 1px solid rgba(232, 233, 234, 0.4);
  box-shadow: 0px 4px 14px rgba(233, 233, 233, 0.25);
  border-radius: 16px;
}

.buttonsContainer .search {
  background: var(--bar-header-bg);
  text-transform: capitalize;
}

.buttonsContainer .v-btn {
  box-shadow: none;
}
</style>
<style lang="scss" src="@\assets\styles\common.scss">
</style>