<template>
  <div class="EventCard">
      <v-row no-gutters>
          <v-col cols="3" class="m-0" style="dateContainer">
            <div 
              class="text-center dateCard"
              v-if="event.eventDate"
              :style="{background: event.eventColor != null ? event.eventColor : 'white'}">
              <div class="py-3 eventDate" :style="{color: event.textColor != null ? event.textColor : 'black'}">
                <h5 class="text-center">
                    {{event.eventDate.day}}
                </h5>
                <span>{{event.eventDate.month}}</span>
              </div>
            </div>
          </v-col>
           <v-col class="p-0 m-0 align-self-center">
               <div class="details">
                    <h3>{{event.title}}</h3>
                    <p>
                        {{event.description}}
                    </p>
                    <v-btn v-if="event.buttonTitle != null" color="primary" class="py-5 px-3">
                        {{event.buttonTitle}}
                    </v-btn>
               </div>
          </v-col>
      </v-row>
  </div>
</template>

<script >

export default {
  name:'EventCard',
  components:{
  },
  props:['event'],
  data() {
    return {
    }
  }

}
</script>

<style scoped lang="scss">
    .EventCard {
       background: white;
       border-radius: 15px;
       margin-bottom: 20px;
    }
    .shadowStyle {
        box-shadow: 20px -20px lightgray;
        margin-top: 20px;
    }
    .details h3 {
        font-weight: bold;
        color: var(--primary);
    }
    .details p {
        font-size: 16px;
        font-weight: normal;
        color: gray;
        line-height: 28px;
        margin: 10px;
    }
    .dateCard {
        text-align: center;
        margin: auto;
        align-self: center;
        margin: 10px;
        border-radius: 10px;
        height: calc(100% - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .EventCard .row {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
    .eventDate span{
        font-weight: normal;
        font-size: small;
    }
    .dateContainer {
        align-self: center;
        height: 100%;
    }
    @media only screen and (max-width: 600px) { 
        .EventCard {
            margin-top: 20px;
        }
    }
 
</style>
