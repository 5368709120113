<template>
  <div class="projectDetailsContainer">
    <CustomHeader headerColor="primary" />
    <v-container>
      <div class="mt-14 md:mt-16"></div>
      <section class="container md:pt-5">
        <BreadCrumb :breadcrumbs="breadcrumbs" />
      </section>
      <projectDetailContent :sliderCard="sliderCard" />
      <MoreProjectsSlider />
    </v-container>
    <CustomFooter />
  </div>
</template>

<script >
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import BreadCrumb from "@/components/shared/breadcrumb/BreadCrumb.vue";
import projectDetailContent from "@/components/projects/projectDetails/projectDetailContent.vue";
import MoreProjectsSlider from "@/components/projects/projectDetails/MoreProjectsSlider.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    CustomHeader,
    CustomFooter,
    BreadCrumb,
    projectDetailContent,
    MoreProjectsSlider
  },
  computed: {
    ...mapGetters(["projects"]),

    sliderCard() {
      let tempCard = this.projects.find(item => item.id == this.$route.params.project);
      return tempCard
    }

  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t("shared.home"),
          slug: `/${this.$route.params.lang}`
        },
        {
          title: this.$t("shared.projects"),
          slug: `/projects/${this.$route.params.lang}`
        },
        {
          title: this.$t("projects.projectDetails")
        }
      ],
    };
  },
  methods: {

  },
};
</script>

<style lang="scss">
.projectDetailsContainer {
  background: var(--light-gray-bg-01);
}
</style>