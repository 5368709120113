<template>
  <div class="messagesContainer">
    <v-container>
      <h5 class="text-primary w-2/4 mb-4">{{ $t("messages.myMessages") }}</h5>
      <v-row>
        <v-col cols="12">
          <Messages />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Messages from "@/components/messagesComponents/messages.vue";

export default {
  components: {
    Messages,
  },
};
</script>