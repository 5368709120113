<template>
  <section class="bg-white rounded-xl md:rounded-2xl shadow-sm p-6 md:p-7 text-text-light">
    <h1
      class="text-2xl md:text-[1.75rem] mb-4 text-text-dark"
      style="font-size: 1.75rem !important"
    >Renovato Apartment</h1>
    <div class="p_location text-lg mb-5">
      <span>icon</span>
      <span>New Cairo, Egypt</span>
    </div>
    <div class="p_ametites flex flex-wrap items-center gap-4 justify-start">
      <div
        v-for="(spec,index) in  specs"
        :key="spec+index"
        class="flex items-center gap-1 flex-grow-0"
      >
        <img alt="spec" contain :src="require(`@/assets/img/ico/${spec.icon}.svg`)" class="w-5" />
        <span>{{spec.info.value}} {{spec.info.key}}</span>
      </div>
    </div>

    <article class="my-8">
      <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla aperiam atque possimus! Deserunt totam accusamus animi nobis architecto laudantium officiis, unde ea id esse culpa est repellat eius illo quaerat hic error! Praesentium, rerum. Earum, minima nisi. Magnam nihil voluptate optio vero sequi dolor tempora ullam fugit, tenetur, ipsa repellat eius ut laborum ipsum facere modi voluptatum delectus at. Amet voluptates, voluptas molestiae accusamus doloremque consequatur dolorum itaque cumque beatae consequuntur nesciunt aliquam, unde commodi quidem ab inventore a? Libero voluptatem distinctio optio eius repellat nemo ipsum, minus ullam nihil architecto suscipit explicabo officia numquam a sunt, aliquid odio cupiditate. Eaque explicabo sequi soluta porro beatae unde earum error ipsa saepe voluptates, itaque fuga officia perferendis ea magni exercitationem. Omnis nesciunt consequuntur voluptatem nemo in at vel neque magni excepturi, laudantium et vero aut, itaque saepe distinctio aliquam ut, eveniet dolore accusantium totam commodi praesentium. Porro illum aperiam fugiat deleniti!</p>
    </article>

    <div class="flex md:justify-between md:items-end">
      <div class="p_info">
        <p>
          <strong class="font-bold text-text-dark">Category :</strong> Commercial
        </p>
        <p>
          <strong class="font-bold text-text-dark">View :</strong> Pool
        </p>
        <p>
          <strong class="font-bold text-text-dark">Floor :</strong> Ground Floor
        </p>
      </div>
      <div class="p_price flex items-end gap-3 flex-grow-0">
        <strong class="text-2xl font-bold text-text-dark">$10,000</strong>
        <small class="text-secondary text-base">25% off</small>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      specs: [
        { icon: "bed", info: { value: "4", key: "beds" } },
        { icon: "bath", info: { value: "2", key: "baths" } },
        { icon: "squareft", info: { value: "2500", key: "sqft" } },
        { icon: "wifi", info: { value: "", key: "Wifi" } }
      ]
    };
  }
};
</script>