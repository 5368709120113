



























import { Component, Vue } from "vue-property-decorator";
import CustomLabel from "@/components/shared/customLabel/CustomLabel.vue";

@Component({
  components: { CustomLabel },
  props: {
    label: { default: "" },
    labelClass: { default: "" },
    groupClass: { default: "" },
    options: {
      type: Array,
      default: () => [],
    },
    data: {
      type: [String, Boolean],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Boolean,
      default: false,
    },
  },
})
export default class customRadio extends Vue {
  handleChange(value: string | number | boolean) {
    this.$emit("data-changed", value);
  }
}
