<template>
  <div>
    <CustomHeader
      :auth="auth"
      headerColor="primary"
    />
    <div class="relatorLayoutContainer">
      <v-container class="relatorLayout">
        <v-row>
          <CustomSidebar
            :itemPanelSelected="-1"
            @addNewList="modalVisible=true"
          />
          <v-col
            :class="[
              'relator-wrapper-info',
              { ar: $i18n.locale == 'ar' },
              { en: $i18n.locale != 'ar' },
            ]"
            cols="10"
            md="8"
            lg="9"
          >
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <CustomFooter />
    <custom-modal
      @close="modalVisible = false"
      :isVisible="modalVisible"
      :modalTitle="$t('modal.addWishList')"
      :okBtn="$t('modal.save')"
      :cancelBtn="$t('modal.cancel')"
    >
      <div class="px-5 py-2">
        <custom-text-input
          :label="$t('modal.enterNewName')"
          :placeholder="$t('modal.newWishList')"
          :isDense="true"
        />
      </div>
    </custom-modal>
  </div>
</template>

<script>
import CustomSidebar from "@/components/userComponent/CustomSidebar/customSidbar.vue";
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import CustomModal from "@/components/shared/CustomModal.vue";
import customTextInput from "@/components/shared/customTextInput/CustomTextInput.vue";

export default {
  components: {
    CustomHeader,
    CustomFooter,
    CustomSidebar,
    customTextInput,
    CustomModal,
  },
  data() {
    return {
      auth: true,
      modalVisible: false
    }
  }
};
</script>

<style lang="scss" scoped>
.relatorLayoutContainer {
  background-color: var(--bg-light-01);
  padding-top: 100px;
}

.relator-wrapper-info {
  padding-top: 0px;
  &.ar {
    margin-right: 32px;
  }
  &.en {
    margin-left: 32px;
  }
}
@media only screen and (max-width: 1264px) {
  .relator-wrapper-info {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 959px) {
  .relatorLayoutContainer {
    margin: auto;
  }
  .relator-wrapper-info {
    margin: auto !important;
  }
}
</style>