<template>
  <div class="featuredPropertyContainer">
    <h3 class="my-3 my-md-5 my-sm-3">
      {{ $t("properties.FeaturedPropertiesInLocation") }}
    </h3>
    <featured-tabs
      @input="(value) => (activeTab = value)"
      :activeTab="activeTab"
    />
    <v-row no-gutters class="justify-start mt-md-5 mt-sm-3 mb-md-5 mb-sm-3">
      <v-col
        cols="12"
        md="4"
        sm="12"
        xs="12"
        v-for="card in featuredCards"
        :key="card.id"
        class="mt-md-2 pr-2 pl-2 mt-sm-2 mb-md-5 mb-sm-5 mt-xs-5 mb-xs-10"
      >
        <featured-card
          :imgmdCols="12"
          :detailsmdCols="12"
          :card="card"
          titleStyle="FeaturedTitleStyle"
          favIconStyle="favIconStyle"
          specContainer="specContainer"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script >
import FeaturedTabs from "@/components/propertiesComponent/FeaturedTabs.vue";
import FeaturedCard from "../homeComponents/featuredCards/FeaturedCard.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    FeaturedTabs,
    FeaturedCard,
  },
  data() {
    return {
      activeTab: 0,
      unitServices: ["Residential", "Commercial", "Administrative"],
    };
  },
  methods: {},
  computed: {
    ...mapGetters(["cards"]),
    featuredCards() {
      let items = this.cards
        .filter((card) => card.unitService == this.unitServices[this.activeTab])
        .map((item) => {
          let copyedItem = { ...item };
          copyedItem.priceOffer = null;
          copyedItem.buttonTitle = null;
          return copyedItem;
        });
      return items.filter((item, index) => index < 6);
    },
  },
};
</script>
<style scoped>
.featuredPropertyContainer h3 {
  font-weight: 700;
}
</style>