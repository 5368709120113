import { render, staticRenderFns } from "./FeaturedCard.vue?vue&type=template&id=4f9fad83&scoped=true&"
import script from "./FeaturedCard.vue?vue&type=script&lang=js&"
export * from "./FeaturedCard.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedCard.vue?vue&type=style&index=0&id=4f9fad83&scoped=true&lang=scss&"
import style1 from "@/assets/styles/carousel.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f9fad83",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCarousel,VCarouselItem,VCol,VDivider,VIcon,VRadio,VRadioGroup,VRow})
