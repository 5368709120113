


































import { Component, Vue } from "vue-property-decorator";
import CustomLabel from '@/components/shared/customLabel/CustomLabel.vue'
@Component({
  components: { CustomLabel },
  props: {
    label: { default: "" },
    date: {
      type: String,
      default: new Date()
    },
    minDate: {
      type: String,
      default: ""
    },
  },
})
export default class customDatePicker extends Vue {

  datePickerMenu = false;

  x = (new Date(new Date(new Date().setDate(new Date().getDate() + 1)).getTime() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

  handleChange (value: any) {
    this.datePickerMenu = false;

    const newValue = (new Date(new Date(value).getTime() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);

    if (value) {
      this.$emit('data-changed', newValue);
    }
  }
}
