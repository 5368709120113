<template>
  <div
    class="featuredCard"
    :dir="$i18n.locale=='ar'?'rtl':'ltr'"
  >
    <v-row>
      <v-col
        cols="12"
        :md="imgmdCols"
        sm="12"
        xs="12"
        class="text-center"
      >
        <v-carousel
          id="FeaturedCarousel"
          height="200px"
          style="direction: ltr"
          :loop="true"
          :hide-delimiters="
            card.cardImage == null || card.cardImage.length <= 1 ? true : false
          "
          :show-arrows="card.cardImage != null && card.cardImage.length > 1"
        >
          <v-carousel-item
            id="FeaturedSlide"
            v-for="image in card.cardImage"
            :key="card.id + image"
            class="text-center"
          >
            <img
              alt="img"
              style="height: 100% !important"
              class="w-full fill-height cardImgSlide"
              :src="require(`@/assets/img/${image}`)"
            />
          </v-carousel-item>
          <v-btn
            color="secondary"
            class="absolute top-3 ltr:left-3 rtl:right-3 py-3 px-3 text-sm"
            v-if="card.isPremium"
            small
          >
            {{ $t("home.premium") }}
          </v-btn>
        </v-carousel>
      </v-col>
      <v-col
        cols="12"
        :md="detailsmdCols"
        sm="12"
        xs="12"
      >
        <div class="details pb-[30px] rtl:text-right ltr:text-left">
          <div
            :class="$i18n.locale == 'ar' ? 'priceTagAR' : 'priceTag'"
            :style="{
              top: imgmdCols == 12 ? '17% !important' : '35% !important',
            }"
            v-if="card.priceOffer != null"
          >
            <img
              alt="img"
              width="68"
              class="fill-height"
              contain
              :src="require(`@/assets/img/priceShape.png`)"
            />
            <span>{{ card.priceOffer }}% off</span>
          </div>
          <div
            class="fav ltr:text-right rtl:text-left"
            :class="[favIconStyle]"
          >
            <v-icon
              @click="setFavorite({ id: card.id, isFav: !card.isFav })"
              class="mr-2"
              :color="card.isFav ? 'red' : 'primary'"
            >{{ card.isFav ? "mdi-heart" : "mdi-heart-outline" }}
            </v-icon>
          </div>
          <router-link :to="`/projects/${card.id}/${$route.params.lang}`">
            <p
              class="my-2"
              :class="[titleStyle, 'mb-0']"
            >
              {{ $i18n.locale == "ar" ? card.title.ar : card.title.en }}
            </p>
          </router-link>
          <div>
            <div class="flex specDetails">
              <img
                alt="location"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                class="shrink"
                contain
                :src="require(`@/assets/img/ico/locationGray.svg`)"
              />
              {{ card.location }}
            </div>
          </div>
          <div
            class="flex mt-5 mb-5"
            :class="specContainer"
          >
            <div
              v-for="(spec, index) in card.specs"
              :key="spec + card.id + '' + index"
              class="specDetails px-2"
            >
              <img
                alt="spec"
                class="shrink rtl:ml-1 ltr:mr-1"
                contain
                :src="require(`@/assets/img/ico/${spec.icon}.svg`)"
              />
              {{ spec.info.value }} {{ $t("home." + spec.info.key) }}
            </div>
          </div>
          <div class="
              flex
              priceDetails
              absolute
              bottom-0
              ltr:left-0
              rtl:right-0
              py-2
            ">
            <h5 :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'">
              ${{
                card.priceOffer
                  ? card.price - card.price * (card.priceOffer / 100)
                  : card.price
              }}
            </h5>
            <h6 v-if="card.priceOffer">${{ card.price }}</h6>
          </div>
          <div
            :class="[
              'text-right w-full absolute ltr:right-0 rtl:left-0 py-2',
              imgmdCols == 12 ? 'button-bottom' : 'bottom-0',
            ]"
            v-if="card.buttonTitle != null"
          >
            <v-btn
              :to="'/unit/' + card.id + '/' + $i18n.locale"
              color="primary"
              small
              class="py-2 px-2 mr-2 ml-2"
              :class="$i18n.locale == 'ar' ? 'float-left' : 'float-right'"
            >
              {{ $t("home." + card.buttonTitle) }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- new wishlist -->
    <custom-modal
      @close="newWishlistModalVisible = false"
      @confirm="addToWishList"
      :isVisible="newWishlistModalVisible"
      :modalTitle="$t('modal.addWishList')"
      :okBtn="$t('modal.save')"
      :cancelBtn="$t('modal.cancel')"
    >
      <div class="px-5">
        <custom-text-input
          class="mb-5"
          :label="$t('modal.enterNewName')"
          :placeholder="$t('modal.newWishList')"
          :isDense="true"
          @data-changed="(value) => (wishlistName = value)"
        />

        <v-divider></v-divider>
        <span class="dividerSpan text-gray-400">{{ $t("modal.or") }}</span>

        <custom-label
          class="text-sm text-gray-500 mt-3"
          :value="$t('modal.selectFromList')"
        >{{ $t("modal.selectFromList") }}</custom-label>

        <div
          class="
            border-2
            rounded-md
            border-gray-200 border-solid
            px-3
            m-2
            max-h-60
            overflow-y-scroll
          "
          id="modalContent"
        >
          <v-radio-group
            v-model="wishlist"
            column
          >
            <v-radio
              v-for="item in wishlists"
              :key="item.title"
              :label="item.title"
              color="primary"
              class="text-sm"
              active-class="activeRadio"
              :value="item"
            ></v-radio>
          </v-radio-group>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script >
import CustomModal from "@/components/shared/CustomModal.vue";
import CustomLabel from "@/components/shared/customLabel/CustomLabel.vue";
import customTextInput from "@/components/shared/customTextInput/CustomTextInput.vue";
import { mapGetters } from "vuex";

export default {
  name: "FeaturedCard",
  props: [
    "card",
    "titleStyle",
    "contentStyle",
    "imgmdCols",
    "detailsmdCols",
    "favIconStyle",
    "specContainer",
  ],
  components: {
    CustomModal,
    customTextInput,
    CustomLabel,
  },
  data() {
    return {
      isFavorite: false,
      wishlist: {},
      cardStatus: {},
      wishlistName: "",
      newWishlistModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(["wishlists"]),
  },
  methods: {
    setFavorite(event) {
      this.newWishlistModalVisible = event.isFav;
      this.cardStatus = event;

      if (!event.isFav) this.$store.commit("updateWishList", event);
      const parsedobj = JSON.parse(JSON.stringify(this.wishlists));
    },
    addToWishList() {
      if (this.wishlistName != "") {
        this.$store.commit("insertWishList", this.wishlistName);
        this.cardStatus.wishlistId =
          this.wishlists[this.wishlists.length - 1].id;
      } else {
        this.cardStatus.wishlistId = this.wishlist.id;
      }
      this.$store.commit("updateWishList", this.cardStatus);

      this.newWishlistModalVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.featuredCard {
  background: white;
  border-radius: 15px;
}

.details {
  position: relative;
  height: 100%;
  padding-bottom: 30px;
}
.details h5 {
  font-weight: bold;
  color: var(--primary);
}
.details p {
  font-weight: normal;
  color: gray;
  line-height: 28px;
}
.specDetails {
  font-size: small;
  color: gray;
  display: inline-flex;
  padding-right: 5px;
  align-self: center;
}
.priceDetails h5 {
  display: inline-flex;
}
.priceDetails h6 {
  display: inline-flex;
  color: gray;
  text-decoration: line-through;
}
.fav {
  width: 100%;
  position: absolute;
  top: 5px;
  cursor: pointer;
}
.featuredCard .row {
  width: 100%;
  margin: 0;
}

.titleStyle {
  padding-block: 10px !important;
  font-size: 1rem;
}

.priceTag {
  position: absolute;
  right: -16px;
  top: 17%;
}
.priceTag span {
  position: absolute;
  top: 10%;
  left: 10%;
  font-size: small;
  color: white;
}
.priceTagAR {
  position: absolute;
  left: -16px;
  top: 17%;
}
.priceTagAR span {
  position: absolute;
  top: 10%;
  right: 10%;
  font-size: small;
  color: white;
  text-align: left;
}
.priceTagAR img {
  transform: scaleX(-1);
}
@media only screen and (max-width: 600px) {
  .propertiesContainer .featuredCard {
    margin-top: 50px;
  }
}
</style>
<style src="@\assets\styles\carousel.css">
</style>
