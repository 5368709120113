<template>
  <AuthLayout
    :sideTitle="$t('LogIn.ExploreFuture')"
    :sideSubTitle="$t('LogIn.EazySolution')"
  >
    <Login />
  </AuthLayout>
</template>

<script>
import Login from "@/components/signInComponent/signInComponent.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  components: {
    Login,
    AuthLayout
  }
};
</script>