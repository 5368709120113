<template>
  <div class="propertiesBarContainer">
    <div class="barHeader">
      <h6>{{ $t("properties.advancedSearch") }}</h6>
    </div>
    <div class="px-5 pt-5 bg-white">
      <custom-select
        :isDense="true"
        class="propertySelect"
        :label="$t('properties.propertyCategory')"
        :items="categories"
        :data="searchParams.category"
        @data-changed="(value) => (searchParams.category = value)"
      />
      <custom-select
        :isDense="true"
        class="propertySelect"
        :label="$t('properties.propertyLocation')"
        :items="locations"
        :data="searchParams.location"
        @data-changed="(value) => (searchParams.location = value)"
      />
      <custom-select
        :isDense="true"
        class="propertySelect"
        :label="$t('properties.propertyType')"
        :items="types"
        :data="searchParams.type"
        @data-changed="(value) => (searchParams.type = value)"
      />
      <custom-select
        :isDense="true"
        class="propertySelect"
        :label="$t('properties.propertyFloor')"
        :items="floors"
        :data="searchParams.floor"
        @data-changed="(value) => (searchParams.floor = value)"
      />

      <div class="mt-2 d-flex">
        <custom-label :value="$t('properties.priceRange')" labelClass="mb-0" />
        <custom-label
          :value="
            searchParams.priceRange[0] +
            ' - ' +
            searchParams.priceRange[1] +
            '$'
          "
          labelClass="mb-0"
          class="mb-2"
          :class="$i18n.locale == 'ar' ? 'mr-auto' : 'ml-auto'"
        />
      </div>
      <div class="mr-2 ml-2 mb-3" dir="ltr">
        <Slider
          :showTooltip="'drag'"
          :max="1000000"
          :min="0"
          :step="1000"
          class="sliderTool"
          tooltipPosition="bottom"
          v-model="searchParams.priceRange"
        />
      </div>

      <div class="mt-2 d-flex">
        <custom-label :value="$t('properties.area')" labelClass="mb-0" />
        <custom-label
          :value="
            $i18n.locale == 'ar'
              ? searchParams.areaRange[0] +
                ' - ' +
                searchParams.areaRange[1] +
                'م2'
              : searchParams.areaRange[0] +
                ' - ' +
                searchParams.areaRange[1] +
                'sqft'
          "
          labelClass="mb-0"
          class="mb-2"
          :class="$i18n.locale == 'ar' ? 'mr-auto' : 'ml-auto'"
        />
      </div>
      <div class="mr-2 ml-2 mb-3" dir="ltr">
        <Slider
          :showTooltip="'drag'"
          :max="1000"
          :min="0"
          :step="10"
          class="sliderTool"
          tooltipPosition="bottom"
          v-model="searchParams.areaRange"
        />
      </div>

      <div class="mt-2 d-flex">
        <custom-label :value="$t('properties.bedrooms')" labelClass="mb-0" />
        <custom-label
          :value="searchParams.bedsRange[0] + ' - ' + searchParams.bedsRange[1]"
          labelClass="mb-0"
          class="mb-2"
          :class="$i18n.locale == 'ar' ? 'mr-auto' : 'ml-auto'"
        />
      </div>
      <div class="mr-2 ml-2 mb-3" dir="ltr">
        <Slider
          :showTooltip="'drag'"
          :max="10"
          :min="0"
          :step="1"
          class="sliderTool"
          tooltipPosition="bottom"
          v-model="searchParams.bedsRange"
        />
      </div>

      <div class="mt-2 d-flex">
        <custom-label :value="$t('properties.bathrooms')" labelClass="mb-0" />
        <custom-label
          :value="
            searchParams.bathsRange[0] + ' - ' + searchParams.bathsRange[1]
          "
          labelClass="mb-0"
          class="mb-2"
          :class="$i18n.locale == 'ar' ? 'mr-auto' : 'ml-auto'"
        />
      </div>
      <div class="mr-2 ml-2 mb-3" dir="ltr">
        <Slider
          :showTooltip="'drag'"
          :max="10"
          :min="0"
          :step="1"
          class="sliderTool"
          tooltipPosition="bottom"
          v-model="searchParams.bathsRange"
        />
      </div>

      <custom-select
        :isDense="true"
        class="propertySelect"
        :label="$t('properties.view')"
        :items="views"
        :data="searchParams.view"
        @data-changed="(value) => (searchParams.view = value)"
      />
      <custom-select
        :isDense="true"
        class="propertySelect"
        :label="$t('properties.finishing')"
        :items="finishings"
        :data="searchParams.finishing"
        @data-changed="(value) => (searchParams.finishing = value)"
      />
      <custom-select
        :isDense="true"
        class="propertySelect"
        :label="$t('properties.paymentMethod')"
        :items="paymentMethods"
        :data="searchParams.paymentMethod"
        @data-changed="changeValue"
      />
      <custom-date-picker
        @updateValue="(value) => (searchParams.deliveryDate = value)"
        :label="$t('properties.deliveryDate')"
        :dateValue="searchParams.deliveryDate"
      />
    </div>
    <div class="searchBtns">
      <v-btn @click="resetSearch" depressed>{{ $t("properties.reset") }}</v-btn>
      <v-btn depressed color="primary" @click="$emit('search', searchParams)">
        {{ $t("properties.search") }}
      </v-btn>
    </div>
    <div class="saveSearch">
      <v-btn
        @click="handleSaveSearch"
        depressed
        outlined
        class
        color="primary"
        >{{ $t("properties.saveSearch") }}</v-btn
      >
    </div>
    <custom-modal
      @close="modalVisible = false"
      :isVisible="modalVisible"
      :modalTitle="$t('modal.saveSearch')"
      :okBtn="$t('modal.save')"
      :cancelBtn="$t('modal.cancel')"
      @confirm="emitSaveSearch"
    >
      <div class="px-5 py-2">
        <custom-text-input
          :label="$t('modal.enterSearchName')"
          :isDense="true"
          :placeholder="$t('modal.search')"
          @data-changed="(value) => (saveSearchTitle = value)"
          :data="saveSearchTitle"
        />
      </div>
    </custom-modal>
  </div>
</template>

<style scoped>
.searchBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding-block: 20px;
  padding-inline: 20px;
}
.searchBtns .v-btn {
  width: calc(50% - 5px);
  padding: 5px 7px;
  margin-inline: 5px;
  font-weight: 600;
}

.saveSearch {
  background: white;
  padding-bottom: 20px;
  padding-inline: 20px;
}
.saveSearch .v-btn {
  width: 100%;
  font-weight: 600;
}
.propertiesBarContainer {
  background: var(--white);
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.barHeader {
  background: var(--bar-header-bg);
  padding: 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
@media only screen and (max-width: 768px) {
  .propertiesBarContainer {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
<style lang="scss" src="@\assets\styles\common.scss"></style>

<script >
import CustomSelect from "@/components/shared/customSelect/CustomSelect.vue";
import CustomDatePicker from "@/components/shared/CustomDatePicker.vue";
import Slider from "@vueform/slider/dist/slider.vue2";
import CustomLabel from "@/components/shared/customLabel/CustomLabel.vue";
import CustomModal from "@/components/shared/CustomModal.vue";
import customTextInput from "@/components/shared/customTextInput/CustomTextInput.vue";
export default {
  components: {
    CustomSelect,
    CustomDatePicker,
    Slider,
    CustomLabel,
    customTextInput,
    CustomModal,
  },
  props: ["appliedSearch"],
  data() {
    return {
      saveSearchTitle: "",
      modalVisible: false,
      categories: ["1 Star", "2 Stars", "3 Stars", "4 Stars", "5 Stars"],
      locations: ["Cairo", "Alex"],
      types: ["Appartment", "Villa", "chalet"],
      floors: ["Ground Floor", "First Floor", "Second Floor"],
      views: ["Sea", "Pool"],
      finishings: ["Decorations", "Electerical"],
      paymentMethods: ["Cash", "Credit Card", "Master Card"],
      priceActive: false,
      priceValue: [50000, 100000],
      bedsValue: [0, 1],
      bathsValue: [0, 1],
      areaValue: [20, 100],
      searchParams: {
        category: "",
        location: "",
        type: "",
        floor: "",
        view: "",
        finishing: "",
        paymentMethod: "",
        priceRange: [0, 1000000],
        bedsRange: [0, 10],
        bathsRange: [0, 10],
        areaRange: [0, 1000],
        deliveryDate: {},
      },
    };
  },
  created() {
    this.resetSearch();
  },
  mounted() {
    if (this.appliedSearch && this.appliedSearch.params) {
      this.searchParams.category = this.appliedSearch.params.category || "";
      this.searchParams.location = this.appliedSearch.params.location || "";
      this.searchParams.type = this.appliedSearch.params.type || "";
      this.searchParams.floor = this.appliedSearch.params.floor || "";
      this.searchParams.view = this.appliedSearch.params.view || "";
      this.searchParams.finishing = this.appliedSearch.params.finishing || "";
      this.searchParams.paymentMethod =
        this.appliedSearch.params.paymentMethod || "";
      this.searchParams.priceRange = this.appliedSearch.params.priceRange || [
        0, 1000000,
      ];
      this.searchParams.bedsRange = this.appliedSearch.params.bedsRange || [
        0, 10,
      ];
      this.searchParams.bathsRange = this.appliedSearch.params.bathsRange || [
        0, 10,
      ];
      this.searchParams.areaRange = this.appliedSearch.params.areaRange || [
        0, 1000,
      ];
      this.searchParams.deliveryDate =
        this.appliedSearch.params.deliveryDate || {};
      this.saveSearchTitle = this.appliedSearch.searchTitle;
    }
  },
  methods: {
    resetSearch() {
      this.searchParams.category = "";
      this.searchParams.location = "";
      this.searchParams.type = "";
      this.searchParams.floor = "";
      this.searchParams.view = "";
      this.searchParams.finishing = "";
      this.searchParams.paymentMethod = "";
      this.searchParams.priceRange = [0, 1000000];
      this.searchParams.bedsRange = [0, 10];
      this.searchParams.bathsRange = [0, 10];
      this.searchParams.areaRange = [0, 1000];
      this.searchParams.deliveryDate = {};
      this.$emit("search", this.searchParams);
    },
    changeValue(value) {
      this.searchParams.paymentMethod = value;
    },
    handleSaveSearch() {
      this.modalVisible = true;
    },
    emitSaveSearch() {
      this.modalVisible = false;
      this.$emit("saveSearch", {
        searchTitle: this.saveSearchTitle,
        params: { ...this.searchParams },
      });
      this.saveSearchTitle = "";
    },
  },
};
</script>
