<template>
  <div class="blogsContainer">
    <CustomHeader headerColor="primary" />
    <v-container class="containerPadding">
      <section class="container mb-6">
        <BreadCrumb :breadcrumbs="breadcrumbs" />
      </section>
      <v-row>
        <v-col
          cols="12"
          md="9"
        >
          <v-row
            class="searchHeader align-center px-5"
            no-gutters
          >
            <v-col
              class="blogsHeader"
              cols="4"
              md="2"
              sm="2"
              xs="12"
            >
              <h6>{{ $t("blogs.blogs") }}</h6>
            </v-col>
            <v-col
              class="d-flex d-sm-none"
              cols="4"
            >
              <div class="customSort">
                <v-btn
                  :color="type == 'list' ? 'primary' : 'white'"
                  class="noShadow"
                  small
                  width="30"
                  height="40"
                  :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                  @click="setFilterType('list')"
                >
                  <img
                    alt="search"
                    class="shrink"
                    contain
                    width="17"
                    :class="type == 'list' ? 'active' : 'inactive'"
                    :style="{
                      transform: $i18n.locale == 'en' ? '' : 'scaleX(-1)',
                    }"
                    :src="require(`@/assets/img/ico/list.svg`)"
                  />
                </v-btn>
                <v-btn
                  :color="type == 'grid' ? 'primary' : 'white'"
                  class="noShadow"
                  small
                  width="30"
                  height="40"
                  :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                  @click="setFilterType('grid')"
                >
                  <img
                    alt="search"
                    class="shrink"
                    contain
                    :class="type == 'grid' ? 'active' : 'inactive'"
                    width="17"
                    :style="{
                      transform: $i18n.locale == 'en' ? '' : 'scaleX(-1)',
                    }"
                    :src="require(`@/assets/img/ico/grid.svg`)"
                  />
                </v-btn>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="10"
              sm="9"
              xs="12"
            >
              <v-row class="content propertiesHeaderContent">
                <v-col
                  class="px-0"
                  cols="10"
                  sm="8"
                  md="9"
                  lg="7"
                >
                  <div class="featuredcustomTabs">
                    <v-tabs
                      v-model="activeTab"
                      centered
                    >
                      <v-tab
                        class="rounded-tl"
                        :class="
                          $i18n.locale == 'ar' ? 'tabClassAR' : 'tabClass'
                        "
                      >
                        <v-btn
                          @click="category = Filters.ALL"
                          :class="activeTab == 0 ? 'activeTab' : 'inactiveTab'"
                        >{{ $t("blogs.All") }}</v-btn>
                      </v-tab>
                      <v-tab
                        class="rounded-tr"
                        :class="
                          $i18n.locale == 'ar' ? 'tabClassAR' : 'tabClass'
                        "
                      >
                        <v-btn
                          @click="category = Filters.NEWS"
                          :class="activeTab == 1 ? 'activeTab' : 'inactiveTab'"
                        >{{ $t("blogs.News") }}</v-btn>
                      </v-tab>
                      <v-tab
                        class="rounded-tr"
                        :class="
                          $i18n.locale == 'ar' ? 'tabClassAR' : 'tabClass'
                        "
                      >
                        <v-btn
                          @click="category = Filters.TIPS"
                          :class="activeTab == 2 ? 'activeTab' : 'inactiveTab'"
                        >{{ $t("blogs.Tips") }}</v-btn>
                      </v-tab>
                      <v-tab
                        class="rounded-tr"
                        :class="
                          $i18n.locale == 'ar' ? 'tabClassAR' : 'tabClass'
                        "
                      >
                        <v-btn
                          :class="activeTab == 3 ? 'activeTab' : 'inactiveTab'"
                          @click="category = Filters.GUIDE"
                        >{{ $t("blogs.Guide") }}</v-btn>
                      </v-tab>
                    </v-tabs>
                  </div>
                </v-col>
                <v-col
                  class="d-none d-sm-flex"
                  cols="3"
                  sm="2"
                  md="3"
                  lg="1"
                >
                  <div class="customSort">
                    <v-btn
                      :color="type == 'list' ? 'primary' : 'white'"
                      class="noShadow"
                      small
                      width="30"
                      height="40"
                      :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                      @click="setFilterType('list')"
                    >
                      <img
                        alt="search"
                        class="shrink"
                        contain
                        width="17"
                        :class="type == 'list' ? 'active' : 'inactive'"
                        :style="{
                          transform: $i18n.locale == 'en' ? '' : 'scaleX(-1)',
                        }"
                        :src="require(`@/assets/img/ico/list.svg`)"
                      />
                    </v-btn>
                    <v-btn
                      :color="type == 'grid' ? 'primary' : 'white'"
                      class="noShadow"
                      small
                      width="30"
                      height="40"
                      :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                      @click="setFilterType('grid')"
                    >
                      <img
                        alt="search"
                        class="shrink"
                        contain
                        :class="type == 'grid' ? 'active' : 'inactive'"
                        width="17"
                        :style="{
                          transform: $i18n.locale == 'en' ? '' : 'scaleX(-1)',
                        }"
                        :src="require(`@/assets/img/ico/grid.svg`)"
                      />
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            no-gutters
            class="justify-start my-md-5 my-sm-3"
          >
            <v-col
              cols="12"
              :md="type == 'list' ? 12 : 4"
              sm="12"
              xs="12"
              v-for="card in displayedCards"
              :key="card.id"
              class="px-2 mt-md-2 mb-md-5 mt-sm-2 mb-sm-10 my-xs-10"
            >
              <Blogs-card
                :class="type != 'list' ? 'grid' : ''"
                :btnContainer="type != 'list' ? 'featureCardBtn' : ''"
                :imgmdCols="type == 'list' ? 4 : 12"
                :detailsmdCols="type == 'list' ? 12 : 12"
                :card="card"
                :type="type"
                :titleStyle="
                  type == 'list' ? 'titleStyle' : 'FeaturedTitleStyle'
                "
                :imgGridCont="type == 'list' ? '' : 'imgGridCont'"
                contentStyle="contentStyle"
                :favIconStyle="type == 'list' ? '' : 'favIconStyle'"
                :specContainer="type == 'list' ? '' : 'specContainer'"
                :premiumStyle="
                  $i18n.locale == 'ar'
                    ? type == 'list'
                      ? 'premiumStyleAR'
                      : 'premiumStyleRespAR'
                    : type == 'list'
                    ? 'premiumStyle'
                    : 'premiumStyleResp'
                "
              />
            </v-col>
          </v-row>

          <v-pagination
            v-model="page"
            :length="Math.ceil(recordsCount / itemsPerPage)"
            @input="setPaginateItems"
            class="mb-7"
          ></v-pagination>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <!-- mobile add  -->
          <div class="md:hidden">
            <img
              width="100%"
              :src="require(`@/assets/img/ads919.png`)"
            />
            <img
              width="100%"
              :src="require(`@/assets/img/ads919.png`)"
            />
          </div>
          <!-- big screens  -->
          <div class="hidden md:block">
            <img
              width="100%"
              :src="require(`@/assets/img/ads293.png`)"
            />
            <img
              width="100%"
              :src="require(`@/assets/img/ads293.png`)"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <CustomFooter />
  </div>
</template>

<script>
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import BlogsCard from "@/components/blogsComponent/blogsCards/blogsCard.vue";
import Filters from "@/utils/BlogFilters";
import BreadCrumb from "@/components/shared/breadcrumb/BreadCrumb.vue";
export default {
  components: {
    CustomHeader,
    BlogsCard,
    CustomFooter,
    BreadCrumb,
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t("shared.home"),
          slug: `/${this.$route.params.lang}`,
        },
        {
          title: this.$t("blogs.blogs"),
        },
      ],
      Filters,
      page: 1,
      type: "list",
      category: Filters.ALL,
      itemsPerPage: 6,
      recordsCount: 0,
      tab: 1,
      activeTab: 0,
      info: {},
      guids: [],
      cards: [
        {
          id: 0,
          cardImage: ["blog1"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          buttonTitle: "ReadMore",
          category: "News",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
        {
          id: 1,
          cardImage: ["blog2"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          category: "News",
          buttonTitle: "ReadMore",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
        {
          id: 2,
          cardImage: ["blog3"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          category: "Tips",
          buttonTitle: "ReadMore",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
        {
          id: 3,
          cardImage: ["blog1"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          category: "Tips",
          buttonTitle: "ReadMore",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
        {
          id: 5,
          cardImage: ["blog2"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          category: "Guide",
          buttonTitle: "ReadMore",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
        {
          id: 6,
          cardImage: ["blog3"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          category: "Guide",
          buttonTitle: "ReadMore",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
        {
          id: 7,
          cardImage: ["blog1"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          category: "Tips",
          buttonTitle: "ReadMore",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
        {
          id: 8,
          cardImage: ["blog2"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          category: "News",
          buttonTitle: "ReadMore",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
        {
          id: 9,
          cardImage: ["blog3"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          category: "Tips",
          buttonTitle: "ReadMore",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
        {
          id: 10,
          cardImage: ["blog1"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          category: "Guide",
          buttonTitle: "ReadMore",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
        {
          id: 11,
          cardImage: ["blog2"],
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          category: "Tips",
          buttonTitle: "ReadMore",
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          availableDate: new Date(),
        },
      ],
    };
  },
  mounted() {
    this.recordsCount = this.cards.length;
  },
  methods: {
    setPaginateItems(event) {
      this.page = event;
    },
    setFilterType(type) {
      this.type = type;
      this.$emit("setFilterType", this.type);
    },
    changeFilter(filter) {
      this.category = filter;
      this.page = 1;
    },
  },
  computed: {
    displayedCards() {
      let viewedCards = [...this.cards];
      if (this.category !== Filters.ALL) {
        viewedCards = this.cards.filter(
          (card) => card.category === this.category
        );
      }
      return viewedCards.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.blogsContainer {
  background: var(--light-gray-bg-01);
  .containerPadding {
    padding-top: 100px;
  }
  .back {
    text-decoration: none;
    color: var(--text-color-01);
  }
  .text {
    color: var(--text-color-02);
  }
  .searchHeader {
    background: var(--white);
    border-radius: 8px;
    .searchHeader h6 {
      display: inline-flex;
    }
    .searchHeader p {
      display: inline-flex;
      margin: 0;
    }
    .imgGridCont {
      height: 200px !important;
      image-rendering: pixelated;
    }
    .searchHeader span {
      font-size: small;
      display: inline-flex;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .noShadow {
    box-shadow: none;
  }
  .inactive {
    filter: brightness(1) invert(0.5) grayscale(100%);
  }
  .active {
    filter: brightness(0) invert(1);
  }
}
.customSort {
  display: inline-flex;
  width: min-content;
  border: none !important;
}
.featuredcustomTabs {
  background: #f9f9f9;
  margin: 6px;
  border-radius: 12px;
  text-align: center;
  .inactiveTab {
    color: #394456;
    box-shadow: none;
    border-radius: 8px;
    background-color: transparent;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 24px;
    font-family: var(--font-en);
    margin-inline: 0px;
  }
  .inactiveTab:hover {
    background-color: transparent;
  }
  .activeTab {
    background-color: var(--primary) !important;
    color: var(--white);
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
  }
  .activeTab span {
    color: white;
    font-weight: bold;
    vertical-align: middle;
  }
}
.v-tab {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0.0892857143em;
  line-height: normal;
  min-width: 65px;
  max-width: 65px;
  outline: none;
  padding: 0px;
}

@media only screen and (max-width: 960px) {
  .blogsHeader {
    margin: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .featuredcustomTabs {
    .content {
      justify-content: center;
    }
    .v-btn:not(.v-btn--round).v-size--default {
      min-width: 100%;
      padding: 0 1px;
    }
  }
  .v-btn:not(.v-btn--round).v-size--small {
    height: 28px;
    min-width: 37px;
    padding: 0 12.4444444444px;
  }
  .featuredcustomTabs .inactiveTab {
    margin: 0px;
  }
  .v-tab {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 0 1 auto;
    font-size: 0.875rem;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.0892857143em;
    line-height: normal;
    min-width: 45px;
    max-width: 45px;
    outline: none;
    padding: 0px;
  }
}
@media only screen and (max-width: 1000px) {
  .propertiesSearchContainer {
    margin-bottom: 20px;
  }
  .blogAdvImg {
    margin-inline: 20px;
  }
}
</style>

 