<template>
  <div class="w-full flex">
    <div
      class="flex flex-grow-0 items-center text-text-light text-sm cursor-pointer hover:text-text-dark gap-2"
      @click="this.goHome"
    >
      <PhArrowLeft class="inline-block rtl:hidden" />
      <PhArrowRight class="hidden rtl:inline-block" />
      <span>{{$t('shared.backToHome')}}</span>
    </div>
  </div>
</template>

<script  >
import { PhArrowLeft, PhArrowRight } from "phosphor-vue";
export default {
  components: { PhArrowLeft, PhArrowRight },
  methods: {
    goHome() {
      this.$router.push({ path: `/${this.$route.params.lang}` });
    }
  }
};
</script>