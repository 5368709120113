<template>
  <div>
    <h2 class="text-lg" style="font-size: 1.125rem !important;">{{$t('req_meeting.EnterArea')}}</h2>
    <div class="w-full" dir="ltr">
      <div class="mt-8 md:mt-10 flex items-center justify-between text-text-light text-sm">
        <span>{{$t('req_meeting.Area')}}</span>
        <span>{{$t('req_meeting.Sqft')}}</span>
      </div>
      <div class="mt-4 mb-12 md:mb-16">
        <Slider
          :showTooltip="'always'"
          :max="3000"
          :min="0"
          :step="10"
          class="sliderTool"
          tooltipPosition="bottom"
          :format="(v) => v + ' ' + $t('req_meeting.Sqft') "
          v-model="areaValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "@vueform/slider/dist/slider.vue2";
export default {
  components: { Slider },
  data() {
    return {
      areaValue: [320, 1100]
    };
  }
};
</script>