<template>
  <div class="custom-data-table">
    <v-data-table
      dataTableBorderRadius="14%"
      :sort-by.sync="sortColumn"
      :sort-desc="true"
      :headers="headers"
      :items="items"
      hide-default-footer
      class="elevation-0 bg-transparent"
      item-class="shadow-0 elevation-0"
    >
      <template v-slot:[`item.Status`]="{ item }">
        <span :class="getColor(item.Status)">
          {{ item.Status }}
        </span>
      </template>
      <template v-slot:[`item.Name`]="{ item }">
        <img
          class="item-name-img"
          :src="require(`@/assets/img/${item.img}.png`)"
        />
        <span class="item-name-span">
          {{ item.Name }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return { sortColumn: '' }
  },
  watch: {
    sortBy() {
      this.sortColumn = this.sortBy
    }
  },
  props: ["headers", "items", 'sortBy'],
  methods: {
    getColor(Status) {
      if (Status == "Active") {
        return "text-green-500";
      }
      if (Status == "Done") {
        return "text-purple-900";
      } else {
        return "text-yellow-400";
      }
    },
    getNoteColor(note) {
      if (note == "Urgent") {
        return 'text-orange-500'
      } else {
        return "text-green-500";
      }
    },
  },
};
</script>

<style lang="scss">
.custom-data-table {
  .v-text-field__details {
    display: none !important;
  }

  .v-data-table > .v-data-table__wrapper > table tr {
    background-color: white;
  }

  .v-data-table-header > tr {
    background-color: var(--light-gray-01) !important;
  }

  .v-data-table > .v-data-table__wrapper > table {
    border-collapse: separate;
    border-spacing: 0 10px;
  }

  .v-data-table {
    background-color: transparent !important;
  }

  tr {
    th:first-child {
      border-end-start-radius: 10px;
      border-start-start-radius: 10px;
    }

    th:last-child {
      border-start-end-radius: 10px;
      border-end-end-radius: 10px;
    }

    td:first-child {
      border-end-start-radius: 10px;
      border-start-start-radius: 10px;
    }
    td:last-child {
      border-start-end-radius: 10px;
      border-end-end-radius: 10px;
    }
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-weight: 400;
    line-height: 24px;
    color: #5f666f;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 24px;
    color: var(--text-color-04);
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
    border-bottom: none !important;
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    border-bottom: none !important;
  }
  .item-name-span {
    padding-inline-start: 10px;
  }
  .item-name-img {
    vertical-align: middle;
    padding-block: 2px;
  }

  .v-data-table-header th.sortable .v-data-table-header__icon {
    display: none;
  }
}
</style>

