<template>
  <div class="mreq__cards">
    <div
      v-if="!isList"
      :class="`mt-4  grid overflow-x-auto min-w-fit pb-2 grid-cols-1 gap-3  sm:grid-cols-2 lg:grid-cols-3 `"
    >
      <RequestsCard
        v-for="(data, ix) in displayedCards"
        :key="data.id"
        :index="ix"
        :data="data"
        :cardsType="cardsType"
        @openReqDialog="openReqDialog"
      />
    </div>

    <RequestsTable v-else :data="cards" @openReqDialog="openReqDialog" />
    <div class="mt-6">
      <v-pagination
        flat
        v-model="page"
        :length="Math.ceil(recordsCount / itemsPerPage)"
        @input="setPaginateItems"
        class="mb-7"
      ></v-pagination>
    </div>

    <v-dialog v-model="reqDialog" width="70%" max-width="1000">
      <RequestsCardDetails
        @closeDialog="closeReqDialog"
        :data="cards.find(c => c.id ===  dialogId)"
      />
    </v-dialog>
  </div>
</template>

<script>
import RequestsCard from "./RequestsCard.vue";
import RequestsTable from "./RequestsTable.vue";
import RequestsCardDetails from "./RequestsCardDetails.vue";

const images = [
  "https://images.unsplash.com/photo-1616047006789-b7af5afb8c20?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
  "https://images.unsplash.com/photo-1581541234269-03d5d8576c0e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=546&q=80",
  "https://images.unsplash.com/photo-1596900779744-2bdc4a90509a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=638&q=80",
  "https://images.unsplash.com/photo-1616628198585-6add765244e0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=637&q=80",
  "https://images.unsplash.com/photo-1615971677499-5467cbab01c0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
  "https://images.unsplash.com/photo-1617098650990-217c7cf9de26?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80",
  "https://images.unsplash.com/photo-1616627561839-074385245ff6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=634&q=80",
  "https://images.unsplash.com/photo-1632119580908-ae947d4c7691?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
];

export default {
  components: { RequestsCard, RequestsTable, RequestsCardDetails },
  props: {
    cardsType: String
  },
  computed: {
    isList() {
      return this.cardsType === "list";
    }
  },
  data() {
    return {
      reqDialog: false,
      dialogId: null,
      page: 1,
      itemsPerPage: 6,
      recordsCount: 0,
      displayedCards: [],
      cards: [
        {
          id: 1,
          image: images[1],
          title:
            this.$i18n.locale == "ar" ? "وحدة مميزة" : "Renovated Apartment",
          date: "Dec 21, 2022",
          status: "Pending"
        },
        {
          id: 2,
          image: images[2],
          title:
            this.$i18n.locale == "ar"
              ? "وحدة مميزة"
              : "Renovated Apartment dddd ddd",
          date: "Dec 21, 2022",
          status: "Offered"
        },
        {
          id: 3,
          image: images[3],
          title:
            this.$i18n.locale == "ar" ? "وحدة مميزة" : "Renovated Apartment",
          date: "Dec 21, 2022",
          status: "Pending"
        },
        {
          id: 4,
          image: images[4],
          title:
            this.$i18n.locale == "ar" ? "وحدة مميزة" : "Renovated Apartment",
          date: "Dec 21, 2022",
          status: "Pending"
        },
        {
          id: 5,
          image: images[5],
          title:
            this.$i18n.locale == "ar" ? "وحدة مميزة" : "Renovated Apartment",
          date: "Dec 21, 2022",
          status: "Offered"
        },
        {
          id: 6,
          image: images[6],
          title:
            this.$i18n.locale == "ar" ? "وحدة مميزة" : "Renovated Apartment",
          date: "Dec 21, 2022",
          status: "Offered"
        },
        {
          id: 7,
          image: images[2],
          title:
            this.$i18n.locale == "ar" ? "وحدة مميزة" : "Renovated Apartment",
          date: "Dec 21, 2022",
          status: "Pending"
        },
        {
          id: 8,
          image: images[3],
          title:
            this.$i18n.locale == "ar" ? "وحدة مميزة" : "Renovated Apartment",
          date: "Dec 21, 2022",
          status: "Offered"
        },
        {
          id: 9,
          image: images[4],
          title:
            this.$i18n.locale == "ar" ? "وحدة مميزة" : "Renovated Apartment",
          date: "Dec 21, 2022",
          status: "Offered"
        }
      ]
    };
  },
  mounted() {
    this.recordsCount = this.cards.length;
    this.displayedCards = this.cards.filter(x => x.id < 7);
  },
  methods: {
    closeReqDialog() {
      this.reqDialog = false;
    },
    openReqDialog(id) {
      if (id != undefined) {
        this.dialogId = id;
        this.reqDialog = true;
      }
    },
    setPaginateItems(event) {
      this.page = event;
      this.displayedCards = this.cards.filter(
        x =>
          x.id >= (event - 1) * this.itemsPerPage &&
          x.id < (event - 1) * this.itemsPerPage + this.itemsPerPage
      );
    }
  }
};
</script>

<style  >
.mreq__cards
  .v-pagination
  .v-pagination__item:not(.v-pagination__item--active) {
  background: transparent !important;
}
.mreq__cards .v-pagination .v-pagination__item,
.mreq__cards .v-pagination .v-pagination__navigation {
  box-shadow: none;
}
</style>