<template>
  <div class="latestCard">
    <img
        alt="img"
        class=""
        contain
        width="100%"
        :src="require(`@/assets/img/${card.cardImage}.png`)"
    />
    <div class="details">
         <v-row style="width:100%" no-gutters>
             <v-col class="specDetails">
                 <img
                    width="13%"
                    alt="location"
                    class="shrink "
                     :class="$i18n.locale=='ar'?'ml-2':'mr-2'"
                    contain
                    :src="require(`@/assets/img/ico/locationGray.svg`)"
                 />
                 {{card.location}}
             </v-col>
             <v-col class="specDetails">
                 <img
                    width="13%"
                    alt="date"
                    class="shrink "
                    :class="$i18n.locale=='ar'?'ml-2':'mr-2'"
                    contain
                    :src="require(`@/assets/img/ico/calendar.svg`)"
                 />
                 {{format(card.availableDate)}}
             </v-col>
         </v-row>
         <h5 class="mt-2 mb-2">{{card.title}}</h5>
         <p class="mb-5">{{card.description}}</p>
         <div class="text-right btnLink">
             <router-link :to="`/blogs/${card.id}/${$route.params.lang}`" color="primary" small class="py-3 px-3  ">
                 {{$t('home.'+card.buttonTitle)}} >
             </router-link>
         </div>
    </div>
  </div>
</template>

<script >
import moment from 'moment'

export default {
  name:'LatestCard',
  props:['card'],
  components:{
  },
  data() {
    return {
    }
  },
  methods: {
    format (value) {
      if (value) {
        return moment(String(value)).format('MMM DD, YYYY')
      }
    }
  }

}
</script>
<style scoped lang="scss">
    .latestCard {
        background: white;
        border-radius: 15px;
        width: min-content;
        padding: 20px;
        margin: 0 auto;
        width: calc(100% - 10%);
    }
    .specDetails {
        font-size: small;
        color: gray;
        display: inline-flex;
        padding-right: 5px;
        align-self: center;
    }
    @media only screen and (max-width: 600px) {
      .specDetails {
          font-size: smaller;
        color: gray;
        display: inline-flex;
        padding-right: 5px;
        align-self: center;
      }
       .latestCard {
        background: white;
        border-radius: 15px;
        width: min-content;
        padding: 20px;
        margin: 0 auto;
        width: 100%;
    }
    }
    .details{
        position:relative;
        height: 100%;
        padding-bottom: 20px;
        margin: 10px;
    }
    .details h5 {
        font-weight: bold;
        color: var(--dark-primary);
        padding-top: 5px;
    }
    .details p {
        font-size: small;
        font-weight: normal;
        color: gray;
    }
    .fav {
        width:100%;
        position:absolute;
        top:5px;
        cursor: pointer;
    }
    .btnLink {
      width:100%;
      position:absolute;
      bottom:0;
    }
</style>
