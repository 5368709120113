<template>
  <v-row
    class="searchHeader align-center px-5"
    no-gutters
  >
    <v-col
      cols="12"
      md="4"
      sm="12"
      xs="12"
    >
      <h6 class="d-inline-flex">{{ title }}</h6>
    </v-col>
    <v-col
      cols="12"
      md="8"
      sm="12"
      xs="12"
    >
      <v-row
        class="content wishListHeaderContent"
        no-gutters
      >
        <v-col
          class="md:contents sm:w-full xs:w-full filterSortingLabelContainer"
          cols="12"
          md="6"
          sm="12"
          xs="12"
        >
          <p class="inline-flex ma-0">{{ $t('home.category') }}</p>
          <custom-select
            :items="categories"
            class="customSort"
            appendedIcon="mdi-chevron-down"
            :data="category"
            @data-changed="changeWishlistFilter"
          />
        </v-col>
        <v-col
          class="md:contents sm:w-full xs:w-full filterSortingLabelContainer"
          cols="12"
          md="6"
          sm="12"
          xs="12"
        >
          <p class="d-inline-flex ma-0">{{ $t('properties.sortby') }}</p>
          <custom-select
            :items="sortByItems"
            class="customSort"
            appendedIcon="mdi-chevron-down"
            :data="sortBy"
            @data-changed="changeSort"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
.searchHeader {
  background: var(--white);
  border-radius: 8px;

  .customSort {
    display: inline-flex;
    width: min-content;
    border: none !important;
    .v-select__selection--comma {
      margin: 7px 20px 7px 0 !important;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 1000px) {
  .searchHeader {
    padding-block: 20px;
    margin-bottom: 20px;
  }
}
</style>

<script >
import CustomSelect from '@/components/shared/customSelect/CustomSelect.vue';
import { mapGetters } from "vuex";

export default {
  props: ['title', 'sortBy', 'category'],
  data() {
    return {
      categories: [{ text: 'All' }, { text: 'Commercial' }, { text: 'Residential' }],
      sortByItems: [{ text: 'Lowest Price' }, { text: 'Highest Price' }],
      type: 'list'
    }
  },
  methods: {
    setFilterType(type) {
      this.type = type;
      this.$emit("setFilterType", this.type);
    },
    changeWishlistFilter(value) {
      this.$emit("handleFilter", value)
    },
    changeSort(value) {
      this.$emit("handleSort", value)
    },
  },
  components: {
    CustomSelect
  },
  computed: {
    ...mapGetters(["cards"]),
  }
}
</script>
