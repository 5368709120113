<template>
  <div class="mb-8">
    <div class="container">
      <div class="bg-white rounded-lg p-6 md:p-7">
        <h2 class="text-3xl mb-6" style="font-size: 1.875rem !important">{{$t('Terms.TermsHeader')}}</h2>
        <h6>{{$t('Terms.TermsHead1')}}</h6>
          <p>{{$t('Terms.Termstext')}}</p>
        <div v-for="x in 3" :key="x" class="text-text">
        <h6>{{$t('Terms.TermsHead2')}}</h6>
          <p>{{$t('Terms.Termstext2')}}</p>
          <p>{{$t('Terms.Termstext3')}}</p>
          <p>{{$t('Terms.Termstext4')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {}
};
</script>