<template>
  <div
    class="blogContainer"
    :class="$i18n.locale == 'ar' ? '' : ''"
  >
    <CustomHeader
      :auth="auth"
      headerColor="primary"
    />
    <v-container class="containerPadding">
      <p>
        <router-link :to="`/blogs/${$route.params.lang}`">{{
          $t("blogs.blogs")
        }}</router-link>
        >>
        <span class="text"> {{ $t("blogs.BlogPreview") }} </span>
      </p>
      <v-row class="blog-Wrapper">
        <v-col
          class="blog-bg-Wrapper mr-5"
          cols="6"
          sm="7"
          md="8"
          lg="8"
        >
          <BlogDetailsContanier :blogDetails="blogs" />
        </v-col>
        <v-col
          class="blog-bg-Wrapper"
          cols="5"
          sm="4"
          md="3"
          lg="3"
          :class="$i18n.locale == 'ar' ? 'blog-bg-Wrapper-Ar' : ''"
        >
          <LatestArticles :blogDetails="blogs" />
        </v-col>
      </v-row>
    </v-container>
    <CustomFooter />
  </div>
</template>

<script>
import CustomHeader from "@/components/header/header.vue";
import BlogDetailsContanier from "@/components/blogsComponent/singleBlogDetails/BlogsDetailsContainer.vue";
import LatestArticles from "@/components/blogsComponent/singleBlogDetails/LatestArticlesContainer.vue";
import CustomFooter from "@/components/footer/footer.vue";
export default {
  components: {
    CustomHeader,
    BlogDetailsContanier,
    LatestArticles,
    CustomFooter,
  },
  data() {
    return {
      auth: true,
      displayedblogs: [],
      blogs: [
        {
          id: 0,
          blogImage: "blog1",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          availableDate: new Date(),
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
          tags: ["News", "Tips", "Guide"],
        },
        {
          id: 1,
          blogImage: "singleBlog1",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          availableDate: new Date(),
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          tags: ["News", "Tips", "Guide"],
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
        },
        {
          id: 2,
          blogImage: "blog3",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          tags: ["News", "Tips", "Guide"],
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
          availableDate: new Date(),
        },
        {
          id: 3,
          blogImage: "blog1",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          tags: ["News", "Tips", "Guide"],
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
          availableDate: new Date(),
        },
        {
          id: 5,
          blogImage: "blog2",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          tags: ["News", "Tips", "Guide"],
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
          availableDate: new Date(),
        },
        {
          id: 6,
          blogImage: "blog3",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          tags: ["News", "Tips", "Guide"],
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
          availableDate: new Date(),
        },
        {
          id: 7,
          blogImage: "blog1",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          tags: ["News", "Tips", "Guide"],
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
          availableDate: new Date(),
        },
        {
          id: 8,
          blogImage: "blog2",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          tags: ["News", "Tips", "Guide"],
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
          availableDate: new Date(),
        },
        {
          id: 9,
          blogImage: "blog3",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          tags: ["News", "Tips", "Guide"],
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
          availableDate: new Date(),
        },
        {
          id: 10,
          blogImage: "blog1",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          tags: ["News", "Tips", "Guide"],
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
          availableDate: new Date(),
        },
        {
          id: 11,
          blogImage: "blog2",
          title:
            this.$i18n.locale == "ar"
              ? "سيتى سكيب مصر 2022"
              : "Cityscape Egypt 2022",
          location:
            this.$i18n.locale == "ar"
              ? "القاهرة الجديدة, مصر"
              : "New Cairo, Egypt",
          description:
            this.$i18n.locale == "ar"
              ? "هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص. بينما تعمل جميع مولّدات نصوص لوريم إيبسوم على الإنترنت على إعادة تكرار مقاطع من نص لوريم إيبسوم نفسه عدة مرات بما تتطلبه الحاجة، يقوم مولّدنا هذا باستخدام كلمات من قاموس يحوي على أكثر من 200 كلمة لا تينية، مضاف إليها مجموعة من الجمل النموذجية، لتكوين نص لوريم إيبسوم ذو شكل منطقي قريب إلى النص الحقيقي. وبالتالي يكون النص الناتح خالي من التكرار، أو أي كلمات أو عبارات غير لائقة أو ما شابه. وهذا ما يجعله أول مولّد نص لوريم إيبسوم حقيقي على الإنترنت."
              : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry.",
          tags: ["News", "Tips", "Guide"],
          date: this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
          articles: [
            {
              articlesId: 0,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article1",
            },
            {
              articlesId: 1,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article2",
            },
            {
              articlesId: 2,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article3",
            },
            {
              articlesId: 3,
              articleTitle:
                this.$i18n.locale == "ar"
                  ? "سيتى سكيب مصر 2022"
                  : "Lorem Ipsum is simply dummy text y",
              date:
                this.$i18n.locale == "ar" ? "21 ديسمبر 2022" : "Dec 21, 2022",
              ArticleImage: "last-article4",
            },
          ],
          availableDate: new Date(),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.blogContainer {
  background: var(--light-gray-bg-01);

  .blog-Wrapper {
    margin-top: 36px;
    justify-content: start;
  }
  .blog-bg-Wrapper-Ar {
    margin-right: 20px;
  }
  .blog-bg-Wrapper {
    background: #ffffff;
    border-radius: 20px;
    padding: 0px;
    margin-bottom: 100px;
  }
}
.containerPadding {
  padding-top: 100px;
}
</style>