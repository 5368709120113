<template>
  <div class="bg-bg-light">
    <CustomHeader
      :auth="auth"
      headerColor="primary"
    />
    <div class="mt-14 md:mt-16"></div>
    <section class="container pt-8 md:pt-10">
      <BreadCrumb :breadcrumbs="breadcrumbs" />
    </section>
    <main class="projects_page pb-10 lg:pb-12 mt-3">
      <projectContent />
    </main>
    <CustomFooter />
  </div>
</template>

<script>
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import BreadCrumb from "@/components/shared/breadcrumb/BreadCrumb.vue";

import projectContent from "@/components/projects/ProjectsContent.vue"
import { mapGetters } from "vuex";

export default {
  components: {
    CustomHeader,
    CustomFooter,
    BreadCrumb,
    projectContent,
  },
  computed: {
    ...mapGetters(["projects", "appliedSearch"]),
  },
  data() {
    return {
      auth: true,
      page: 1,

      breadcrumbs: [
        {
          title: this.$t("shared.home"),
          slug: `/${this.$route.params.lang}`
        },
        {
          title: this.$t("shared.ourProjects")
        }
      ]
    };
  },

};
</script>

<style  >
.projects__search .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>