<template>
  <div>
    <section class="mreq__filters bg-white shadow-sm rounded-lg py-3 md:py-4 px-5 md:px-6">
      <div class="w-full flex flex-wrap xs:justify-center md:justify-between items-center gap-3">
        <div class="flex flex-grow-0 justify-center md:justify-start flex-wrap items-center gap-2">
          <h3 style="font-size: 1.25rem !important">{{$t('requests.Requests')}}</h3>
          <span class="h-3 w-[1px] bg-gray-200"></span>
          <span class="text-sm">
            {{$t('requests.Total')}}
            <span class="text-text-dark">15 {{$t('requests.results')}}</span>
          </span>
        </div>

        <div class="flex items-center gap-4 flex-grow-0">
          <!-- sort  -->
          <div class="flex flex-grow-0 justify-center md:justify-start items-center">
            <span>{{$t('projects.SortBy')}}:</span>
            <custom-select
              :items="sortByItems"
              class="customSort -mb-2 h-auto min-h-fit text-text-dark flex-grow-0 max-w-[100px]"
              :item-value="sortByValue"
              :data="sortByValue"
              :placeholder="$t('projects.CommercialProjects')"
            />
          </div>
          <!-- view changer  -->
          <div>
            <div>
              <v-btn
                :color="cardsType== 'list' ? 'primary' : 'white'"
                small
                elevation="0"
                width="30"
                height="40"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                @click="setFilterType('list')"
              >
                <img
                  alt="search"
                  class="shrink"
                  contain
                  width="17"
                  :class="cardsType== 'list' ? 'active' : 'inactive'"
                  :style="{transform: $i18n.locale=='en' ? '' : 'scaleX(-1)'}"
                  :src="require(`@/assets/img/ico/list.svg`)"
                />
              </v-btn>
              <v-btn
                :color="cardsType== 'grid' ? 'primary' : 'white'"
                elevation="0"
                small
                width="30"
                height="40"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                @click="setFilterType('grid')"
              >
                <img
                  alt="search"
                  class="shrink"
                  contain
                  :class="cardsType== 'grid' ? 'active' : 'inactive'"
                  width="17"
                  :style="{transform: $i18n.locale=='en' ? '' : 'scaleX(-1)'}"
                  :src="require(`@/assets/img/ico/grid.svg`)"
                />
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="mreq__cards"></div>
  </div>
</template>

<script>
import CustomSelect from "@/components/shared/customSelect/CustomSelect.vue";

export default {
  components: { CustomSelect },
  props: {
    cardsType: String
  },
  data() {
    return {
      sortByItems: ["Date", "Status"],
      sortByValue: "Date"
    };
  },
  methods: {
    setFilterType(t) {
      this.$emit("changeCardType", t);
    }
  }
};
</script>

<style scoped>
.inactive {
  filter: brightness(1) invert(0.5) grayscale(100%);
}
.active {
  filter: brightness(0) invert(1);
}
</style>