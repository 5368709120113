<template>
  <AuthLayout :fullWidth="true">
    <AuthCard>
      <Newpassword />
    </AuthCard>
  </AuthLayout>
</template>

<script>
import Newpassword from "@/components/newPasswordComponent/NewPasswordComponent.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import AuthCard from "@/components/auth/shared/AuthCard.vue";

export default {
  components: {
    Newpassword,
    AuthLayout,
    AuthCard
  }
};
</script>