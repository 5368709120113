<template>
  <router-link :to="`/projects/${project.id}/${$route.params.lang}`">
    <div class="project__card bg-white shadow-sm rounded-2xl p-3 cursor-pointer">
      <div class="relative">
        <span class="absolute top-3 left-3 rtl:left-auto rtl:right-3 bg-white text-text-dark text-sm px-2 py-1 font-bold rounded shadow z-[1]">{{project.propertiesNum}} {{$t('projects.projects')}}</span>
        <Carousel
          style="direction:ltr"
          :loop="true"
          :perPageCustom="[[0,1],[600,1],[960,1]]"
          paginationPosition="bottom-overlay"
          :paginationEnabled="project.cardImage.length > 1 ? true : false"
          :autoplay="false"
          :mouse-drag="project.cardImage.length > 1 ? true : false"
          :centerMode="true"
        >
          <Slide
            v-for="(image , ix) in project.cardImage"
            :key="project.id+ix+image"
            class="text-center"
          >
            <img
              alt="img"
              class="w-full aspect-[3/2] fill-height rounded-lg"
              :src="require(`@/assets/img/${image}`)"
            />
          </Slide>
        </Carousel>
      </div>
      <div class="mt-2 p-2">
        <h5>{{project.title.en}}</h5>

        <div class="flex gap-2 mt-1 text-text-light text-sm">
          <img
            alt="location"
            class="shrink"
            contain
            :src="require(`@/assets/img/ico/locationGray.svg`)"
          />
          {{project.location}}
        </div>

        <div class="mt-4 flex items-center gap-2">
          <span class="first-letter:">{{ $t('projects.StartingPrice') }}:</span>
          <span class="text-2xl font-bold text-text-dark">${{project.startingPrice}}</span>
        </div>
        <div
          v-if="project.downPayment"
          class="mt-4 text-secondary"
        >
          {{$t('projects.Downpayment')}} {{project.downPayment.value}}
          {{$t('projects.over')}} {{project.downPayment.duration}}
        </div>
      </div>
    </div>
  </router-link>
</template>


<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    project: Object
  },
};
</script>


<style lang="scss" src="@\assets\styles\common.scss"></style>
