import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#220054',
        secondary: '#C8A45D',
        info: '#548e2d29',
        btn: '#220054',
        lightPrimary: '#57407a',
        midGray:'#6b7280',
        darkPrimary: '#1B2534',
        accent: '#82B1FF',
        error: '#FF5252',
        success: '#4CAF50',
        warning: '#FFC107',
        lightDark: '#383e43',
        subtitle: '#777E90',

      }
    }
  },
  rtl: false,
})
