<template>
  <section class="t">
    <div class="pb-6" dir="ltr">
      <carousel :perPage="1" :paginationEnabled="false" :navigate-to="activeSlide">
        <slide
          v-for="(img , ix) in images"
          :key="ix+'-img'"
          class="rounded-xl md:rounded-2xl shadow-sm"
        >
          <img
            alt="Logo"
            class="bg-white w-full h-96 object-cover rounded-xl md:rounded-2xl"
            :src="img"
          />
        </slide>
      </carousel>

      <div class="w-full hidden md:flex justify-center -mt-20">
        <carousel :perPage="3" class="w-full md:w-2/3 lg:w-1/2 pb-41" :paginationEnabled="false">
          <slide
            v-for="(img , ix) in images"
            :key="ix+'-img'"
            class="p-2"
            @slideclick="() => handleSlideClick(ix)"
          >
            <img
              alt="Logo"
              class="object-cover w-full h-36 rounded-xl"
              :class="activeSlide === ix ? 'shadow-sm': 'shadow-lg'"
              contain
              :src="img"
            />
          </slide>
        </carousel>
      </div>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      activeSlide: 0,
      images: [
        "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8cHJvcGVydHl8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        "https://images.unsplash.com/photo-1448630360428-65456885c650?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80",
        "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
        "https://images.unsplash.com/photo-1600047509807-ba8f99d2cdde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=992&q=80",
        "https://images.unsplash.com/photo-1600607687939-ce8a6c25118c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1153&q=80"
      ]
    };
  },
  methods: {
    handleSlideClick(ix) {
      this.activeSlide = ix;
    }
  }
};
</script>