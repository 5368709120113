<template>
  <v-row class="exprties-section">
    <v-col cols="12">
      <v-card-title class="text-h5">
        <span class="exprties-section__title">
          {{ $t("profile.Expertise") }}
        </span>
        <v-spacer></v-spacer>

        <router-link :to="`/realtor/editexpertise/${$route.params.lang}`">
          <v-btn class="exprties-section__icon" icon>
            <img src="@/assets/img/ico/Edit.svg" alt="Edit.svg" />
          </v-btn>
          <span class="exprties-section__EditAncortitle">{{
            $t("profile.edit")
          }}</span>
        </router-link>
      </v-card-title>
      <v-divider></v-divider>

      <v-row class="exprties-section__information">
        <v-col cols="12">
          <v-row class="exprties-section__information__details">
            <v-col cols="12" sm="6" lg="6">
              <label>{{ $t("profile.ExpertiseArea") }}</label>
              <p>{{ data.expertiseArea }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="6">
              <label>{{ $t("profile.Categories") }}</label>
              <br/>
              <span v-for="item in data.Categories.join(',').split(' ')" :key="item">
              {{item}}
              </span>
            </v-col>
            <v-col cols="12" sm="6" lg="12">
              <label>{{ $t("profile.ExpertIn") }}</label>
              <br/>
              <span class=""  v-for="item in data.expertIn.join(',').split(' ')" :key="item" >{{ item}}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["data"],

};
</script>

<style lang="scss" scoped>
.exprties-section {
  padding: 0px;
  background: var(--white);
  border: 1px solid rgba(232, 233, 234, 0.4);
  box-shadow: 0px 4px 14px rgba(233, 233, 233, 0.25);
  border-radius: 16px;
  margin-bottom: 24px;
  &__title {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 30px;
    color: var(--dark-primary);
  }
  &__icon {
    .v-btn--icon.v-size--default {
      height: 17px;
      width: 18px;
      margin-inline: 9px;
    }
    .theme--light.v-btn.v-btn--icon {
      color: transparent;
    }
    .theme--light.v-btn :hover {
      color: transparent;
    }
  }
  &__EditAncortitle {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 22px;
    color: var(--primary);
  }
  &__information {
    margin-top: 12px;
    &__title {
      font-family: var(--font-en);
      font-style: normal;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 24px;
      color: var(--dark-primary);
    }
    &__subtitle {
      font-family: var(--font-en);
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 24px;
      color: var(--text-mute);
    }
    &__details {
      padding-inline: 32px;
      margin-top: 12px;
      margin-bottom: 4px;
      label {
        font-family: var(--font-en);
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 20px;
        color: var(--text-mute);
      }
      p {
        font-family: var(--font-en);
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 22px;
        color: var(--text-color-04);
      }
    }
  }
  .v-btn__content {
    width: 16px;
    height: 16px;
    margin-right: 15.67px;
    i {
      color: var(--primary);
    }
  }
}

@media only screen and (max-width: 599px) {
  .exprties-section__information {
    &__details {
      text-align: center;
    }
    .v-list-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      min-height: 1px;
    }
  }
}
@media only screen and (max-width: 959px) {
  .exprties-section__information {
    &__details {
      text-align: center;
    }
    .v-list-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      min-height: 1px;
    }
  }
}
</style>