<template>
  <div class="bg-bg-light">
    <CustomHeader headerColor="primary" />
    <div class="mt-14 md:mt-16"></div>

    <section class="container pt-8 md:pt-10">
      <BreadCrumb :breadcrumbs="breadcrumbs" />
    </section>
    <main class="req_meeting_page pb-10 lg:pb-12">
      <div class="container mt-4 md:mt-8">
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-5 lg:gap-6">
          <div class="req_meeting_page--content col-span-full md:col-start-1 md:col-end-4">
            <MainContent />
          </div>
          <div class="req_meeting_page--aside col-span-full md:col-start-4">
            <AsideContent />
          </div>
        </div>
      </div>
    </main>
    <CustomFooter />
  </div>
</template>

<script>
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import MainContent from "@/components/requestMeeting/MainContent.vue";
import AsideContent from "@/components/requestMeeting/AsideContent.vue";
import BreadCrumb from "@/components/shared/breadcrumb/BreadCrumb.vue";

export default {
  components: {
    CustomHeader,
    CustomFooter,
    BreadCrumb,
    MainContent,
    AsideContent
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t("shared.home"),
          slug: `/${this.$route.params.lang}`
        },
        {
          title: this.$t("req_meeting.title")
        }
      ]
    };
  }
};
</script>