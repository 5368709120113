export default {
  header: {
    main: "الرئيسية",
    about: "من نحن",
    properties: "عقارات",
    faq: "الأسئلة الشائعة",
    contact: "تواصل معنا",
    login: "تسجيل دخول",
  },
  footer: {
    underIconText:
      "Zrealtors عبارة عن منصة ذات وجهين توفر طريقة ذكية لشراء العقارات ،",
    underIconText2: " من صنع تريند",
    importantLinks: "روابط مهمة",
    main: "الرئيسية",
    about: "من نحن",
    properties: "عقارات",
    faq: "الأسئلة الشائعة",
    contact: "تواصل معنا",
    privacyPolicy: "سياسة الخصوصية",
    termsAndConditions: "الأحكام والشروط",
    contactInfo: "معلومات التواصل",
    copyRights: "جميع الحقوق محفوظة.",
    projects: "مشاريع",
    developers: "المطورين",
    realEstateGuide: "دليل العقارات",
    commercialServices: "خدمات تجارية",
    partnerWithUs: "شريك معنا",
    becomePartner: "تريد ان تصبح شريك",
    reserveYourProperty: "احجز ملكيتك",
    footerLocation: "3 منطقة سعد ، بوابة منقرع ، حدائق الاهرام ، الجيزة",
  },
  LogIn: {
    ExploreFuture: "إكتشف المستقبل معنا",
    EazySolution: "Zrealtors يوفر حلولاً سهلة!",
  },
  password: {
    welcomeMessage: "أهلاً, مرحباً بك مرة أخرى",
    password: "الرقم السرى",
    newpassword: "رقم سرى جديد",
    confirmpassword: "تأكيد الرقم السرى",
    ResetPassword: "إعادة تعيين كلمة المرور",
    CreateAccount: "انشاء حساب",
    name: "الاسم",
    phone: "رقم الهاتف",
    email: "البريد الالكترونى",
    SignIn: "تسجيل الدخول",
    SignUp: "إنشاء حساب",
    OrSignUp: "أو قم بالاشتراك من هنا",
    OrSignIn: "أو قم بالتسجيل من هنا",
    rememberMe: "تذكرنى",
    HaveAccountMessage: " لديك حساب بالفعل؟ ",
    dontHaveAccountMessage: "ليس لديك حساب؟ ",
    SendOTP: "ارسال الرمز",
    SendLink: "أرسل الرابط",
    EmailVerification: "تأكيد بواسطة البريد الالكتروني",
    CreatePassword: "إنشاء كلمة مرور ",
    CreateNewPassword: "إنشاء كلمة مرور جديدة",
    EnterYourDetailsLog: "أدخل التفاصيل لتسجيل دخولك",
    EnterYourDetails: "أدخل التفاصيل لإنشاء حسابك",
    EnterYourEmail: "أدخل عنوان بريدك الإلكتروني المسجل",
    ForgotPassword: "هل نسيت كلمة السر ؟",
    SentOTPMessage:
      " لقد أرسلنا إلى رمز مكون من 4 أرقام على البريد الإلكتروني الخاص بك",
    DidntReceiveMessage: " لم تتلق الرمز؟ ",
    Resend: "إعادة إرسال",
    Successfully: "تهانينا",
    SuccessMessage: "يمكنك الآن استخدام كلمة مرورك الجديدة ",
    SuccessMessage2: "لتسجيل الدخول إلى حسابك",
    BackToSignIn: "العودة إلى تسجيل الدخول",
    NewPassword: "كلمة مرور جديدة",
    ConfirmPassword: "تأكيد كلمة المرور",
    PassValdate: "8 أحرف على الأقل",
    text: "............",
  },
  errors: {
    required: "مطلوب",
    minLength: "يجب ان يكون عدد الحروف اكبر من {num}",
    maxLength: "يجب ان يكون عدد الحروف اقل من من {num}",
    email: "بريد الكترونى غير صحيح",
    phone: "ادخل رقم هاتف صحيح",
    passwordMatch: "الرقم السرى غير متطابق",
  },
  home: {
    findProperty: "ابحث عن عقار",
    makeRequest: "تقديم طلب",
    priceRange: "نطاق السعر",
    category: "الفئة",
    location: "الموقع",
    RecentlySearchLocations: "مواقع تم البحث عنها",
    post1: `اعرف اكثر عن قيمة منزلك وخيارات البيع المتاحة`,
    post2: `هل تسعى لشراء وحدة جديدة او بيع وحدة حالية؟`,
    postDescription2: "نحن نقدم لك الحل الأسهل!",
    ReadMore: "اقرأ المزيد >",
    readMore: "اقرأ المزيد",
    featuredProperties: "الوحدات المتميزة",
    viewAll: "اظهر المزيد",
    viewDetails: "اظهر التفاصيل",
    contactUs: "تواصل معنا",
    newsUpdate: "اخر الأخبار",
    newsUpdate2: "أحدث الأخبار والعروض",
    premium: "استثنائي",
    beds: "سرائر",
    baths: "حمام",
    sqft: "متر مربع",
    recentLocationSearch: "المواقع التى تم البحث عنها",
    post2Description: `إذا كنت تخطط لبيع منزلك ، فربما تساءلت عن المبلغ الذي يمكنني بيعه لمنزلي؟ أحد التحديات التي تواجه تحديد قيمة منزلك هو أن “القيمة” متغيرة؛ حيث أنه قد يكون أحد المشترين على استعداد لدفع أكثر من غيره. \n
    إذا أردت بيع أو شراء منزل في منطقة ما ، فمن الضروري للغاية معرفة القيمة الحقيقية التي تعكس سعر المنزل المراد بيعه أو شرائه ، وما هي العوامل والمواصفات التي تساهم في خلق تلك القيمة. في هذا المقال سوف نلقي الضوء على أهم العوامل التي يتم الاهتمام بها عند الشراء أو البيع.`,
    searchLocation: "ابحث عن موقع",
  },
  profile: {
    profile: "الملف الشخصى",
    myProfile: "ملفي الشخصي",
    myWishlists: "قوائم الرغبات",
    ContactedUnits: "الوحدات المتصل بها",
    MyRequests: "طلباتى",
    Logout: "تسجيل خروج",
    profileInfo: "المعلومات الشخصية",
    EditProfile: "تعديل الملف",
    name: "اسم صاحب الملف",
    Email: "البريد الالكترونى",
    Password: "الرقم السرى",
    MobileNumber: "رقم الهاتف",
    Language: "اللغة",
    City: "المدينة",
    Gender: "الجنس",
    Cairo: "القاهره",
    Alex: "اسكندرية",
    English: "الانجليزية",
    Male: "ذكر",
    Female: "انثى",
    Single: "عازب",
    Married: "متزوج",
    DateOFBirthe: "تاريخ الميلاد",
    MaritalStatue: "الحالة الاجتماعية",
    NumberOfChildern: "عدد الأطفال",
    statistics: "إحصائيات",
    OpenedRequests: "الطلبات المفتوحة",
    ClosedRequests: "الطلبات المغلقة",
    SavedSearch: "بحث محفوظ",
    SearchName: "اسم البحث",
    Action: "إجراء",
    SearchText: "عقارات في القاهرة أقل من 700 ألف",
    ApplySearch: "تطبيق البحث",
    ViewProfile: "عرض الملف الشخصى",
    UploadPicture: "ارفع صورتك",
    UploadPictureText:
      "للحصول على أفضل النتائج ، استخدم صورة 300 بكسل × 300 بكسل على الأقل بتنسيق JPEG أو PNG",
    Upload: "تحميل",
    Remove: "حذف",
    Save: "حفظ",
    Cancel: "إلغاء",
    FirstName: "الاسم الاول",
    LastName: "الاسم الاخير",
    selectDate: "ادخل تاريخ الميلاد",
    MyLeads: "العملاء المحتملون",
    Leads: "العملاء المحتملون",
    MyCILs: "My CILs",
    CILs: "ضريبة البنية التحتية",
    MyProperties: "ممتلكاتي",
    Properties: "الممتلكات",
    Messages: "الرسائل",
    Settings: "الإعدادات",
    edit: "تعديل",
    Expertise: "الخبرة",
    ExpertiseArea: "مجال الخبرة:",
    ExpertiseAreaText: "From 300,000 to 300,500 LE",
    Categories: " التصنيفات:",
    CategoriesText: "Commercial,Adminstartive",
    ExpertIn: "خبير في:",
    ExpertInText: "Emaar,TMG",
    WorkExperience: "الخبرة العملية",
    Add: "إضافة",
    RealEstateCompany: "Real Estate Company",
    RealEstateStartup: "Real Estate Startup",
    SeniorConsultant: "Senior Consultant",
    SeniorRealtor: "Senior Realtor",
    BuyerAgent: "Buyer Agent",
    EditExpertise: "تعديل الخبرات",
    AddWorkExpr: "إضافة خبرة سابقة",
    Title: "المسمى الوظيفى",
    AddTitle: "إضافة المسمى الوظيفى",
    Company: "اسم الشركة",
    AddCompany: "إضافة اسم الشركة",
    emptySavedSearch: "يبدو أنك لم تقم بإضافة أي بحث محفوظ حتى الآن!",
  },
  properties: {
    home: "الرئيسية",
    properties: "العقارات",
    total: "مجموع",
    results: "النتائج",
    sortby: "التصنيف حسب",
    lowestPrice: "الأقل سعر",
    highestPrice: "الأعلى سعر",
    advancedSearch: "بحث متقدم",
    propertyCategory: "فئة الملكية",
    propertyLocation: "الموقع",
    propertyFloor: "الطابق",
    ReadMore: "اقرأ المزيد >",
    propertyType: "النوع",
    view: "الإطلالة",
    finishing: "التشطيب",
    paymentMethod: "طريقة الدفع",
    deliveryDate: "تاريخ الإستلام",
    reset: "إعادة",
    search: "بحث",
    priceRange: "نطاق السعر",
    area: "المساحة",
    bedrooms: "غرف النوم",
    bathrooms: "الحمامات",
    saveSearch: "تسجيل البحث",
    FeaturedPropertiesInLocation: "وحدات متميزة في هذا النطاق",
    Residential: "سكني",
    Administrative: "إداري",
    Commercial: "تجاري",
    TotalResult: "مجموع النتائج 15 نتيجة",
  },
  blogs: {
    home: "الرئيسية",
    blogs: "المدونات",
    properties: "عقارات",
    All: "الجميع",
    News: "أخبار",
    login: "تسجيل دخول",
    Tips: "نصائح",
    Guide: "مرشد",
    ReadMore: "اقرأ المزيد >",
    BlogPreview: "معاينة المدونة",
    Search: "بحث",
    SearchKeywords: "مفاتيح البحث",
    LatestArticles: "آخر المقالات",
    BlogPreviewText:
      "مؤتمر سيتي سكيب الافتراضي هو المؤتمر العقاري مؤتمر سيتي سكيب الافتراضي هو المؤتمر الحقيقي ...",
  },
  shared: {
    backToHome: "الرجوع الى الرئيسية",
    home: "الرئيسية",
    ourProjects: "مشاريعنا",
    Developers: "المطورين",
    ContactUs: "تواصل معنا",
    AboutUs: "عن Zrealtors",
    Terms: "الأحكام والشروط",
    privacy: "سياسة الخصوصية",
    projects: "المشاريع",
    CommercialService: "الخدمات التجارية",
  },
  req_meeting: {
    title: "طلب اجتماع",
    steps: {
      step_1_title: "المساحة",
      step_2_title: "فئة العقار",
      step_3_title: "نوع العقار",
      step_4_title: "السعر",
      step_5_title: "البيانات الشخصية",
    },
    btns: {
      skip: "تخطى",
      next: "التالي",
      previous: "السابق",
      submit: "ارسال",
    },
    Area: "المساحة",
    EnterArea: "ادخل المساحة",
    Sqft: "Sqft",
    EnterPropertyCategory: "ادخل فئة العقار",
    PropertyCategory: "فئة العقار",
    EnterPropertyType: "ادخل نوع العقار",
    PropertyType: "نوع العقار",
    EnterPriceRange: "ادخل متوسط السعر",
    PriceRange: "متوسط السعر",
    EnterPersonalInfo: "ادخل بياناتك الشخصية",
    Name: "الاسم",
    MobileNumber: "رقم الهاتف",
    Email: "البريد الإلكتروني",
    Comment: "لديك ملاحظة",
    WriteComment: "اكتب ملاحظاتك ...",
  },
  unit: {
    unitDetails: "تفاصيل الوحدة",
    otherPropertiesFrom: "عقارات أخرى من",
    registerInterest: "تسجيل إهتمامك",
    floor: "الطابق",
    addWishList: "اضافة لفائمة الأمنيات",
  },
  projects: {
    AdvancedSearch: "البحث المتقدم",
    ProjectType: "نوع المشروع",
    projectLocation: "موقع المشروع",
    PriceRange: "متوسط السعر",
    Area: "المساحة",
    Sqft: "Sqft",
    Finishing: "الإطلالة",
    Reset: "اعادة ضبط",
    Search: "بحث",
    SaveSearch: "حفظ البحث",
    CommercialProjects: "المشاريع التجارية",
    Total: "المجموع",
    results: "نتيجة",
    SortBy: "صنف حسب ",
    StartingPrice: "السعر الاولي",
    Downpayment: "Down payment",
    over: "over",
    projects: "مشروع",
    projectDetails: "تفاصيل المشروع",
    downpayment: "مقدم",
    installmentYears: "سنوات التقسيط",
    Years: "سنوات",
    PaymentPlans: "خطط السداد",
    plan1: "الخطـة الأولى",
    plan2: "الخطـة الثانية",
    plan3: "الخطـة الثالثة",
    viewMore: "اظهر المزيد",
    Share: "شارك :",
    otherProjectes: "قد ترغب أيضًا",
  },
  developers: {
    ShowMore: "أظهر المزيد",
  },

  meetingDialog: {
    dialogTitle: "بوابتك لحياة غنية",
    SimilarUnitsIn: "عقارات مشابهة في",
    Name: "الاسم",
    MobileNumber: "رقم الهاتف المحمول",
    EmailAddress: "عنوان البريد الإلكتروني",
    Project: "المشروع",
    ProjectType: "نوع المشروع",
    Boker: "الوسيط العقاري",
    CallTime: "موعد المكالمة",
    Communication: "التواصل",
    PaymentMethod: "طريقة الدفع",
    Cash: "كاش",
    Installments: "تقسيط",
    Comment: "لديك تعليق ؟",
    WriteComment: "التعليق",
    RegisterNow: "سجل الآن",
  },
  modal: {
    saveSearch: "حفظ البحث",
    save: "حفظ",
    cancel: "الغاء",
    enterSearchName: "أدخل اسم البحث",
    search: "بحث",
    addWishList: "إضافة قائمة أمنيات",
    enterNewName: "أدخل اسم جديد",
    or: "أو",
    selectFromList: "أختر من القائمة",
    newWishList: "قائمة أمنيات جديدة",
    confirmMessage: "هل أنت متأكد؟",
    delete: "حذف",
  },
  requests: {
    MyRequests: "طلباتي",
    Requests: "الطلبات",
    Total: "المجموع",
    results: "نتيجة",
    Pending: "معلق",
    Offered: "معروض",
    PropertyName: "اسم العقار",
    Date: "التاريخ",
    Status: "الحالة",
    Action: "الاوامر",
    Details: "التفاصيل",
    RequestDetails: "تفاصيل الطلب",
    Name: "الاسم",
    Price: "السعر",
    Type: "النوع",
    Area: "المساحة",
    View: "المنظر",
  },
  wishlist: {
    myWishlists: "قائمتى المفضلة",
    addNewList: "إضافى قائمة جديدة",
  },
  faq: {
    frequentlyAskedQuestions: "أسئلة مكررة",
    FAQ: "أسئلة متكررة",
    FAQText:
      "تحتوي هذه الصفحة مجموعة الأسئلة الأكثر تكراراً والإجابات. إذا لم تجد الإجابة على سؤالك هنا ،من الممكن أن تتواصل معانا من خلال صفحة الاتصال.",
  },
  about: {
    aboutHeader: "سيتي سكيب مصر 2022",
    aboutText1:
      "زد ريلتورز هي وجهتك الأولى للعقار في مصر بأسهل طريقة. عرض تفاصيل صورة العقارات والعقارات. قارن واتصل بالمطور العقاري مجانًا.",
    aboutText2:
      "الحصول على رؤية أصول في التنمية شراء وبيع وتأجير العقارات نحن نسعى على تحقيق وتحقيق أحلامك. تسعى جاهدة أن تعمل بها وتواصل العمل في المستقبل. . لا تترد في التواصل معنا في حال وجود ملاحظات أو استفسارات لديك ..",
    aboutText3:
      "زد ريلتورز يوفر لك بيانات رائدة لمساعدتك في العثور على العقارات في جميع أنحاء مصر مما يجعلها سهلة ومريحة قدر الإمكان. تواصل مع أفضل المحترفين المحليين وتصفح مجموعتنا الواسعة من القوائم التجارية والسكنية. يمكنك شراء جميع أنواع العقارات ؛ من التطورات الجديدة لإعادة البيع ، وكل شيء بينهما. سنساعدك حتى على بيع ممتلكاتك. في إسكان.كوم، نحن مصممون على تبسيط العملية ، مع تزويدك بكل ما تقدمه الصناعة.",
  },
  contact: {
    ContactInformation: "معلومات الاتصال",
    Email: "البريد الإلكتروني",
    Phone: "الهاتف",
    Location: "الموقع",
    Name: "الاسم",
    Message: "الرسالة",
    YourMessage: "اكتب رسالتك",
    Send: "ارسال",
    getTouchMessage:
      "ترغب في الحصول على اتصال؟ نحب أن نسمع منك. إليك كيف يمكنك الوصول إلينا ...",
  },
  messages: {
    myMessages: "رسائلى",
    searchMessages: "أبحث عن رسالة",
    createNewMessage: "إنشاء رسالة جديدة",
    admins: "المسؤولين",
    clients: "العملاء",
    filterby: "تصفية حسب",
    Send: "ارسال",
    getTouchMessage:
      "ترغب في الحصول على اتصال؟ نحب أن نسمع منك. إليك كيف يمكنك الوصول إلينا ...",
    recievedMessages: "الرسايل المستلمة",
    sentMessages: "الرسالة المرسلة",
    search: "بحث عن شخص",
    writeMessage: "اكتب رسالتك هنا",
  },
  Terms: {
    TermsHeader: "الشروط والأحكام",
    TermsHead1: "شروط وأحكام استخدام الموقع الرسمي لشركة زا ريلتورز",
    Termstext:
      'مرحبا بكم في الموقع الرسمي لشركة (زا ريلتورز) على شبكة (الإنترنت) ويشار إليه في هذه الشروط والأحكام بلفظ "الموقع"',
    TermsHead2: "الإطلاع وقراءة شروط استخدام الموقع:",
    Termstext2:
      'تخضع عملية الدخول إلى الموقع والتصفح وتنزيل المواد ونسخها باستخدام هذا الموقع (ويشار إلى هذه العملية بلفظ "الاستخدام") و/أو أي معلومات أو مواد من هذا الموقع للأحكام والشروط المبينة أدنـاه ("الشروط")، لذا يرجى قراءة هذه الشروط ، وعلى وجه الخصوص بيان الخصوصية والسرية ، بعناية قبل استخدام هذا الموقع.',
    Termstext3:
      "بالنفاذ والدخول إلى هذا الموقع واستخدامه يفترض أن يكون المستخدم قد اطلع على هذه الأحكام والشروط وفهم فحواها وتشكل شروط الاستخدام -كما هي موضحة أدناه – عقدًا بينك وبين سيرا وإن استخدامك لهذا الموقع يعد قبولاً بما ورد بهذه الأحكام والشروط ، وفي حالة عدم التقيد بهذه الشروط فيجب عليك وقف استخدام هذا الموقع فوراً.",
    Termstext4:
      "يرجى ملاحظة أن هذه الشروط قد تتغير من وقتٍ لآخر كلما دعت الضرورة دون أي إشعار ويجب على مستخدمو الموقع قراءة الشروط والتغييرات المحتملة بانتظام. وعند الرغبة في الحصول على نسخة مطبوعة ، يرجى إرسال رسالة إلكترونية شاملة عنوان المراسلة الذي يمكننا التواصل معكم عن طريقه .",
    TermsHead5: "شروط وأحكام استخدام الموقع الرسمي لشركة زا ريلتورز",
  },
  policy: {
    privacyPolicy: "سياسة الخصوصية",
    policyHead1: "تجميع المعلومات واستعمالها:",
    policyText1:
      "نحن نقوم بتجميع عدد كبير من المعلومات المتنوعة لغايات مختلفة وذلك لتقديم الخدمات المتاحة على الموقع لك وتحسينها وتشمل على سبيل المثال لا الحصر:",
    Email: "البريد الالكترونى",
    name: "الاسم الأول والاسم الأخير.",
    phonenumber: "رقم الهاتف.",
    address: "العنوان ، والمنطقة ، والمحافظة ، والرمز البريدي ، والمدينة.",
    Cookies: "الكوكيز وبيانات الاستعمال.",
    Data: "البيانات من الشبكات الاجتماعية إذا كنت تستخدم حسابات الشبكة الاجتماعية الخاصة بك للتسجيل وإنشاء حساب في الموقع، ومن المعلومات المستخدمة من مواقع الشبكات الاجتماعية مثل Facebook و Google+ و Twitter ما يلي:",
    username: "اسم المستخدم للشبكة الاجتماعية.",
    Location: "الموقع",
    sex: "الجنس",
    DateBirth: "الموقع",
  },
  dataTable: {
    Date: "التاريخ",
    Name: "أسم العقار",
    StartingDate: "تاريخ البدأ",
    EndDate: "تاريخ الانتهاء",
    Selling: "سعر البيع",
    Status: "الحالة",
  },
  notifications: {
    notifications: "إشعارات",
    viewMore: "عرض المزيد",
    noNotifications: "لا يوجد اشعارات فى الوقت الحالى",
    loadMore: "عرض المزيد",
  },
};
