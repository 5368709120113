<template>
  <div class="profile-wrapper">
    <h4>{{ $t("profile.myProfile") }}</h4>
    <v-row>
      <PersonalInfo />
      <v-col class="advertising-wrapper" cols="11" md="3" xl="3">
         <div>
          <!-- mobile add  -->
          <div class="md:hidden">
            <img width="100%" :src="require(`@/assets/img/ads919.png`)" />
          </div>
          <!-- big screens  -->
          <div class="hidden md:block">
            <img width="100%" :src="require(`@/assets/img/ads293.png`)" />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PersonalInfo from "@/components/userComponent/personalInfoSection/userInfoComponent.vue";
export default {
  components: {
    PersonalInfo,
  },
};
</script>


<style lang="scss" scoped>
.profile-wrapper {
  h4 {
    margin-bottom: 24px;
  }
  .advertising-wrapper {
    padding-top: 0px;
  }
}
@media only screen and (max-width: 1264px) {
  .advertising-wrapper {
    text-align: center;
    img {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 959px) {
  .profile-wrapper {
    h4 {
      text-align: center;
      margin-top: 24px;
    }
  }
  .advertising-wrapper {
    margin: auto;
    img {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 599px) {
  .profile-wrapper {
    h4 {
      text-align: center;
      margin-top: 24px;
    }
  }
  .advertising-wrapper {
    margin: auto;
    text-align: center;
    img {
      width: 100%;
    }
  }
}
</style>