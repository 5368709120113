import ApiService from '@/services/api.service';
import { AxiosResponse } from 'axios';
import { Commit } from 'vuex'
import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_READ
} from '../actions.type';
import {
  COMMON_ERROR,
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,
  READ_NOTIFICATION
} from '../mutation.type';

export const NOTIFICATION_PER_PAGE = 10;

export default {
  namespaced: true,

  state: {
    loading: false,
    notifications: [],
    dropDownNotifications: [],
    total: 0,
    page: 0
  },
  getters: {
    loading: (state: NotificationsState) => {
      return state.loading;
    },
    notifications: (state: NotificationsState) => {
      return state.notifications;
    },
    total: (state: NotificationsState) => {
      return state.total;
    },
    page: (state: NotificationsState) => {
      return state.page;
    },
  },
  mutations: {
    [NOTIFICATION_REQUEST]: (state: NotificationsState) => {
      state.loading = true
    },
    [NOTIFICATION_SUCCESS]: (
      state: NotificationsState,
      payload: {
        notifications: Array<Notification>,
        total: number,
        page: number
      }
    ) => {
      state.loading = false
      state.notifications = [...state.notifications, ...payload.notifications]
      if (state.page === 1) {
        state.dropDownNotifications = payload.notifications;
      }
      state.total = payload.total
      // TODO: remove next line and use the comment when use APIs
      state.page = 1
      // state.page = payload.page
    },
    [NOTIFICATION_ERROR]: (state: NotificationsState) => {
      state.loading = false
    },
    [READ_NOTIFICATION]: (
      state: NotificationsState,
      notificationId: string
    ) => {
      state.notifications = state.notifications.map(notification => {
        if (notification.id === notificationId) {
          notification.read_at = new Date().toISOString();
        }

        return notification;
      })
      state.loading = false
    },
  },
  actions: {
    [GET_NOTIFICATIONS]: (
      { commit, state }: {
        commit: Commit,
        state: NotificationsState
      }
    ) => {
      return new Promise((resolve, reject) => {
        const notifications = [
          {
            id: 1,
            title: 'title',
            details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing.',
            created_at: new Date(),
            read_at: "1",
          },
          {
            id: 2,
            title: 'title',
            details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing.',
            created_at: new Date(2022, 6, 7, 10, 33, 30, 0),
          },
          {
            id: 3,
            title: 'title',
            details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing.',
            created_at: new Date('7/7/2022'),
            read_at: "1",
          },
          {
            id: 4,
            title: 'title',
            details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing.',
            created_at: new Date('7/7/2022'),
          },
          {
            id: 5,
            title: 'title',
            details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing.',
            created_at: new Date('7/7/2022'),
          },
          {
            id: 6,
            title: 'title',
            details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing.',
            created_at: new Date('7/7/2022'),
          },
          {
            id: 7,
            title: 'title',
            details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing.',
            created_at: new Date('7/7/2022'),
          },
          {
            id: 8,
            title: 'title',
            details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing.',
            created_at: new Date('7/7/2022'),
          },
          {
            id: 9,
            title: 'title',
            details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing.',
            created_at: new Date('7/7/2022'),
          },
          {
            id: 10,
            title: 'title',
            details: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing.',
            created_at: new Date('7/7/2022'),
          },
        ]
        commit(NOTIFICATION_SUCCESS, {
          notifications,
          total: 10,
          page: 10
        })

        resolve(notifications);

        //commit(NOTIFICATION_REQUEST)
        //
        //const params = {
        //  page: state.page + 1,
        //  per_page: NOTIFICATION_PER_PAGE
        //}
        //
        //ApiService.get('all-notifications', params)
        //  .then(async (response: AxiosResponse) => {
        //    const data = response.data.data;
        //    const notifications = data.data;
        //
        //    commit(NOTIFICATION_SUCCESS, {
        //      notifications,
        //      total: data.total,
        //      page: data.current_page
        //    })
        //
        //    resolve(notifications);
        //  })
        //  .catch(err => {
        //    commit(NOTIFICATION_ERROR)
        //    commit('common/' + COMMON_ERROR, err, { root: true })
        //    reject(err)
        //  })
      })
    },
    [MARK_NOTIFICATION_READ]: (
      { commit }: { commit: Commit },
      notificationId: string
    ) => {
      return new Promise((resolve, reject) => {
        commit(READ_NOTIFICATION, notificationId)
        resolve(null);
        //const params = {
        //  notification_id: notificationId
        //}
        //ApiService.get(
        //  'notification-mark-as-read',
        //  params
        //)
        //  .then(async (response: AxiosResponse) => {
        //    const data = response.data;
        //
        //    commit(READ_NOTIFICATION, notificationId)
        //
        //    resolve(data);
        //  })
        //  .catch(err => {
        //    commit('common/' + COMMON_ERROR, err, { root: true })
        //    reject(err)
        //  })
      })
    },
  },
}

interface NotificationsState {
  loading: boolean,
  notifications: Array<Notification>,
  dropDownNotifications: Array<Notification>,
  total: number,
  page: number,
}

interface Notification {
  id: string,
  type: string,
  notifiable_type: string,
  notifiable_id: number,
  data: {
    title: string,
    message: string,
    link: string,
    image: string
  },
  read_at: string,
  created_at: string,
  updated_at: string,
}