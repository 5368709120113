<template>
  <div class="SponsersBar">
    <carousel
      style="direction: ltr"
      :loop="true"
      :perPageCustom="[
        [0, 2],
        [600, 2],
        [960, 5],
      ]"
      :paginationEnabled="false"
      :autoplay="false"
      :mouse-drag="true"
      :centerMode="true"
    >
      <slide v-for="sponser in sponsers" :key="sponser" class="text-center">
        <img
          alt="Logo"
          class="shrink mr-2"
          contain
          height="22"
          :src="require(`@/assets/img/ico/${sponser}.svg`)"
        />
      </slide>
    </carousel>
  </div>
</template>

<script >
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "SponsersBar",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      sponsers: [
        "microsoft",
        "entrepreneur",
        "fortune",
        "businessWeb",
        "mashable",
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.SponsersBar {
  margin: 50px !important;
}
</style>
