<template>
  <div class="max-h-[80vh] bg-white text-text overflow-y-auto shadow-2xl rounded-2xl relative">
    <div class="flex items-center justify-between border-0 border-b border-gray-200 border-solid py-5 lg:py-6 mx-5 lg:mx-6 sticky top-0 bg-white z-10 text-text-dark">
      <h6>{{$t('projects.PaymentPlans')}}</h6>
      <span
        class="cursor-pointer hover:scale-110 transform text-text-light hover:text-text-dark"
        @click="$emit('closeDialog')"
      >
        <PhX weight="bold" />
      </span>
    </div>

    <div class="px-5">
      <div class="my-4">
        <h5 class="mb-2 my-4 ">{{ $t('projects.plan1') }}</h5>
        <div class="flex">
          <span
            class="align-self-center"
            :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
          >{{
                                    $t('projects.downpayment')
                            }}:</span>
          <h6 class="d-inline">10%</h6>
        </div>
        <div class="flex">
          <span
            class="align-self-center"
            :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
          >{{
                                    $t('projects.installmentYears')
                            }}:</span>
          <h6 class="d-inline">10 {{ $t('projects.Years') }}</h6>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="my-4">
        <h5 class="mb-2 my-4 ">{{ $t('projects.plan2') }}</h5>
        <div class="flex mt-4">
          <span
            class="align-self-center"
            :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
          >
            {{ $t('projects.downpayment') }}:
          </span>
          <h6 class="d-inline">10%</h6>
        </div>
        <div class="flex">
          <span
            class="align-self-center"
            :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
          >{{
                                    $t('projects.installmentYears')
                            }}:</span>
          <h6 class="d-inline">10 {{ $t('projects.Years') }}</h6>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="my-4">
        <h5 class="mb-2 my-4 ">{{ $t('projects.plan3') }}</h5>
        <div class="flex mt-4">
          <span
            class="align-self-center"
            :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
          >
            {{ $t('projects.downpayment') }}:
          </span>
          <h6 class="d-inline">10%</h6>
        </div>
        <div class="flex">
          <span
            class="align-self-center"
            :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
          >{{
                                    $t('projects.installmentYears')
                            }}:</span>
          <h6 class="d-inline">10 {{ $t('projects.Years') }}</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhX } from "phosphor-vue";

export default {
  components: {
    PhX
  },
  props: {
    data: Object
  },
  data() {
    return {};
  },
  mounted() {
    console.log(this.data);
  },
};
</script>
