<template>
  <div
    class="featuredCard"
    :dir="$i18n.locale=='ar'?'rtl':'ltr'"
  >
    <v-row>
      <v-col
        cols="12"
        :md="imgmdCols"
        sm="12"
        xs="12"
        class="text-center"
      >
        <v-carousel
          id="FeaturedCarousel"
          height="200px"
          style="direction: ltr"
          :loop="true"
          :hide-delimiters="
            card.cardImage == null || card.cardImage.length <= 1 ? true : false
          "
          :show-arrows="card.cardImage != null && card.cardImage.length > 1"
        >
          <v-carousel-item
            id="FeaturedSlide"
            v-for="image in card.cardImage"
            :key="card.id + image"
            class="text-center"
          >
            <img
              alt="img"
              style="height: 100% !important"
              class="w-full fill-height cardImgSlide"
              :src="require(`@/assets/img/${image}`)"
            />
          </v-carousel-item>
          <v-btn
            color="white"
            class="absolute top-3 ltr:left-3 rtl:right-3 py-3 px-3 primary--text"
            v-if="card.propertiesNum"
            small
          >
            {{card.propertiesNum}} Project
          </v-btn>
        </v-carousel>
      </v-col>
      <v-col
        cols="12"
        :md="detailsmdCols"
        sm="12"
        xs="12"
      >
        <div class="details pb-[30px] rtl:text-right ltr:text-left">
          <router-link :to="`/projects/${card.id}/${$route.params.lang}`">
            <h5
              class="my-2"
              :class="[titleStyle, 'mb-0']"
            >
              {{ $i18n.locale == "ar" ? card.title.ar : card.title.en }}
            </h5>
          </router-link>
          <div>
            <div class="flex">
              <img
                alt="location"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                class="shrink"
                contain
                :src="require(`@/assets/img/ico/locationGray.svg`)"
              />
              {{ card.location }}
            </div>
          </div>
          <div class="mt-4 flex items-center gap-2">
            <span class="first-letter:">{{ $t('projects.StartingPrice') }}:</span>
            <span class="text-2xl font-bold text-text-dark">${{card.startingPrice}}</span>
          </div>
          <div
            v-if="card.downPayment"
            class="mt-4 text-secondary"
          >
            {{$t('projects.Downpayment')}} {{card.downPayment.value}}
            {{$t('projects.over')}} {{card.downPayment.duration}}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script >

export default {
  name: "FeaturedCard",
  props: [
    "card",
    "titleStyle",
    "contentStyle",
    "imgmdCols",
    "detailsmdCols",
    "favIconStyle",
    "specContainer",
  ],
  components: {
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped lang="scss">
.featuredCard {
  background: white;
  border-radius: 15px;
}

.details {
  position: relative;
  height: 100%;
  padding-bottom: 30px;
}
.details h5 {
  font-weight: bold;
  color: var(--primary);
}
.details p {
  font-weight: normal;
  color: gray;
  line-height: 28px;
}
.featuredCard .row {
  width: 100%;
  margin: 0;
}
.titleStyle {
  padding-block: 5px !important;
  font-size: 1rem;
}

@media only screen and (max-width: 600px) {
  .propertiesContainer .featuredCard {
    margin-top: 50px;
  }
}
</style>
<style src="@\assets\styles\carousel.css">
</style>
