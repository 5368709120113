<template>
  <div class="max-h-[90vh] bg-white text-text overflow-y-auto shadow-2xl rounded-2xl relative">
    <div class="flex items-center justify-between border-0 border-b border-gray-200 border-solid py-5 lg:py-6 mx-5 lg:mx-6 sticky top-0 bg-white z-10 text-text-dark">
      <h6>{{$t('requests.RequestDetails')}}</h6>
      <span
        class="cursor-pointer hover:scale-110 transform text-text-light hover:text-text-dark"
        @click="$emit('closeDialog')"
      >
        <PhX weight="bold" />
      </span>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-4 py-5 lg:py-6 mx-5 lg:mx-6 mb-4">
      <div class="flex flex-col gap-1">
        <span class="text-sm text-text-light">{{$t('requests.Name')}}</span>
        <!-- <span v-if="data.title">{{data.title}}</span> -->
        <span v-if="data.title.en">{{data.title.en}}</span>
      </div>

      <div class="flex flex-col gap-1">
        <span class="text-sm text-text-light">{{$t('requests.Price')}}</span>
        <span>7,000,000 EGP</span>
      </div>

      <div class="flex flex-col gap-1">
        <span class="text-sm text-text-light">{{$t('requests.Type')}}</span>
        <span>Residential</span>
      </div>

      <div class="flex flex-col gap-1">
        <span class="text-sm text-text-light">{{$t('requests.Area')}}</span>
        <span>250 msq</span>
      </div>

      <div class="flex flex-col gap-1">
        <span class="text-sm text-text-light">{{$t('requests.View')}}</span>
        <span>Pool</span>
      </div>
      <div
        v-if="data.startingPrice"
        class="flex flex-col gap-1"
      >
        <span class="text-sm text-text-light">{{$t('projects.StartingPrice')}}</span>
        <span>{{data.startingPrice}}</span>
      </div>

      <div
        v-if="data.status"
        class="flex flex-col gap-1"
      >
        <span class="text-sm text-text-light">{{$t('requests.Status')}}</span>
        <span :class="`${data.status === 'Pending' ? 'text-orange-400' : 'text-success'}`">{{$t('requests.' + data.status)}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { PhX } from "phosphor-vue";

export default {
  components: {
    PhX
  },
  props: {
    data: Object
  },
  data() {
    return {};
  },
  mounted() {
    console.log(this.data);
  },
};
</script>