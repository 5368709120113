<template>
  <div class="flex flex-col gap-4">
    <RequestsFilters :cardsType="cardsType" @changeCardType="changeCardType" />
    <RequestsCards :cardsType="cardsType" />
  </div>
</template>


<script>
import RequestsFilters from "./RequestsFilters.vue";
import RequestsCards from "./RequestsCards.vue";
export default {
  components: {
    RequestsFilters,
    RequestsCards
  },
  data() {
    return {
      cardsType: "grid"
    };
  },
  methods: {
    changeCardType(t) {
      this.cardsType = t;
    }
  }
};
</script>