












import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    value: { default: "" },
    labelClass: { default: "" },
    isError: {
      type: Boolean,
      default: false,
    },
  },
})
export default class customLabel extends Vue {}
