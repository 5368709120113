<template>
  <div class="MyPropertiesContainer">
    <h4>{{ $t("profile.MyProperties") }}</h4>
    <v-row>
      <v-col sm="12" md="12">
        <myLeadsHeader
          @handleSort="(value) => (sortColumn = value)"
          :sortColumn="sortColumn"
          :sortList="sortList"
          :items="tableData"
          :title="title"
        />
      </v-col>
      <v-col cols="12" md="12">
        <custom-data-table
          :headers="headers"
          :items="displayedItems"
          :sortBy="sortColumn"
          :status="displayedItems.Status"
        ></custom-data-table>
        <v-pagination
          v-model="page"
          :length="Math.ceil(recordsCount / itemsPerPage)"
          @input="setPaginateItems"
          class="my-7"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import myLeadsHeader from "@/components/leadsComponent/myLeadsHeader.vue";
import CustomDataTable from "@/components/shared/CustomDataTable.vue";
export default {
  components: {
    myLeadsHeader,
    CustomDataTable,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      recordsCount: 0,
      itemsPerPage: 7,
      sortColumn: this.$t("dataTable.Date"),
      sortList: [
        this.$t("dataTable.Date"),
        this.$t("dataTable.Name"),
        this.$t("dataTable.Selling"),
      ],
      title: "Properties",
      headers: [
        {
          text: this.$i18n.locale == "ar" ? "اسم العقار" : "Property Name",
          value: "Name",
          align: "center",
        },
        {
          text: this.$i18n.locale == "ar" ? "سعر البيع" : "Selling Price",
          value: "Selling",
          align: "center",
        },
        {
          text: this.$i18n.locale == "ar" ? "نسبة العمولة" : "Commision Rate",
          value: "CommisionRate",
          align: "center",
        },
        {
          text:
            this.$i18n.locale == "ar" ? "هوية ضريبة البنية" : "CILs Statues",
          value: "CILsStatues",
          align: "center",
        },
        {
          text: this.$i18n.locale == "ar" ? "هوية العميل" : "Client ID",
          value: "Client",
          align: "center",
        },
        {
          text: this.$i18n.locale == "ar" ? "التاريخ" : "Date",
          value: "Date",
          align: "center",
        },
      ],
      tableData: [
        {
          id: 1,
          Name: "Renovated Apartment",
          img: "myProperties1",
          Client: "C-001",
          CommisionRate: "20%",
          Selling: "$20,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "Active",
        },
        {
          id: 2,
          Name: "Renovated Apartment",
          img: "myProperties2",
          Client: "C-002",
          CommisionRate: "3%",
          Selling: "$50,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "On Hold",
        },
        {
          id: 3,
          Name: "Renovated Apartment",
          img: "myProperties3",
          Client: "C-003",
          CommisionRate: "5%",
          Selling: "$80,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "Done",
        },
        {
          id: 4,
          Name: "Renovated Apartment",
          img: "myProperties4",
          Client: "C-004",
          CommisionRate: "16%",
          Selling: "$22,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "Done",
        },
        {
          id: 5,
          Name: "Renovated Apartment",
          img: "myProperties5",
          Client: "C-005",
          CommisionRate: "12%",
          Selling: "$60,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "On Hold",
        },
        {
          id: 6,
          Name: "Renovated Apartment",
          img: "myProperties6",
          Client: "C-006",
          CommisionRate: "25%",
          Selling: "$90,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "Done",
        },
        {
          id: 7,
          Name: "Renovated Apartment",
          img: "myProperties7",
          Client: "C-007",
          CommisionRate: "40%",
          Selling: "$32,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "Active",
        },
        {
          id: 8,
          Name: "Renovated Apartment",
          img: "myProperties1",
          Client: "C-008",
          CommisionRate: "20%",
          Selling: "$20,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "Active",
        },
        {
          id: 9,
          Name: "Renovated Apartment",
          img: "myProperties2",
          Client: "C-009",
          CommisionRate: "20%",
          Selling: "$20,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "Active",
        },
        {
          id: 10,
          Name: "Renovated Apartment",
          img: "myProperties3",
          Client: "C-010",
          CommisionRate: "20%",
          Selling: "$20,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "Active",
        },
        {
          id: 11,
          Name: "Renovated Apartment",
          img: "myProperties4",
          Client: "C-011",
          CommisionRate: "20%",
          Selling: "$20,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "Active",
        },
        {
          id: 12,
          Name: "Renovated Apartment",
          img: "myProperties5",
          Client: "C-012",
          CommisionRate: "20%",
          Selling: "$20,000",
          CILsStatues: "Dec 21, 2022",
          Date: "Jan 14, 2023",
          Status: "Active",
        },
      ],
      displayedItems: [],
      filteredItems: [],
    };
  },
  methods: {
    setPaginateItems(event) {
      if (event) {
        this.page = event;
      }
      this.displayedItems = this.filteredItems.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );
    },
  },
  mounted() {
    this.recordsCount = this.tableData.length;
    this.filteredItems = this.tableData;
    this.displayedItems = this.tableData.filter(
      (x) => x.id <= this.itemsPerPage
    );
  },
  computed: {},
};
</script>

<style lang="scss">
.MyPropertiesContainer {
  h4 {
    margin-bottom: 24px;
  }
}
.MyPropertiesContainer .v-data-table > .v-data-table__wrapper > table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

@media only screen and (max-width: 959px) {
  .MyPropertiesContainer {
    h4 {
      text-align: center;
      margin-top: 24px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .MyPropertiesContainer {
    h4 {
      text-align: center;
      margin-top: 24px;
    }
  }
}
</style>

