<template>
  <section class="w-full lg:w-4/5">
    <div class="mb-10 lg:mb-12 md:mt-6">
      <h2
        class="text-2xl text-text-dark mb-4"
        style="font-size: 1.5rem !important;"
      >{{$t('contact.ContactInformation')}}</h2>
      <p
        class="text-text-light"
      >{{$t('contact.getTouchMessage')}}</p>
    </div>
    <div>
      <ul class="list-none p-0 flex flex-col gap-5" style="padding: 0">
        <li class="flex items-start gap-2">
          <span
            class="w-12 h-12 flex items-center justify-center text-white bg-primary rounded-full flex-grow-0"
          >
            <ph-envelope-open :size="20" />
          </span>
          <div class="flex flex-col">
            <span class="text-sm">{{$t('contact.Email')}}:</span>
            <a href="mailto:example@mail.com">example@mail.com</a>
          </div>
        </li>

        <li class="flex items-start gap-2">
          <span
            class="w-12 h-12 flex items-center justify-center text-white bg-primary rounded-full flex-grow-0"
          >
            <PhPhoneCall :size="20" />
          </span>
          <div class="flex flex-col">
            <span class="text-sm">{{$t('contact.Phone')}}:</span>
            <a dir="ltr" href="tel:0045611227890">(00) 456 1122 7890</a>
          </div>
        </li>

        <li class="flex items-start gap-2">
          <span
            class="w-12 h-12 flex items-center justify-center text-white bg-primary rounded-full flex-grow-0"
          >
            <PhMapPin :size="20" />
          </span>
          <div class="flex flex-col">
            <span class="text-sm">{{$t('contact.Location')}}:</span>
            <span>{{$t('footer.footerLocation')}}</span>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { PhEnvelopeOpen, PhPhoneCall, PhMapPin } from "phosphor-vue";
export default {
  components: {
    PhEnvelopeOpen,
    PhPhoneCall,
    PhMapPin
  }
};
</script>