<template>
  <v-col class="profile-info-wrapper" cols="11" sm="11" md="8" xl="9">
    <v-row class="EditProfileSection">
      <v-col cols="12">
        <v-card-title class="text-h5">
          <span class="EditProfileSection__title">{{
            $t("profile.EditProfile")
          }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="EditProfileSection__information">
          <v-col cols="12">
            <v-list-item>
              <v-list-item-avatar size="100">
                <img src="@/assets/img/profileAvatar.png" alt="user" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="EditProfileSection__information__title"
                  >{{ $t("profile.UploadPicture") }}</v-list-item-title
                >
                <div class="EditProfileSection__information__sub">
                  <span>{{ $t("profile.UploadPictureText") }}</span>
                </div>
                <div class="EditProfileSection__information__buttons">
                  <CustomButton
                    class="uploadButton"
                    :classes="['customButton', 'uploadButton']"
                    :text="$t('profile.Upload')"
                  />
                  <CustomButton
                    :classes="['whiteBackground', 'outlined']"
                    :text="$t('profile.Remove')"
                  />
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12">
            <v-row class="EditProfileSection__information__details">
              <v-col cols="12" md="6">
                <CustomTextInput
                  placeholder="Ahmed"
                  class="customTextFieldInput"
                  :label="$t('profile.FirstName')"
                  :data="user.firstName"
                  @data-changed="(value) => (formData.firstName = value)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <CustomTextInput
                  placeholder="Mohamed"
                  class="customTextFieldInput"
                  :label="$t('profile.LastName')"
                  :data="user.lastName"
                  @data-changed="(value) => (formData.lastName = value)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <CustomTextInput
                  placeholder="ahmed@gmail.com"
                  class="customTextFieldInput"
                  :label="$t('profile.Email')"
                  :data="user.email"
                  @data-changed="(value) => (formData.email = value)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <CustomTextInput
                  placeholder="0122 568 5997"
                  class="customTextFieldInput"
                  :label="$t('profile.MobileNumber')"
                  :data="user.mobile"
                  @data-changed="(value) => (formData.mobile = value)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <CustomTextInput
                  placeholder="password"
                  :data="user.password"
                  :value="formData.password"
                  @data-changed="(value) => (formData.password = value)"
                  class="customTextFieldInput"
                  :label="$t('profile.Password')"
                  :type="showPass ? 'text' : 'password'"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :appendIconClick="showHidePassword"
                />
              </v-col>
              <v-col cols="12" md="6">
                <CustomSelect
                  :label="$t('profile.City')"
                  :items="cities"
                  :data="user.city"
                  :value="formData.city"
                  @data-changed="(value) => (formData.cities = value)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <CustomRadioButton
                  :label="$t('profile.Gender')"
                  :options="options"
                  :data="user.gender"
                  :value="formData.gender"
                  @data-changed="(value) => (formData.gender = value)"
                  groupClass=" border border-solid border-gray-300 "
                />
              </v-col>
              <v-col cols="12" md="6">
                <DatePick
                  :label="$t('profile.DateOFBirthe')"
                  :placeholder="$t('profile.selectDate')"
                  :date="user.dateOfBirth"
                  @data-changed="formData.dateOfBirth = $event"
                />
              </v-col>
              <v-col cols="12" md="6">
                <CustomSelect
                  :label="$t('profile.MaritalStatue')"
                  :items="MaritalStatue"
                  :data="user.MaritalStatue"
                  :value="formData.MaritalStatue"
                  @data-changed="(value) => (formData.MaritalStatue = value)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <CustomTextInput
                  placeholder="5"
                  class="customTextFieldInput"
                  :label="$t('profile.NumberOfChildern')"
                  :data="user.numberOfChilds"
                  :value="formData.numberOfChilds"
                  @data-changed="(value) => (formData.numberOfChilds = value)"
                />
              </v-col>
              <v-col cols="12">
                <CustomButton
                  class="uploadButton"
                  :classes="['customButton', 'uploadButton']"
                  :text="$t('profile.Save')"
                  :click="EditUserInfo"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import CustomButton from "../shared/Button/CustomButton.vue";
import CustomTextInput from "../shared/customTextInput/CustomTextInput.vue";
import CustomSelect from "../shared/customSelect/CustomSelect.vue";
import CustomRadioButton from "../shared/CustomRadioButton.vue";
import DatePick from "../shared/DatePicker.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CustomButton,
    CustomTextInput,
    CustomSelect,
    CustomRadioButton,
    DatePick,
  },
  props: ["defaultValue", "inline"],
  data() {
    return {
      modalVisible: false,
      formData: {
        firstName: 'Ahmed',
        lastName: 'Mohamed',
        mobile: '01225685997',
        email: 'ahmed@gmail.com',
        password: '•••••••••••',
        dateOfBirth: new Date(1995, 7, 17).toISOString().slice(0, 10),
        numberOfChilds: "5",
        gender:"Male",
        MaritalStatue: "Married",
        city: "Cairo",
      },
      cities: [
        {
          text: this.$t("profile.Cairo"),
          value: "Cairo",
        },
        {
          text: this.$t("profile.Alex"),
          value: "Alex",
        },
      ],
      MaritalStatue: [
        {
          text: this.$t("profile.Married"),
          value: "Married",
        },
        {
          text: this.$t("profile.Single"),
          value: "Single",
        },
      ],
      options: [
        {
          text: this.$t("profile.Male"),
          value: "Male",
        },
        {
          text: this.$t("profile.Female"),
          value: "Famale",
        },
      ],
      date: new Date(new Date()).toISOString().substr(0, 10),
      showPass: false,
    };
  },
  methods: {
    showHidePassword() {
      this.showPass = !this.showPass;
    },
    handleChangeDate(date) {
      this.date = date;
    },
    EditUserInfo() {
      if (this.formData != null) {
        this.$store.commit("updateUserInfo", this.formData);
        this.$router.push(`/realtor/profile/${this.$route.params.lang}`);
      }
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style lang="scss" scoped>
.EditProfileSection {
  padding: 0px;
  background: var(--white);
  border: 1px solid rgba(232, 233, 234, 0.4);
  box-shadow: 0px 4px 14px rgba(233, 233, 233, 0.25);
  border-radius: 16px;
  margin-bottom: 24px;
  &__title {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 30px;
    color: var(--dark-primary);
  }
  &__icon {
    .v-btn--icon.v-size--default {
      height: 17px;
      width: 18px;
      margin-inline: 9px;
    }
    .theme--light.v-btn.v-btn--icon {
      color: transparent;
    }
    .theme--light.v-btn :hover {
      color: transparent;
    }
  }
  &__EditAncortitle {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 22px;
    color: var(--primary);
  }
  &__information {
    margin-top: 12px;
    &__title {
      font-family: var(--font-en);
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 24px;
      color: var(--dark-primary);
    }
    &__sub {
      max-width: 301px;
      height: 45px;
      span {
        font-family: var(--font-en);
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 22px;
        color: var(--text-mute);
      }
    }
    &__buttons {
      display: flex;
      margin-top: 10px;
      .customButton[data-v-327f3426] .v-btn.v-size--default {
        padding: 7px 24px;
        min-width: 97px !important;
      }
    }
    &__details {
      padding-inline: 32px;
      margin-top: 12px;
      margin-bottom: 4px;
      label {
        font-family: var(--font-en);
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 20px;
        color: var(--text-mute);
      }
      p {
        font-family: var(--font-en);
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 22px;
        color: var(--text-color-04);
      }
    }
  }
  .v-btn__content {
    width: 16px;
    height: 16px;
    margin-right: 15.67px;
    i {
      color: var(--primary);
    }
  }
}

@media only screen and (max-width: 599px) {
  .profile-info-wrapper {
    margin: auto;
    padding-top: 20px !important;
  }
  .EditProfileSection {
    &__information {
      text-align: center;

      &__details {
        text-align: center;
      }
      &__sub {
        text-align: center;
        margin: auto;
      }
      .v-list-item {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        min-height: 1px;
      }
    }
  }
}
@media only screen and (max-width: 959px) {
  .profile-info-wrapper {
    margin: auto;
    padding-top: 20px !important;
  }
  .EditProfileSection__information {
    &__details {
      text-align: center;
    }
    &__sub {
      text-align: center;
      margin: auto;
    }
    &__buttons {
      text-align: center;
      justify-content: center;
    }
    .v-list-item {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      min-height: 1px;
    }
  }
}
</style>