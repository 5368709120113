<template>
  <div class="customFilter">
    <div
      class="
        flex flex-col
        md:flex-row
        flex-wrap
        items-center
        justify-between
        p-4
        md:p-5 md:px-7
        gap-5
        lg:gap-7
      "
    >
      <!-- location  -->
      <div>
        <LocationDropdown
          icon="location"
          :label="$t('home.location')"
          :options="items"
          @data-changed="(value) => (HomeFilterParams.params.location = value)"
          :data="HomeFilterParams.params.location"
        />
      </div>
      <div
        class="divider w-full max-w-xs h-[1px] md:w-[1px] md:h-12 bg-gray-200"
      ></div>
      <div>
        <custom-dropdown
          icon="category"
          :label="$t('home.category')"
          :options="propertyCategories"
          @data-changed="(value) => (HomeFilterParams.params.category = value)"
          :data="HomeFilterParams.params.category"
        />
      </div>
      <div
        class="divider w-full max-w-xs h-[1px] md:w-[1px] md:h-12 bg-gray-200"
      ></div>
      <div>
        <price-filter
          :activePrice="priceActive"
          @priceIsActive="
            (event) => {
              $emit('priceIsActive', event);
            }
          "
          :value="priceValue"
          :label="$t('home.priceRange')"
          icon="price"
        />
      </div>
      <div
        class="divider w-full max-w-xs h-[1px] md:w-[1px] md:h-12 bg-gray-200"
      ></div>
      <div>
        <v-btn color="primary" class="px-3 py-6" @click="search">
          <img
            alt="search"
            class
            contain
            :src="require(`@/assets/img/ico/search.svg`)"
          />
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script >
import PriceFilter from "./PriceFilter.vue";
import CustomDropdown from "./CustomDropdown.vue";
import LocationDropdown from "./LocationDropdown.vue";

export default {
  components: { CustomDropdown, LocationDropdown, PriceFilter },
  name: "CustomFilter",
  props: ["priceValue", "priceActive"],
  data() {
    return {
      items: ["Cairo", "Alex"],
      propertyCategories: [
        "1 Star",
        "2 Stars",
        "3 Stars",
        "4 Stars",
        "5 Stars",
      ],
      HomeFilterParams: {
        searchTitle: "",
        params: {
          category: "5 Stars",
          location: "Cairo",
          priceRange: this.priceValue,
          bedsRange: [0, 10],
          bathsRange: [0, 10],
          areaRange: [0, 1000],
        },
      },
    };
  },
  watch: {
    priceValue: function (newVal) {
      return (this.HomeFilterParams.params.priceRange = newVal);
    },
  },
  methods: {
    search() {
      const HomeFilterParams = this.HomeFilterParams;
      this.$store.commit("appliedSearchParams", HomeFilterParams);
      this.$router.push({ path: `/properties/${this.$i18n.locale}` });
    },
  },
};
</script>

<style scoped lang="scss">
.filterContainer {
  position: relative;
  width: fit-content;
  margin: auto;
}

.customFilter > .row {
  width: 60vw;
  padding: 20px;
}

.colsContainer {
  border-right: 2px solid var(--border-color-02);
  margin-block: 5px;
  display: flex;
  justify-content: center;
}
.colsContainer:last-child {
  border: none;
}
[dir="rtl"] {
  .colsContainer:first-child {
    border: none;
  }
}
@media only screen and (max-width: 600px) {
  .colsContainer {
    border-bottom: 2px solid var(--border-color-02);
    border-right: 0;
  }
  .customFilter > .row {
    width: 100%;
    padding: 20px;
  }
  .filterContainer {
    margin: 0;
  }
}
</style>
<style>
.customTabs > .v-tabs > .v-tabs-bar {
  background: transparent !important;
}
.customTabs .v-tabs-items {
  border-radius: 8px !important;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
