<template>
  <div class="text-center notifications">
    <v-menu
      v-model="isMenuOpened"
      :close-on-content-click="false"
      :nudge-width="310"
      :max-width="310"
      :nudge-right="270"
      offset-y
      eager
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          top
          left
          overlap
          :offset-x="10"
          color="error"
          dot
          :value="unreadedMessages"
        >
          <v-btn
            text
            class="!p-0 !h-fit !min-w-fit !m-0"
            v-bind="attrs"
            v-on="on"
          >
            <v-img
              alt="Notification"
              class="shrink mr-1 ml-1"
              contain
              :src="require(`@/assets/img/ico/Notification.svg`)"
            />
          </v-btn>
        </v-badge>
      </template>

      <v-card class="notifications__card">
        <div class="notifications__header d-flex justify-space-between">
          <p class="notifications__title mb-0">
            {{ $t("notifications.notifications") }}
          </p>
          <router-link
            :to="`/notifications/${this.$route.params.lang}`"
            class="notifications__link"
          >
            {{ $t("notifications.viewMore") }}
          </router-link>
        </div>

        <v-divider></v-divider>

        <notifications-list />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import NotificationsList from "./NotificationsList.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    NotificationsList,
  },
  data: () => ({
    isMenuOpened: false,
  }),
  computed: {
    ...mapGetters("notifications", ["notifications"]),
    unreadedMessages() {
      return (
        this.notifications?.filter((notification) => !notification.read_at)
          .length || []
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  &__header {
    padding: 16px;
  }

  &__card {
    overflow: hidden;
  }

  &__title {
    font-weight: 700;
    color: var(--text-color-01);
  }

  &__link {
    text-decoration: none;
    font-weight: 700;
    font-size: 0.875rem;
  }
}
</style>