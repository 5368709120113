<template>
  <div class="notifications">
    <CustomHeader headerColor="primary" />
    <v-container class="notifications__Container rounded-xl">
      <div class="notifications__Container__card">
        <div class="rounded-xl bg-white">
          <h5 class="py-5 px-7">{{ $t("notifications.notifications") }}</h5>
          <v-divider class="w-full"></v-divider>
          <div class="p-5">
            <NotificationsList :fromPage="true" />
          </div>
        </div>
      </div>
    </v-container>
    <CustomFooter />
  </div>
</template>

<script>
import NotificationsList from "@/components/notificationsComponents/NotificationsList.vue";
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";

export default {
  components: {
    NotificationsList,
    CustomHeader,
    CustomFooter,
  },
};
</script>
<style lang="scss" scoped>
.notifications {
  background: var(--light-gray-bg-01);
  &__Container {
    padding: 0 !important;
    &__card {
      margin: 100px;
      margin-top: 0;
      padding-top: 100px;
      @media only screen and (max-width: 991.98px) {
        margin: 20px;
      }
    }
  }
}
</style>