<template>
  <div class="unitCard">
    <v-row>
      <v-col
        cols="12"
        :md="imgmdCols"
        sm="12"
        xs="12"
        style="position: relative"
        class="text-center"
      >
        <slider-gallery
          v-if="card && card.cardImage"
          :options="card.cardImage"
        />
      </v-col>
    </v-row>

    <!-- details -->
    <v-row>
      <v-col
        cols="12"
        :md="detailsmdCols"
        sm="12"
        xs="12"
      >
        <div
          class="details"
          :class="contentStyle"
        >
          <div
            :class="$i18n.locale == 'ar' ? 'priceTagAR' : 'priceTag'"
            v-if="card.priceOffer != null"
          >
            <img
              alt="img"
              width="68"
              class="fill-height"
              contain
              :src="require(`@/assets/img/priceShape.png`)"
            />
            <span>{{ card.priceOffer }}% off</span>
          </div>
          <div
            v-if="card.isFav != null"
            class="fav"
            :class="[
              favIconStyle,
              $i18n.locale == 'ar' ? ' left-0' : 'sm:right-0',
            ]"
          >
            <v-icon
              @click="setFavorite({ id: card.id, isFav: true })"
              v-if="!card.isFav"
              class="mr-2"
              color="primary"
            >mdi-heart-outline</v-icon>
            <v-icon
              @click="setFavorite({ id: card.id, isFav: false })"
              v-if="card.isFav"
              class="mr-2"
              color="red"
            >mdi-heart</v-icon>
            <span>{{ $t("unit.addWishList") }}</span>
          </div>

          <h4
            class="my-2"
            :class="titleStyle"
          >
            {{
              card && card.title
                ? $i18n.locale == "ar"
                  ? card.title.ar
                  : card.title.en
                : ""
            }}
          </h4>
          <div>
            <div
              class="flex specDetails"
              :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
            >
              <img
                alt="location"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                class="shrink"
                contain
                :src="require(`@/assets/img/ico/locationGray.svg`)"
              />
              {{ card.location }}
            </div>
          </div>
          <div
            class="flex flex-wrap gap-4 mt-5 mb-5"
            :class="specContainer"
          >
            <div
              v-for="(spec, index) in card.specs"
              :key="spec + card.id + '' + index"
              class="specDetails"
            >
              <img
                alt="spec"
                class="shrink"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                contain
                :src="require(`@/assets/img/ico/${spec.icon}.svg`)"
              />
              {{ spec.info.value }}
              {{ spec.info.key != "" ? $t("home." + spec.info.key) : "" }}
            </div>
          </div>
          <div class="descContainer">
            <p>{{ card.description }}</p>
          </div>
          <v-row
            no-gutters
            class
          >
            <v-col
              cols="12"
              md="12"
              sm="6"
              xs="12"
              :class="$i18n.locale == 'ar' ? '' : ''"
            >
              <div
                class="flex"
                v-if="card.category"
              >
                <span :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'">{{ $t("home.category") }} :</span>
                <p class="d-inline">{{ card.category }}</p>
              </div>
              <div
                class="flex"
                v-if="card.view"
              >
                <span :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'">{{ $t("properties.view") }}:</span>
                <p class="d-inline">{{ card.view }}</p>
              </div>
              <div
                class="flex"
                v-if="card.floor"
              >
                <span :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'">{{ $t("unit.floor") }}:</span>
                <p class="d-inline">{{ card.floor }}</p>
              </div>
              <div
                class="flex"
                v-if="card.startingPrice"
              >
                <span
                  class="align-self-center"
                  :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
                >{{ $t("projects.StartingPrice") }}:</span>
                <h5 class="d-inline">{{ card.startingPrice }}</h5>
              </div>
              <div
                class="flex"
                v-if="card.pahse"
              >
                <span
                  class="align-self-center my-4"
                  :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
                >{{ card.pahse }}</span>
              </div>
              <div
                class="flex"
                v-if="card.downPayment"
              >
                <span
                  class="text-secondary"
                  :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
                >{{ $t("projects.Downpayment") }}
                  {{ card.downPayment.value }} {{ $t("projects.over") }}
                  {{ card.downPayment.duration }}
                </span>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="6"
              xs="12"
              class="priceDetails"
              :class="
                $i18n.locale == 'ar'
                  ? 'mr-auto priceDetailsAr'
                  : 'ml-auto priceDetailsEn'
              "
              v-if="card.price"
            >
              <h5 :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'">
                ${{
                  card.priceOffer
                    ? card.price - card.price * (card.priceOffer / 100)
                    : card.price
                }}
              </h5>
              <h6 v-if="card.priceOffer">${{ card.price }}</h6>
            </v-col>

            <div
              v-if="card.socialLinks"
              class="social-media-icons"
              :class="$i18n.locale == 'ar' ? 'socialMediaAR' : 'socialMediaEN'"
            >
              <p class="d-inline-flex mx-2">{{ $t("projects.Share") }}</p>
              <v-hover
                v-for="link in card.socialLinks"
                :key="link.type"
              >
                <ShareNetwork
                  :network="`${link.type}`"
                  :url="urlPath"
                  :title="$i18n.locale == 'ar' ? card.title.ar : card.title.en"
                  :description="card.description"
                  hashtags="ZRealtors,real_state"
                >
                  <v-btn
                    :elevation="0"
                    fab
                    class="mr-2"
                    :color="link.color"
                    small
                  >
                    <img
                      :src="require(`@/assets/img/ico/${link.type}.svg`)"
                      alt="soacial"
                      width="15"
                      height="15"
                    />
                  </v-btn>
                </ShareNetwork>
              </v-hover>
            </div>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <custom-modal
      @close="modalVisible = false"
      @confirm="addToWishList"
      :isVisible="modalVisible"
      :modalTitle="$t('modal.addWishList')"
      :okBtn="$t('modal.save')"
      :cancelBtn="$t('modal.cancel')"
    >
      <div class="px-5 py-2">
        <custom-text-input
          class="mb-3"
          :label="$t('modal.enterNewName')"
          :placeholder="$t('modal.newWishList')"
          :isDense="true"
          @data-changed="(value) => (wishlistName = value)"
        />

        <v-divider></v-divider>
        <span class="dividerSpan text-gray-400">{{ $t("modal.or") }}</span>

        <custom-label
          class="text-sm text-gray-500 mt-3"
          :value="$t('modal.selectFromList')"
        ></custom-label>

        <div
          class="
            border-2
            rounded-md
            border-gray-200 border-solid
            px-3
            m-2
            max-h-60
            overflow-y-scroll
          "
          id="modalContent"
        >
          <v-radio-group
            v-model="wishlist"
            column
          >
            <v-radio
              v-for="item in wishlists"
              :key="item.title"
              :label="item.title"
              color="primary"
              class="text-sm"
              active-class="activeRadio"
              :value="item"
            ></v-radio>
          </v-radio-group>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script >
import SliderGallery from "../shared/SliderGallery.vue";
import CustomModal from "@/components/shared/CustomModal.vue";
import CustomTextInput from "@/components/shared/customTextInput/CustomTextInput.vue";
import CustomLabel from "@/components/shared/customLabel/CustomLabel.vue";
import { mapGetters } from "vuex";

export default {
  name: "UnitCard",
  props: [
    "card",
    "titleStyle",
    "contentStyle",
    "premiumStyle",
    "imgmdCols",
    "detailsmdCols",
    "favIconStyle",
    "specContainer",
  ],
  components: {
    SliderGallery,
    CustomModal,
    CustomTextInput,
    CustomLabel,
  },
  data() {
    return {
      isFavorite: false,
      currentIndex: 0,
      imageArray: [],
      imagesPerPage: 9,
      paginationNum: 0,
      wishlist: {},
      cardStatus: {},
      wishlistName: "",
      modalVisible: false,
    };
  },
  mounted() {
    this.imageArray = this.card.cardImage;
  },
  computed: {
    ...mapGetters(["wishlists"]),
    urlPath() {
      return `${window.location.origin}${this.$route.fullPath}`;
    },
  },
  methods: {
    setFavorite(event) {
      this.modalVisible = event.isFav;
      this.cardStatus = event;

      if (!event.isFav) this.$store.commit("updateWishList", event);
    },
    addToWishList() {
      if (this.wishlistName != "") {
        this.$store.commit("insertWishList", this.wishlistName);
        this.cardStatus.wishlistId =
          this.wishlists[this.wishlists.length - 1].id;
      } else {
        this.cardStatus.wishlistId = this.wishlist.id;
      }
      this.$store.commit("updateWishList", this.cardStatus);

      this.modalVisible = false;
    },

    pageChange(i) {
      this.paginationNum = Math.trunc((i + 1) / this.imagesPerPage);
      this.$refs.thumbnailCarousel.goToPage(this.paginationNum);
    },
  },
};
</script>
<style scoped lang="scss">
.unitCard {
  background: white;
  border-radius: 15px;
  padding: 30px;
}
.shadowStyle {
  box-shadow: 20px -20px lightgray;
  margin-top: 20px;
}
.social-media-icons {
  position: absolute;
  display: flex;
  align-items: baseline;
}
.socialMediaAR {
  left: 26px;
  bottom: 20px;
}
.socialMediaEN {
  right: 26px;
  bottom: 20px;
}
.details {
  position: relative;
  height: 100%;
}
.details h4 {
  font-weight: bold;
  color: var(--primary);
}
.details p {
  font-weight: normal;
  color: gray;
  line-height: 28px;
}
.specDetails {
  color: gray;
  display: inline-flex;
  align-self: center;
}
.priceDetails {
  width: fit-content;
  margin-top: auto;
}
.priceDetails h5 {
  display: inline-flex;
}
.priceDetails h6 {
  display: inline-flex;
  color: gray;
  text-decoration: line-through;
}
.fav {
  position: absolute;
  top: 5px;
  cursor: pointer;
}
.unitCard .row {
  width: 100%;
  margin: 0;
}

.titleStyle {
  padding-block: 10px !important;
}
.priceTag {
  position: absolute;
  right: calc(-16px - 30px);
  top: 17%;
}
.priceTag span {
  position: absolute;
  top: 10%;
  left: 10%;
  font-size: small;
  color: white;
}
.priceTagAR {
  position: absolute;
  left: -16px;
  top: 17%;
}
.priceTagAR span {
  position: absolute;
  top: 10%;
  right: 10%;
  font-size: small;
  color: white;
  text-align: left;
}
.priceTagAR img {
  transform: scaleX(-1);
}

.descContainer {
  font-size: small;
  margin: 35px 0;
}
.fav span {
  color: var(--primary);
}
.priceDetailsAr {
  text-align: left;
}
.priceDetailsEn {
  text-align: right;
}

@media only screen and (max-width: 960px) {
  .priceDetailsAr {
    text-align: left;
  }
  .priceDetailsEn {
    text-align: right;
  }
  .priceTagAR {
    left: -7%;
  }
}

@media only screen and (max-width: 600px) {
  .propertiesContainer .unitCard {
    margin-top: 50px;
  }
  .priceTag {
    top: 86%;
    right: -4%;
  }
  .priceTagAR {
    top: 86%;
    left: -4%;
  }
  .priceDetailsAr {
    text-align: right;
  }
  .priceDetailsEn {
    text-align: right;
  }
  .unitCard {
    padding: 0px;
  }
}
</style>
