<template>
  <div class="featuredcustomTabs">
    <v-tabs v-model="active" centered>
      <v-tab
        class="rounded-tl"
        :class="$i18n.locale == 'ar' ? 'tabClassAR' : 'tabClass'"
      >
        <v-btn
          class="btnTab"
          :class="active == 0 ? 'activeTab' : 'inactiveTab'"
        >
          {{ $t("properties.Residential") }}
        </v-btn>
      </v-tab>
      <v-tab
        class="rounded-tr"
        :class="$i18n.locale == 'ar' ? 'tabClassAR' : 'tabClass'"
      >
        <v-btn
          class="btnTab"
          :class="active == 1 ? 'activeTab' : 'inactiveTab'"
        >
          {{ $t("properties.Commercial") }}
        </v-btn>
      </v-tab>
      <v-tab
        class="rounded-tr"
        :class="$i18n.locale == 'ar' ? 'tabClassAR' : 'tabClass'"
      >
        <v-btn
          class="btnTab"
          :class="active == 2 ? 'activeTab' : 'inactiveTab'"
        >
          {{ $t("properties.Administrative") }}
        </v-btn>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script >
export default {
  name: "CustomTabs",
  components: {},
  props: ['activeTab'],
  
  data() {
    return {
    };
  },
  computed: {
    active: {
      get () { return this.activeTab },
      set (activeTab) { this.$emit('input', activeTab); },
    },
  }
};
</script>

<style scoped lang="scss">
.tabClass {
  background: var(--light-gray-01);
  margin-left: 0 !important;
}
.tabClass:last-child {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.tabClassAR {
  background: var(--light-gray-01);
  margin-right: 0 !important;
}
.tabClassAR:last-child {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.activeTab {
  background-color: var(--primary) !important;
  color: var(--white);
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
}
.activeTab span {
  color: white;
  font-weight: bold;
  vertical-align: middle;
}
.featuredcustomTabs .v-tabs {
  background-color: transparent !important;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
}
.btnTab {
  border-radius: 7px;
  width: 100%;
}
.inactiveTab {
  background-color: transparent !important;
  opacity: 1;
  color: var(--dark-primary) !important;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
}
.inactiveTab span {
  color: var(--dark-primary) !important;
  font-weight: bold;
  vertical-align: middle;
}

.v-btn {
  box-shadow: none;
}
</style>
<style>
.v-tabs-slider-wrapper {
  display: none;
}
.featuredcustomTabs .v-tabs > .v-tabs-bar {
  background: transparent;
}

@media only screen and (max-width: 600px) {
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
    > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
    .v-slide-group__prev {
    display: none;
  }

  .v-slide-group__content {
    width: 100%;
  }

  .v-slide-group__content {
    width: 100%;
  }
  .activeTab {
    width: 50%;
  }

  .inactiveTab {
    width: 50%;
  }

  .featuredcustomTabs .v-tabs .v-tab--active:hover::before,
  .theme--light.v-tabs .v-tab--active::before {
    opacity: 1;
  }
  .featuredcustomTabs .v-btn:hover::before {
    opacity: 0;
  }
}
</style>
