<template>
  <div class="unitContainer">
    <CustomHeader headerColor="primary" />
    <v-container>
      <div class="mt-14 md:mt-16"></div>
      <section class="container md:pt-5">
        <BreadCrumb :breadcrumbs="breadcrumbs" />
      </section>

      <v-row class="mt-3">
        <v-col
          cols="12"
          md="9"
          class
          :class="$i18n.locale=='ar'? '' : ''"
        >
          <unit-card
            @addWishList="modalVisible=true"
            :imgmdCols="12"
            :detailsmdCols="12"
            :card="sliderCard"
            contentStyle="contentStyle"
            premiumStyle="premiumStyle"
            favIconStyle="favIconClass"
          />
          <img
            width="100%"
            class="mt-7"
            :src="require(`@/assets/img/ads919.png`)"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="text-center"
        >
          <div class="buttonsContainer">
            <v-btn
              class="mb-5 search"
              width="100%"
              @click="() => this.$router.push({ path: `/properties/${this.$route.params.lang}` })"
            >
              {{$t('properties.advancedSearch')}}
              <v-icon>{{$i18n.locale == 'en' ? 'mdi-chevron-right' : 'mdi-chevron-left'}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              width="100%"
              @click="openReqMeetingDialog"
            >{{$t('unit.registerInterest')}}</v-btn>
          </div>
          <!-- mobile add  -->
          <div class="md:hidden mt-7">
            <img
              width="100%"
              :src="require(`@/assets/img/ads919.png`)"
            />
            <img
              width="100%"
              :src="require(`@/assets/img/ads919.png`)"
            />
          </div>
          <!-- big screens  -->
          <div class="hidden md:block mt-7">
            <img
              width="100%"
              :src="require(`@/assets/img/ads293.png`)"
            />
            <img
              width="100%"
              :src="require(`@/assets/img/ads293.png`)"
            />
          </div>
        </v-col>
      </v-row>
      <div class="DetailsSlider my-5 md:mx-9">
        <h3 class="my-md-5 my-sm-3 unitTitle text-center">{{$t('unit.otherPropertiesFrom')}} ({{project}})</h3>
        <carousel
          style="direction:ltr !important"
          id="unitsCarousel"
          :loop="true"
          :perPageCustom="[[0,1],[600,2],[960,4]]"
          :paginationEnabled="false"
          :autoplay="false"
          :centerMode="true"
          :navigationEnabled="true"
          navigationPrevLabel
          navigationNextLabel
        >
          <slide
            class="px-2"
            id="unitsSlides"
            v-for="(card,index) in displayedCards"
            :key="'unitDetailsView' + index"
          >
            <featured-card
              :imgmdCols="12"
              :detailsmdCols="12"
              :card="card"
              titleStyle="FeaturedTitleStyle"
              contentStyle="contentStyle"
              favIconStyle="favIconStyle"
              specContainer="specContainer"
            />
          </slide>
        </carousel>
      </div>
    </v-container>
    <CustomFooter />
    <v-dialog
      v-model="reqMeetingDialog"
      width="80%"
      max-width="1000"
      persistent
    >
      <ReqMeetingDialog @closeDialog="closeReqMeetingDialog" />
    </v-dialog>
  </div>
</template>


<style scoped>
.buttonsContainer {
  width: 100;
}
.unitContainer {
  background: var(--light-gray-bg-01);
}

.back {
  text-decoration: none;
  color: var(--text-color-01);
}
.text {
  color: var(--text-color-02);
}
.buttonsContainer .search {
  background: var(--bar-header-bg);
  text-transform: capitalize;
}
.buttonsContainer .v-btn {
  box-shadow: none;
}
.unitTitle {
  color: var(--primary);
}
.dividerSpan {
  text-align: center;
  width: 50px;
  background: white;
  margin: auto;
  margin-top: -13px;
  display: block;
}
</style>
<style lang="scss" src="@\assets\styles\common.scss"></style>
<script >
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import UnitCard from "@/components/unitComponents/UnitCard.vue";
import FeaturedCard from "@/components/homeComponents/featuredCards/FeaturedCard.vue";
import { Carousel, Slide } from "vue-carousel";
import ReqMeetingDialog from "@/components/unitComponents/ReqMeetingDialog.vue";
import BreadCrumb from "@/components/shared/breadcrumb/BreadCrumb.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CustomHeader,
    CustomFooter,
    UnitCard,
    FeaturedCard,
    Carousel,
    Slide,
    ReqMeetingDialog,
    BreadCrumb
  },
  computed: {
    ...mapGetters(["cards"]),
    displayedCards() {
      let items = this.cards.map(item => {
        let copyedItem = { ...item };
        copyedItem.priceOffer = null;
        copyedItem.buttonTitle = null;
        return copyedItem;
      });
      return items.filter(item => item.id < 6)
    },
    sliderCard() {
      let tempCard = { ...this.cards.find(item => item.id == this.cardId) };
      if (tempCard) {
        tempCard.cardImage = this.cardImage;
        tempCard.description = this.detailedDiscription;
        tempCard.specs = this.specs;
        tempCard.category = "Commercial";
        tempCard.view = "Pool";
        tempCard.floor = "Ground Floor";
      }
      return tempCard
    }

  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t("shared.home"),
          slug: `/${this.$route.params.lang}`
        },
        {
          title: this.$t("unit.unitDetails")
        }
      ],
      cardId: -1,
      project: "Project Name",
      detailedDiscription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting ever dummy text of the printing industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting industry Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the simply standard dummy text ever dummy text of the printing and typesetting ever dummy text of the printing industry.`,
      cardImage: [
        "room1.png",
        "room3.webp",
        "room3.webp",
        "room4.webp",
        "room5.jpg",
        "room6.jpg",
        "room7.jpg",
        "room10.webp",
        "room11.jpeg",
        "room12.jpg",
        "room13.jpg",
        "room14.jpg"
      ],
      specs: [
        { icon: "bed", info: { value: "4", key: "beds" } },
        { icon: "bath", info: { value: "2", key: "baths" } },
        { icon: "squareft", info: { value: "2500", key: "sqft" } },
        { icon: "wifi", info: { value: "Wifi", key: "" } }
      ],
      reqMeetingDialog: false,
    };
  },
  methods: {
    closeReqMeetingDialog() {
      this.reqMeetingDialog = false;
    },
    openReqMeetingDialog() {
      this.reqMeetingDialog = true;
    }
  },
  mounted() {
    this.cardId = this.$route.params.unit;
  }
};
</script>