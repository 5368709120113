<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      content-class="rounded-xl"
      :persistent="persistent"
    >
      <v-card class="px-7 pb-3">
        <v-card-title class="px-0">
          <span class="text-sm font-bold py-2 text-primary">
            {{modalTitle}}
          </span>
          <v-btn
            @click="$emit('close')"
            :class="$i18n.locale=='ar'?'mr-auto':'ml-auto'"
            icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          {{modalText}}
        </v-card-text>

        <slot></slot>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            small
            min-width="80"
            @click="$emit('close')"
          >
            {{cancelBtn}}
          </v-btn>
          <v-btn
            small
            min-width="80"
            color="primary"
            depressed
            @click="$emit('confirm')"
          >
            {{okBtn}}
          </v-btn>

        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  components: {},
  props: ['modalTitle', 'isVisible', 'okBtn', 'cancelBtn', 'modalText', 'persistent'],
  data() {
    return {
    }
  },
  methods: {

  },
  computed: {
    dialog: {
      get() { return this.isVisible },
      set(isVisible) { this.$emit('input', isVisible) },
    },
  }
};
</script>