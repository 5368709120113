<template>
  <div class="sliderGallery">
    <carousel
      v-model="currentIndex"
      v-on:pageChange="pageChange"
      paginationPosition="bottom-overlay"
      class="sliderClass"
      style="direction: ltr"
      :loop="true"
      :perPageCustom="[
        [0, 1],
        [600, 1],
        [960, 1],
      ]"
      :paginationEnabled="false"
      :autoplay="false"
      :mouse-drag="options.length > 1 ? true : false"
      :centerMode="true"
      :navigationEnabled="true"
      navigationNextLabel=">"
      navigationPrevLabel="<"
    >
      <slide
        v-for="(image, index) in options"
        :key="'carousel' + index + image"
        class="text-center"
      >
        <img
          alt="img"
          style="width: 100%; height: 466px"
          contain
          class="shrink cardImgSlide"
          :src="getImageURL(image)"
        />
      </slide>
    </carousel>
    <carousel
      ref="thumbnailCarousel"
      paginationPosition="bottom-overlay"
      class="thumbnailClass"
      style="direction: ltr"
      :loop="true"
      :perPageCustom="imagesPerPage"
      :paginationEnabled="false"
      :centerMode="true"
      :autoplay="false"
    >
      <slide
        v-for="(image, index) in options"
        :key="'thumbnail' + index + image"
        class="text-center"
      >
        <img
          @click="currentIndex = index"
          alt="img"
          style="width: 75px; height: 60px"
          class="fill-height shrink thumbnailImgSlide"
          :class="currentIndex == index ? 'activeImg' : 'inActiveImg'"
          :src="getImageURL(image)"
        />
      </slide>
    </carousel>
  </div>
</template>

<script >
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "sliderGallery",
  props: ["options", "selectedIndex"],
  components: {
    Carousel,
    Slide,
  },
  watch: {
    selectedIndex(newValue) {
      if (this.currentIndex != newValue) {
        this.currentIndex = newValue;
        this.pageChange(newValue);
      }
    },
  },
  data() {
    return {
      currentIndex: 0,
      imageArray: [],
      imagesPerPage: [
        [960, 9],
        [600, 6],
        [0, 4],
      ],
      paginationNum: 0,
    };
  },
  methods: {
    getImageURL(image) {
      if (image.includes("http://") || image.includes("https://")) {
        return image;
      } else {
        return require(`@/assets/img/${image}`);
      }
    },

    pageChange(i) {
      let perPage = this.imagesPerPage[0];
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          perPage = this.imagesPerPage[2];
          break;
        }
        case "sm": {
          perPage = this.imagesPerPage[1];
          break;
        }
        case "md": {
          perPage = this.imagesPerPage[0];
          break;
        }
        default: {
          perPage = this.imagesPerPage[0];
          break;
        }
      }
      this.paginationNum = Math.trunc(i / perPage[1]);
      this.$refs.thumbnailCarousel.goToPage(this.paginationNum);
    },
  },
};
</script>
<style scoped lang="scss">
.thumbnailClass {
  position: absolute !important;
  bottom: 0;
  height: fit-content !important;
  height: 25% !important;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(22, 85, 105, 0.0225) 0%,
    rgba(22, 95, 118, 0.75) 100%
  );
  left: 0;
  padding-inline: calc(5% - 12px);
  margin: 0 12px 17px 12px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.sliderGallery {
  background: white;
  border-radius: 15px;
}

.sliderGallery .row {
  width: 100%;
  margin: 0;
}

.activeImg {
  opacity: 1 !important;
  margin-top: 38% !important;
  transform: scale(1.1);
  margin-right: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-left: 5px;
  border: 1.7px solid white;
  padding: 0;
  cursor: pointer;
}
.inActiveImg {
  opacity: 0.55 !important;
  margin-top: 40% !important;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

@media only screen and (max-width: 600px) {
  .sliderGallery {
    margin-top: 20px;
  }
  .activeImg {
    margin-top: 10% !important;
  }
  .inActiveImg {
    margin-top: 10% !important;
  }
}
</style>
<style  lang="scss" src="@\assets\styles\common.scss"></style>
