<template>
  <v-row class="SavedSearch-Section">
    <v-col cols="12">
      <v-card-title class="text-h5">
        <span class="SavedSearch-Section__title">{{
            $t("profile.SavedSearch")
        }}</span>
      </v-card-title>
      <v-divider></v-divider>

      <div class="search-Section-wrapper">
        <v-row class="search-Section-wrapper__row">
          <v-col
            class="search-Section-wrapper__labels"
            cols="12"
          >
            <v-card-title>
              <p>{{ $t("profile.SearchName") }}</p>

              <v-spacer></v-spacer>

              <p class="ma-auto">{{ $t("profile.Action") }}</p>
            </v-card-title>
          </v-col>

          <v-col
            v-for="search in savedSearchList"
            :key="search.searchTitle"
            class="search-Section-wrapper__row__singleSection"
            cols="12"
          >
            <div class="search-Section-wrapper__row__singleSection__details">
              <div>
                <p>{{ search.searchTitle }}</p>
              </div>
              <div class="
                  search-Section-wrapper__row__singleSection__details__Actions
                ">
                <button
                  class="applyAction"
                  @click="applySearch(search)"
                >
                  {{ $t("profile.ApplySearch") }}
                </button>
                <button @click="confirmDelete()">
                  <img
                    src="@/assets/img/ico/deleteIco.svg"
                    alt="user"
                  />
                </button>
              </div>
            </div>
            <custom-modal
              @close="confirmDeleteModel = false"
              @confirm="CommitDelete(search)"
              :isVisible="confirmDeleteModel"
              :modalTitle="$t('modal.confirmMessage')"
              :okBtn="$t('modal.delete')"
              :cancelBtn="$t('modal.cancel')"
            >

            </custom-modal>
          </v-col>

        </v-row>
        <div v-if="!savedSearchList || savedSearchList.length == 0">
          <p class="text-center pt-7 mb-0 text-gray-400 text-sm">{{ $t("profile.emptySavedSearch") }}</p>
        </div>
      </div>
    </v-col>

  </v-row>
</template>

<script>
import CustomModal from "@/components/shared/CustomModal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CustomModal
  },
  data() {
    return {
      confirmDeleteModel: false,
    }
  },
  computed: {
    ...mapGetters(["savedSearch"]),
    savedSearchList() {
      return this.savedSearch.filter(item => item && item.searchTitle);
    }
  },
  methods: {
    applySearch(searchParams) {
      this.$store.commit("appliedSearchParams", searchParams);
      (searchParams.params.priceRange) ?
        this.$router.push({ path: `/properties/${this.$i18n.locale}` })
        :
        this.$router.push({ path: `/projects/${this.$i18n.locale}` })
    },
    confirmDelete() {
      this.confirmDeleteModel = true;
    },
    CommitDelete(index) {
      this.confirmDeleteModel = false;
      this.$store.commit("deleteSavedSearch", index);
    }
  }
};
</script>

<style lang="scss" scoped>
.SavedSearch-Section {
  padding: 0px;
  background: var(--white);
  border: 1px solid rgba(232, 233, 234, 0.4);
  box-shadow: 0px 4px 14px rgba(233, 233, 233, 0.25);
  border-radius: 16px;
  margin-bottom: 24px;

  &__title {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 30px;
    color: var(--dark-primary);
  }
}

.search-Section-wrapper {
  padding: 0px 24px 24px 24px;

  &__row {
    margin-top: 16px;

    &__singleSection {
      background: var(--white);
      border: 1px solid #eeeeee;
      border-radius: 8px;
      margin-top: 8px;

      &__details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;

        p {
          margin-bottom: 0px;
        }

        &__Actions {
          display: flex;
          align-items: center;

          button {
            height: 32px;
          }

          button.applyAction {
            font-family: var(--font-en);
            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 18px;
            text-align: center;
            color: var(--primary);
            background: rgba(34, 0, 84, 0.04);
            border-radius: 4px;
            margin-inline: 10px;
            border-width: 0px;
            padding: 7px 11px;
          }
        }
      }
    }
  }

  &__labels {
    background: var(--border-color-03);
    border-radius: 8px;
    margin-bottom: 8px;

    .spacer {
      flex-grow: 0.75 !important;
    }

    p {
      margin: 0px;
      font-family: var(--font-en);
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 20px;
      color: var(--text-color-04);
    }

    .v-card__title {
      padding: 0px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .search-Section-wrapper {
    &__row {
      &__singleSection {
        &__details {
          &__Actions {
            button {
              height: 42px !important;
            }

            button.applyAction {
              padding: 1px 11px !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  .search-Section-wrapper {
    &__row {
      &__singleSection {
        &__details {
          &__Actions {
            button {
              height: 42px !important;
            }

            button.applyAction {
              padding: 1px 11px !important;
            }
          }
        }
      }
    }
  }
}
</style>