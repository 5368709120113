<template>
  <div class="unitCard">
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
      >
        <img
          v-if="details.blogImage"
          alt="img"
          width="100%"
          :src="require(`@/assets/img/${details.blogImage}.png`)"
        />
        <div class="details">
          <div>
            <div
              class="flex specDetails"
              :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'"
            >
              <img
                alt="location"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                class="shrink"
                contain
                :src="require(`@/assets/img/ico/locationGray.svg`)"
              />
              {{ details.location }}
            </div>
            <div class="flex specDetails">
              <img
                alt="location"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'"
                class="shrink"
                contain
                :src="require(`@/assets/img/ico/calendar-2.svg`)"
              />

              {{ details.date }}
            </div>
          </div>
          <h4 class="my-2">{{ details.title }}</h4>
          <div class="descContainer">
            <p>{{ details.description }}</p>
            <p>{{ details.description }}</p>
            <p>{{ details.description }}</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script >

export default {
  props: ["blogDetails"],
  components: {},
  data() {
    return {
      currentIndex: 0,
      details: {},
    };
  },
  mounted() {
    this.details = this.blogDetails[this.$route.params.id];
  },
};
</script>
<style scoped lang="scss">
.unitCard {
  background: white;
  border-radius: 15px;
  padding: 20px;
}
.details {
  position: relative;
  margin-top: 10px;
  height: 100%;
  h4 {
    font-weight: bold;
    color: var(--primary);
  }
  p {
    font-weight: normal;
    color: gray;
    line-height: 28px;
  }
  .descContainer {
    font-size: small;
    margin: 25px 0;
  }
}

.specDetails {
  color: gray;
  display: inline-flex;
  align-self: center;
}

.unitCard .row {
  width: 100%;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .propertiesContainer .unitCard {
    margin-top: 50px;
  }

  .unitCard {
    padding: 0px;
  }
}
</style>