<template>
  <div class="bg-bg-light">
    <CustomHeader :auth="auth" headerColor="primary" />
    <div class="mt-14 md:mt-16"></div>

    <section class="container pt-8 md:pt-10">
      <BreadCrumb :breadcrumbs="breadcrumbs" />
    </section>

    <main class="contact_page mt-3">
      <CommercialService />
    </main>
    <CustomFooter />
  </div>
</template>

<script>
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import CommercialService from "@/components/commercialService/CommercialService.vue";
import BreadCrumb from "@/components/shared/breadcrumb/BreadCrumb.vue";

export default {
  components: {
    CustomHeader,
    CustomFooter,
    BreadCrumb,
    CommercialService
  },
  data() {
    return {
      auth: true,
      breadcrumbs: [
        {
          title: this.$t("shared.home"),
          slug: `/${this.$route.params.lang}`
        },
        {
          title: this.$t("shared.CommercialService")
        }
      ]
    };
  }
};
</script>