<template>
  <v-stepper alt-labels elevation="0" :value="activeStep" class="shadow-lg">
    <v-stepper-header class="flex-col md:flex-row">
      <span v-for="step in steps" :key="step.id" class="flex flex-col md:flex-row items-center">
        <v-stepper-step :step="step.id" :complete="step.complete">{{step.title}}</v-stepper-step>
        <v-divider
          v-if="step.id !== steps.length"
          class="border-[1.5px] hidden md:block"
          :class="steps[step.id] && step.complete &&  steps[step.id].complete ? 'border-success bg-success' : 'border-bg-light bg-bg-light'"
        ></v-divider>
      </span>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: {
    steps: Array,
    activeStep: Number
  }
};
</script>

<style  >
.reqmeeting .v-stepper {
  border-radius: 12px !important;
}
.reqmeeting .v-stepper__step__step {
  background-color: var(--bg-light) !important;
  color: var(--color-text) !important;
  font-weight: bold;
}
.reqmeeting .v-stepper__step__step.primary {
  background-color: var(--color-success) !important;
  color: var(--white) !important;
}
.reqmeeting .v-stepper__label {
  font-size: 0.875rem;
  white-space: nowrap;
  font-weight: bold;
}

@media only screen and (max-width: 959.98px) {
  .reqmeeting .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: inline-block;
  }
  .reqmeeting .v-stepper--alt-labels .v-stepper__step {
    flex-basis: auto;
  }
}
@media only screen and (max-width: 560.98px) {
  .reqmeeting .v-stepper__step {
    padding: 1rem 0.75rem;
  }
  .reqmeeting .v-stepper--alt-labels .v-stepper__step {
    max-width: 80px;
  }
  .reqmeeting .v-stepper__label {
    white-space: unset;
    text-align: center !important;
    line-height: 1.4;
  }
}
</style>