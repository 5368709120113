<template>
  <AuthLayout :fullWidth="true">
    <AuthCard>
      <ForgotPassword />
    </AuthCard>
  </AuthLayout>
</template>

<script>
import ForgotPassword from "@/components/forgotpassword/forgotpasswordComponent.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import AuthCard from "@/components/auth/shared/AuthCard.vue";

export default {
  components: {
    ForgotPassword,
    AuthLayout,
    AuthCard
  }
};
</script>