import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import PropertiesView from "../views/PropertiesView.vue";
import Login from "../views/signin/SigninView.vue";
import Signup from "../views/signUp/SignUpView.vue";
import PartnerSignup from "../views/signUp/PartnerSignUpView.vue";
import Forgotpassword from "../views/passwordView/ForgotpasswordView.vue";
import EmailVerificationView from "../views/passwordView/EmailVerificationView.vue";
import NewPasswordView from "../views/passwordView/NewPasswordView.vue";
import SuccessPassChang from "../views/passwordView/SuccessPassChang.vue";
import Profile from "../views/user/ProfileView.vue";
import EditProfile from "../views/user/EditProfileView.vue";
import UserLayout from "../views/user/UserLayout.vue";
import Blogs from "../views/blogs/BlogsView.vue";
import SingleBlog from "../views/blogs/BlogDetailsView.vue";
import RequestMeeting from "../views/requestMeeting/RequestMeeting.vue";
import PropertyDetails from "../views/PropertyDetailsView.vue";
import UnitDetailsView from "../views/UnitDetailsView.vue";
import ProjectsView from "../views/ProjectsView.vue";
import DevelopersView from "../views/DevelopersView.vue";
import RelatorLayout from "../views/relator/RelatorLayout.vue";
import EditExpertise from "../views/user/EditExpertiseView.vue";
import LeadsView from "../views/user/LeadsView.vue";
import MyCILsView from "../views/user/MyCILsView.vue";
import MyPropertiesView from "../views/user/MyPropertiesView.vue";
import MyRequestsView from "../views/user/MyRequestsView.vue";
import WishListView from "../views/WishListView.vue";
import FAQView from "../views/FAQView.vue";
import ContactView from "../views/ContactView.vue";
import TermsConditionView from "../views/TermsConditionView.vue";
import PrivacyPolicyView from "../views/PrivacyPolicyView.vue";
import CommercialServiceView from "../views/CommercialServiceView.vue";
import MessagesView from "../views/user/MessagesView.vue";
import ProjectsDetailsView from "../views/ProjectsDetailsView.vue";
import NotificationsView from "../views/user/NotificationsView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/:lang?",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about/:lang?",
    name: "about",
    component: AboutView,
  },
  {
    path: "/commercial-service/:lang?",
    name: "commercialService",
    component: CommercialServiceView,
  },

  {
    path: "/contact/:lang?",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/user/",
    name: "user",
    component: UserLayout,
    children: [
      {
        path: "profile/:lang?",
        name: "userprofile",
        component: Profile,
      },
      {
        path: "editProfile/:lang?",
        name: "usereditProfile",
        component: EditProfile,
      },
      {
        path: "requests/:lang?",
        name: "requests",
        component: MyRequestsView,
      },
      {
        path: "wishList/:lang?",
        name: "wishList",
        component: WishListView,
      },
    ],
  },
  {
    path: "/realtor/",
    name: "realtor",
    component: RelatorLayout,
    children: [
      {
        path: "profile/:lang?",
        name: "realtorprofile",
        component: Profile,
      },
      {
        path: "editProfile/:lang?",
        name: "realtoreditProfile",
        component: EditProfile,
      },
      {
        path: "editexpertise/:lang?",
        name: "editexpertise",
        component: EditExpertise,
      },
      {
        path: "messages/:lang?",
        name: "messages",
        component: MessagesView,
      },
      {
        path: "myleads/:lang?",
        name: "myleads",
        component: LeadsView,
      },
      {
        path: "myCIL/:lang?",
        name: "myCIL",
        component: MyCILsView,
      },
      {
        path: "myproperties/:lang?",
        name: "MyProperties",
        component: MyPropertiesView,
      },
    ],
  },
  {
    path: "/notifications/:lang?",
    name: "notifications",
    component: NotificationsView,
  },
  {
    path: "/login/:lang?",
    name: "login",
    component: Login,
  },
  {
    path: "/properties/:lang?",
    name: "properties",
    component: PropertiesView,
  },
  {
    path: "/properties/:slug/:lang?",
    name: "propertyDetails",
    component: PropertyDetails,
  },
  {
    path: "/signup/:lang?",
    name: "signup",
    component: Signup,
  },
  {
    path: "/PartnerSignup/:lang?",
    name: "PartnerSignup",
    component: PartnerSignup,
  },
  {
    path: "/forgotpassword/:lang?",
    name: "forgotpassword",
    component: Forgotpassword,
  },
  {
    path: "/emailVerification/:lang?",
    name: "emailVerificationView",
    component: EmailVerificationView,
  },
  {
    path: "/newpassword/:lang?",
    name: "NewPassword",
    component: NewPasswordView,
  },
  {
    path: "/success/:lang?",
    name: "Success",
    component: SuccessPassChang,
  },
  {
    path: "/blogs/:lang?",
    name: "blogs",
    component: Blogs,
  },
  {
    path: "/blogs/:id?/:lang?",
    name: "siglebolg",
    component: SingleBlog,
  },
  {
    path: "/faq/:lang?",
    name: "faq",
    component: FAQView,
  },
  {
    path: "/contact/:lang?",
    name: "Aboutcontact",
    component: AboutView,
  },
  {
    path: "/terms/:lang?",
    name: "terms",
    component: TermsConditionView,
  },
  {
    path: "/policy/:lang?",
    name: "policy",
    component: PrivacyPolicyView,
  },
  {
    path: "/request-meeting/:lang?",
    name: "RequestMeeting",
    component: RequestMeeting,
  },
  {
    path: "/unit/:unit?/:lang?",
    name: "unit",
    component: UnitDetailsView,
  },
  {
    path: "/projects/:lang?",
    name: "projects",
    component: ProjectsView,
  },
  {
    path: "/projects/:project?/:lang?",
    name: "projectDetails",
    component: ProjectsDetailsView,
  },
  {
    path: "/developers/:lang?",
    name: "developers",
    component: DevelopersView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
