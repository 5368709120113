<template>
  <div>
    <h2
      class="text-lg"
      style="font-size: 1.125rem !important;"
    >{{$t('req_meeting.EnterPersonalInfo')}}</h2>
    <div class="mt-6 md:mt-8 mb-4 lg:mb-6 max-w-2xl">
      <div class="flex flex-col">
        <div>
          <label class="text-sm mb-2 block font-bold text-text">{{$t('req_meeting.Name')}}</label>
          <v-text-field transition="none" dense outlined :placeholder="$t('req_meeting.Name')" />
        </div>
        <div>
          <label class="text-sm mb-2 block font-bold text-text">{{$t('req_meeting.MobileNumber')}}</label>
          <v-text-field
            transition="none"
            dense
            outlined
            :placeholder="$t('req_meeting.MobileNumber')"
          />
        </div>
        <div>
          <label class="text-sm mb-2 block font-bold text-text">{{$t('req_meeting.Email')}}</label>
          <v-text-field transition="none" dense outlined :placeholder="$t('req_meeting.Email')" />
        </div>
        <div>
          <label class="text-sm mb-2 block font-bold text-text">{{$t('req_meeting.Comment')}}</label>
          <v-textarea
            transition="none"
            dense
            outlined
            :placeholder="$t('req_meeting.WriteComment')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: ["Appertment", "Villa"]
    };
  }
};
</script>