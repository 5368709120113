


















import { Component, Vue } from "vue-property-decorator";
@Component({
  props: {
    label: { default: "" },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Boolean, String, Number],
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
})
export default class CustomCheckbox extends Vue {
  handleChange(value: unknown) {
    this.$emit("data-changed", value);
  }
}
