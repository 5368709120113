<template>
  <div class="flex flex-col items-center mt-4">
    <div class="flex items-center gap-3">
      <img
        v-if="icon != null"
        alt="location"
        contain
        :src="require(`@/assets/img/ico/${icon}.svg`)"
      />
      <span class="font-bold">{{label}}</span>
    </div>
    <v-select
      :items="options"
      transition="none"
      flat
      dense
      solo
      class="custom__dropdown"
      :value="data"
      @keyup="keyup"
      @blur="handleBlur"
      @input="handleChange"
      append-icon="mdi-chevron-down"
      :menu-props="{'nudge-top' : -40}"
    >
      <template v-slot:item="{ item  }">
        <span class="text-sm">{{ item }}</span>
      </template>
    </v-select>
  </div>
</template>

<script >
export default {
  name: "CustomFilter",
  props: {
    data: String,
    label: String,
    icon: String,
    options: []
  },
  emits: ["update:value"],
  data() {
    return {};
  },
  methods: {
    handleChange(value) {
      this.$emit("data-changed", value);
    },
    keyup(event) {
      this.$emit("keyup", event);
    },

    handleBlur(e) {
      this.$emit("blur", e.target.value);
    }
  }
};
</script>

<style scoped lang="scss">
.custom__dropdown {
  max-width: 130px;
}
</style>

