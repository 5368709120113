<template>
  <div class="max-w-sm mx-auto">
    <div class="text-center mb-6">
      <img :src="require(`@/assets/login/Success.svg`)" alt="trueSign" />
      <h4 class="my-6">{{ $t("password.Successfully") }}</h4>
      <p>
        {{ $t("password.SuccessMessage") }}
        <br />
        {{ $t("password.SuccessMessage2") }}
      </p>
    </div>

    <div>
      <CustomButton
        class="newsletter"
        classes="text-base capitalize"
        large
        :click="ToSignIn"
        block
        :text="$t('password.SignIn')"
      />
    </div>

    <div class="pa-0 mt-4 text-center">
      <div class="backToSignAncor">
        <router-link :to="`/login/${$route.params.lang}`" tag="a">{{
          $t("password.BackToSignIn")
        }}</router-link>
      </div>
    </div>
  </div>
</template>
 
<script>
import CustomButton from "../shared/Button/CustomButton.vue";
export default {
  components: {
    CustomButton
  },
  data() {
    return {};
  },
  methods: {
    ToSignIn() {
      this.$router.push({ path: `/login/${this.$route.params.lang}` });
    }
  }
};
</script>

<style lang="scss" scoped>

.backToSignAncor {
  margin: 5px 0px;
  a {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: var(--secondary) !important;
    text-decoration: none !important;
  }
}
</style>