<template>
  <v-container>
    <v-row class="text-center orSignWith" justify="space-around">
      <v-col cols="4">
        <div class="line"></div>
      </v-col>
      <v-col cols="4">
        <p>{{ subheader }}</p>
      </v-col>
      <v-col cols="4">
        <div class="line"></div>
      </v-col>
    </v-row>
    <div class="flex items-center gap-4 md:gap-5 mt-6">
      <v-btn elevation="0" class="flex-grow border-gray-200" large outlined>
        <v-img
          class="max-w-[80px]"
          :src="require(`@/assets/login/google.svg`)"
          alt="zrelators"
        ></v-img>
      </v-btn>
      <v-btn elevation="0" class="flex-grow border-gray-200" large outlined>
        <v-img
          class="max-w-[80px]"
          :src="require(`@/assets/login/facebook.svg`)"
          alt="zrelators"
        ></v-img>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    title: String,
    subheader: String
  }
};
</script>

<style lang="scss" scoped>
.orSignWith {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .line {
    background-color: var(--bg-light);
    padding: 1px 0px;
    max-height: 1px;
  }
  p {
    color: var(--dark-gray);
    min-width: 100%;
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 500;
    font-size: 0.6875rem;
    line-height: 13px;
    margin: 1px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .orSignWith {
    .line {
      background-color: var(--bg-light);
      padding: 1px 0px;
      max-height: 1px;
    }
    p {
      min-width: 100%;
    }
  }
}
</style>