<template>
  <div class="max-w-sm mx-auto xl:max-w-md w-full">
    <div>
      <h3 class="pt-5 signin-header-wrapper">{{ $t("password.SignUp") }}</h3>
      <p class="mt-3">{{ $t("LogIn.EazySolution") }}</p>
    </div>
    <v-form>
      <CustomTextInput
        placeholder="Name"
        class="customTextFieldInput"
        :label="$t('password.name')"
        @data-changed="formData.name = $event"
        :data="formData.name"
        :rules="[rules.required]"
      />

      <CustomTextInput
        placeholder="Mobile Number"
        class="customTextFieldInput"
        :label="$t('password.phone')"
        :data="formData.phone"
        @data-changed="formData.phone = $event"
        :rules="[rules.required]"
      />

      <CustomTextInput
        placeholder="example@gmail.com"
        class="customTextFieldInput"
        :label="$t('password.email')"
        @data-changed="formData.email = $event"
        :data="formData.email"
        :rules="[rules.required, rules.email]"
      />

      <CustomTextInput
        placeholder="password"
        :data="formData.password"
        @data-changed="formData.password = $event"
        class="customTextFieldInput"
        :label="$t('password.password')"
        :rules="[rules.required, rules.min]"
        :type="showPass ? 'text' : 'password'"
        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
        :appendIconClick="showHidePassword"
      />

      <CustomTextInput
        placeholder="Confirm password"
        :data="formData.passwordConfirm"
        @data-changed="formData.passwordConfirm = $event"
        class="customTextFieldInput"
        :label="$t('password.ConfirmPassword')"
        :rules="[
          rules.required,
          rules.min,
          rules.confirmPassword(formData.password, formData.passwordConfirm),
        ]"
        :type="showConfirm ? 'text' : 'password'"
        :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        :appendIconClick="showHideConfirmPassword"
      />
      <CustomButton
        class="custom-btn"
        classes="text-base capitalize"
        block
        large
        type="submit"
        :click="CreateNewAccount"
        :text="$t('password.CreateAccount')"
      />

      <p class="HaveAcountMessage text-center mt-5">
        {{ $t("password.HaveAccountMessage") }}
        <router-link
          :to="`/login/${$route.params.lang}`"
          tag="a"
        >
          {{ $t("password.SignIn") }}
        </router-link>
      </p>

      <SocialMediaRegister :subheader="$t('password.OrSignUp')" />
    </v-form>
  </div>
</template>
 
<script>
import SocialMediaRegister from "../shared/SocialMediaRegisterSection.vue";
import CustomButton from "../shared/Button/CustomButton.vue";
import CustomTextInput from "../shared/customTextInput/CustomTextInput.vue";
import rules from "@/services/rules";

export default {
  components: {
    CustomButton,
    CustomTextInput,
    SocialMediaRegister
  },
  data() {
    return {
      rules,
      formData: {
        name: "",
        phone: "",
        email: "",
        password: "",
        passwordConfirm: ""
      },

      showPass: false,
      showConfirm: false
    };
  },
  methods: {
    showHidePassword() {
      this.showPass = !this.showPass;
    },
    showHideConfirmPassword() {
      this.showConfirm = !this.showConfirm;
    },
    CreateNewAccount() {
      console.log("CreateNewAccount", this.formData);
    }
  }
};
</script>

<style lang="scss" scoped>
.row + .row {
  margin-top: 8px;
}
.signin-header-wrapper {
  color: var(--btn-bg);
  .HaveAcountMessage {
    font-family: var(--font-en);
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: var(--text-mute);
  }
}
.Sign-in-explore-text {
  padding: 12px 100px 0px 90px;
  font-weight: 500;
  font-family: var(--font-en);
  font-style: normal;
  font-size: 1.25rem;
  line-height: 24px;
  color: var(--white);
}
.signture-image {
  position: absolute;
  bottom: 77px;

  &.ar {
    right: 100px;
  }
  &.en {
    left: 100px;
  }
}
@media only screen and (max-width: 959px) {
  .signin-background {
    display: none;
  }
  .signin-header-wrapper {
    color: var(--btn-bg);
    text-align: center;
  }
  .custom-btn {
    padding: 15px 0px 16px;
  }
}
</style>