<template>
  <div class="latestContainer">
      <div class="latestTitle">
          <h4 class="text-uppercase">{{$t('home.newsUpdate')}}</h4>
          <h3>{{$t('home.newsUpdate2')}}</h3>
      </div>
      <v-row no-gutters>
            <v-col cols="12" md="8" sm="12" xs="12">
                <v-row no-gutters>
                    <v-col cols="12" md="6" sm="12" v-for="card in cards" :key="card.id" class="mt-5 mb-md-5 mb-sm-2">
                        <latest-card :card="card"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-col  cols="12" md="4" sm="12" xs="12">
                <v-col class="px-0 py-0" cols="12">
                    <div class="eventCards mt-md-5 mt-sm-2">
                        <event-card v-for="event in events" :key="event.id" :event="event" />
                    </div>
                </v-col>
          </v-col>
      </v-row>
  </div>
</template>

<script >
import EventCard from '../EventCard.vue';
import LatestCard from './LatestCard.vue';

export default {
  name:'LatestContainer',
  components:{
    LatestCard,
    EventCard
  },
  data() {
    return {
        monthNames: ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ],
        cards:[
            {
                id: 0,
                cardImage:'villa3',
                title: this.$i18n.locale == 'ar' ? 'سيتي سكيب مصر 2022' : 'Cityscape Egypt 2022',
                description: this.$i18n.locale == 'ar' ? 'مؤتمر سيتي سكيب الافتراضي هو المؤتمر العقاري' : 'The Cityscape Virtual Conference is the real estate conference',
                location:'New Cairo, Egypt',
                availableDate: new Date(),
                buttonTitle:'readMore'
            },
            {
                id: 1,
                cardImage:'villa4',
                title: this.$i18n.locale == 'ar' ? 'سيتي سكيب مصر 2022' : 'Cityscape Egypt 2022',
                description: this.$i18n.locale == 'ar' ? 'مؤتمر سيتي سكيب الافتراضي هو المؤتمر العقاري' : 'The Cityscape Virtual Conference is the real estate conference',
                location:'New Cairo, Egypt',
                availableDate: new Date(),
                buttonTitle:'readMore'
            },
            
        ],
        events: [ 
            {
                id: 0,
                description: this.$i18n.locale == 'ar' ? 'حدث جديد في القاهرة ينطلق قريبا' : 'New event in cairo is launching soon',
                eventDate: { day: '30', month: 'June'},
                eventColor: '#E3F0FC',
                textColor: '#649BC0'
            },
            {
                id: 1,
                description: this.$i18n.locale == 'ar' ? 'مستقبل الاستثمار بالعاصمة الادارية الجديدة' : 'The future of investment in the new administrative capital',
                eventDate: { day: '16', month: 'June'},
                eventColor: '#E7DCF7',
                textColor: '#8965BD'
            },
            {
                id: 2,
                description: this.$i18n.locale == 'ar' ? 'كيف تستثمر في المدن الجديدة' : 'How to invest in new cities',
                eventDate: { day: ('12'), month: 'June'},
                eventColor: '#F6F8D4',
                textColor: '#6E7230'
            },
            {
                id: 3,
                description: this.$i18n.locale == 'ar' ? 'إطلاق جديد في 6 أكتوبر !!' : 'New launches in 6 October !!',
                eventDate: { day: '05', month: 'June'},
                eventColor: '#F4E8E8',
                textColor: '#D86666'
            },
            
        ],
        
    }
  }

}
</script>

<style scoped lang="scss">
 .latestContainer {
     background: var(--light-gray-bg);
     padding: 50px 150px;
 }
     
 .latestTitle {
     margin-bottom: 20px;
 }
 .latestTitle h3 {
    color: var(--primary);
    text-align: center;
 }
 .latestTitle h4  {
    color: var(--secondary);
    text-align: center;
    font-weight: normal;
 }
 .eventCards {
    margin-bottom: 50px;
    height: 100%;
    width: calc(100% - 10%);
    margin: 0 auto;
 }
 @media only screen and (max-width: 600px) {
        .latestContainer {
            padding: 50px 30px;
        }
        .eventCards {
            margin-bottom: 50px;
            height: 100%;
            width:100%;
            margin: 0 auto;
        }
    }
</style>
