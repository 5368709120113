import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./services/i18n/i18n";
import ApiService from "@/services/api.service";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/styles/tailwind.css";
import VueCompositionAPI from "@vue/composition-api";
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSocialSharing);
Vue.use(VueCompositionAPI);
Vue.config.productionTip = false;

ApiService.init();

new Vue({
  router,
  store,
  vuetify,
  i18n,

  render: (h) => h(App),
}).$mount("#app");
