<template>
  <div class="container">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-5">
      <div class="col-span-1 hidden md:block"></div>
      <div class="md:col-span-2 lg:col-span-3">
        <ProjectsFilters
          @handleFilter="filterElements"
          :recordsCount="recordsCount"
        />
      </div>
      <div class="projects__search col-span-1">
        <ProjectsSearch
          v-if="this.$vuetify.breakpoint.mdAndUp"
          @search="searchHandle"
          @saveSearch="saveSearchHandle"
          :appliedSearch="appliedSearch"
        />
        <v-expansion-panels
          v-else
          flat
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="p-4 bg-bg-dark text-text-dark text-lg font-bold">{{$t('projects.AdvancedSearch')}}</v-expansion-panel-header>
            <v-expansion-panel-content class="p-0 first:p-0">
              <ProjectsSearch
                @search="searchHandle"
                @saveSearch="saveSearchHandle"
                :appliedSearch="appliedSearch"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="md:col-span-2 lg:col-span-3">
        <section>
          <div class="project__cards grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <ProjectCard
              v-for="project in displayedCards"
              :key="project.id"
              :project="project"
            />
          </div>
          <div class="mt-6">
            <v-pagination
              v-model="page"
              :length="Math.ceil(recordsCount / itemsPerPage)"
              @input="setPaginateItems"
              class="mb-7"
            ></v-pagination>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectsSearch from "./ProjectsSearch.vue";
import ProjectsFilters from "./ProjectsFilters.vue";
import ProjectCard from "./ProjectCard.vue";
import { mapGetters } from "vuex";

export default {

  components: { ProjectsSearch, ProjectsFilters, ProjectCard },
  computed: {
    ...mapGetters(["projects", "appliedSearch"]),
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 6,
      recordsCount: 0,
      displayedCards: [],
      filteredCards: [],
    };
  },
  mounted() {
    this.filteredCards = this.projects;
    this.recordsCount = this.filteredCards.length;
    this.displayedCards = this.filteredCards.filter(
      (x, index) => index < this.itemsPerPage
    );
    if (this.appliedSearch && this.appliedSearch.params) {
      this.searchHandle(this.appliedSearch.params)
    }
  },
  methods: {
    setPaginateItems(pageIndex) {
      this.page = pageIndex;
      this.displayedCards = this.filteredCards.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );
    },
    searchHandle(ProjectSearchFilter) {
      const filteredCards = this.projects.filter(
        (project) =>
          (!ProjectSearchFilter["location"] ||
            (ProjectSearchFilter["location"] &&
              project.location.toLowerCase() == ProjectSearchFilter["location"].toLowerCase())) &&
          (!ProjectSearchFilter["prjectType"] ||
            (ProjectSearchFilter["prjectType"] &&
              project.prjectType.toLowerCase() == ProjectSearchFilter["prjectType"].toLowerCase())) &&
          (!ProjectSearchFilter["finishing"] ||
            (ProjectSearchFilter["finishing"] &&
              project.finishing.toLowerCase() == ProjectSearchFilter["finishing"].toLowerCase()))
          &&
          ((ProjectSearchFilter["priceValue"][0] == 0 &&
            ProjectSearchFilter["priceValue"][1] == 0) ||
            (project.startingPrice >= ProjectSearchFilter["priceValue"][0] &&
              project.startingPrice <= ProjectSearchFilter["priceValue"][1]))
          &&
          ((ProjectSearchFilter["areaValue"][0] == 0 &&
            ProjectSearchFilter["areaValue"][1] == 0) ||
            (project.specs[2].info.value >= ProjectSearchFilter["areaValue"][0] &&
              project.specs[2].info.value <= ProjectSearchFilter["areaValue"][1]))
          &&
          ((ProjectSearchFilter["areaValue"][0] == 0 &&
            ProjectSearchFilter["areaValue"][1] == 0) ||
            (project.specs[2].info.value >= ProjectSearchFilter["areaValue"][0] &&
              project.specs[2].info.value <= ProjectSearchFilter["areaValue"][1]))

      );
      this.recordsCount = filteredCards.length;
      this.filteredCards = filteredCards;
      this.setPaginateItems(1);
    },
    filterElements(value) {
      if (value == "All") {
        this.displayedCards = this.projects
      } else {
        this.displayedCards = this.projects.filter(
          (project) => project.prjectType.includes(value)
        );
      }
      this.recordsCount = this.displayedCards.length;
    },
    saveSearchHandle(ProjectSearchFilter) {
      this.$store.commit("saveSearchParams", ProjectSearchFilter);
    },
  },

};
</script>

<style  >
.projects__search .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>