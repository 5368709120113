<template>
  <div class="bg-bg-light">
    <CustomHeader :auth="auth" headerColor="primary" />
    <div class="containerPadding"></div>
    <main class="req_meeting_page pt-16 pb-10 lg:pb-12">
      <div class="container mt-4 md:mt-8">
        <BackToHome />
      </div>
      <div class="container mt-4 md:mt-8">
        <PropertySlider />
      </div>
      <div class="container mt-4 md:mt-8">
        <div
          class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-5 lg:gap-6 grid-flow-row-dense"
        >
          <div class="md:col-start-3 md:col-end-4 lg:col-start-4 lg:col-end-5">
            <div class="w-full flex flex-col gap-4 md:gap-5 lg:gap-6">
              <div>
                <PropertyActions />
              </div>
              <div class="w-full hidden md:block">
                <img width="100%" :src="require(`@/assets/img/ads293.png`)" />
              </div>
            </div>
          </div>
          <div class="md:col-start-1 md:col-end-3 lg:col-start-2 lg:col-end-4">
            <PropertyContent />
          </div>
          <div class="md:col-span-full lg:col-start-1 lg:col-end-2">
            <div>
              <!-- mobile add  -->
              <div class="lg:hidden">
                <img width="100%" :src="require(`@/assets/img/ads919.png`)" />
              </div>
              <!-- big screens  -->
              <div class="hidden lg:block">
                <img width="100%" :src="require(`@/assets/img/ads293.png`)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <OtherProperties />
      </div>
    </main>
    <CustomFooter />
  </div>
</template>

<script>
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import BackToHome from "@/components/shared/BackToHome.vue";
import PropertyContent from "@/components/PropertyDetails/PropertyContent.vue";
import PropertyActions from "@/components/PropertyDetails/PropertyActions.vue";
import OtherProperties from "@/components/PropertyDetails/OtherProperties.vue";
import PropertySlider from "@/components/PropertyDetails/PropertySlider.vue";

export default {
  components: {
    CustomHeader,
    CustomFooter,
    BackToHome,
    PropertyContent,
    PropertyActions,
    OtherProperties,
    PropertySlider
  },
  data(){
    return{
      auth:true,
    }
  }
};
</script>