<template>
  <div class="faqContainer">
    <CustomHeader headerColor="primary" />
    <v-container class="pt-12 sm:pt-12 md:pt-[100px]">
     
      <section class="container pt-8 md:pt-10">
        <bread-crumb :breadcrumbs="breadcrumbs" />
      </section>
      <div class="faqContainer__inner-container bg-white py-9">
        <div class="w-full text-center">
          <h5>{{ $t("faq.frequentlyAskedQuestions") }}</h5>
          <p
            class="
              headingText
              w-4/5
              sm:w-3/4
              md:w-1/2
              lg:w-1/2
              m-auto
              text-gray-500
            "
          >
            {{ $t("faq.FAQText") }}
          </p>
        </div>
        <div class="w-4/5 sm:w-11/12 md:w-3/5 lg:w-3/5 m-auto">
          <v-expansion-panels v-model="panel" class="shadow-none">
            <v-expansion-panel
              v-for="item in questions"
              :key="item.id"
              class="shadow-none mb-2 rounded-lg"
            >
              <v-expansion-panel-header
                class="rounded-lg"
                expand-icon="mdi-menu-down"
              >
                <span class="font-bold text-primary">{{ item.question }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-gray-500">
                {{ item.answer }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-container>
    <CustomFooter />
  </div>
</template>

<script >
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import BreadCrumb from '@/components/shared/breadcrumb/BreadCrumb.vue';

export default {
  components: {
    CustomHeader,
    CustomFooter,
    BreadCrumb,
  },
  data() {
    return {
      panel: [],
       breadcrumbs: [
        {
          title: this.$t("shared.home"),
          slug: `/${this.$route.params.lang}`
        },
        {
          title: this.$t("faq.FAQ")
        }
      ],
      questions: [
        {
          id: 0,
          question: 
          this.$i18n.locale == "ar" ? "هل يمكنني تخطي التسليم؟" : "Can I skip a delivery?",
          answer:
          this.$i18n.locale == "ar" ? "اجابة هذا السؤال" : "Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.",
        },
        {
          id: 1,
          question:
          this.$i18n.locale == "ar" ? "متى سوف أتلقى طلبي؟" : "When will I receive my order?",
          answer:
            "Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.",
        },
        {
          id: 2,
          question:
          this.$i18n.locale == "ar" ? "هل يمكنني تخطي التسليم؟" : "Can I skip a delivery?",
          answer:
            "Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.",
        },
        {
          id: 3,
          question:          
           this.$i18n.locale == "ar" ? "متى سوف أتلقى طلبي؟" : "When will I receive my order?",
          answer:
            "Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.",
        },
        {
          id: 4,
          question: 
          this.$i18n.locale == "ar" ? "هل يمكنني تخطي التسليم؟" : "Can I skip a delivery?",
          answer:
            "Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.",
        },
        {
          id: 5,
          question: 
          this.$i18n.locale == "ar" ? "هل يمكنني تخطي التسليم؟" : "Can I skip a delivery?",
          answer:
            "Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.",
        },
      ],
    };
  },

  methods: {},
};
</script>

<style scoped>

.faqContainer {
  background: var(--light-gray-bg-01);
}

.faqContainer .breadcrumb {
  margin-bottom: 46px !important;
  margin-top: 10px;
}

.faqContainer .faqContainer__inner-container {
  border-radius: 20px;
}

.faqContainer .headingText {
  margin-bottom: 37px;
  margin-top: 10px;
}
.faqContainer .back {
  text-decoration: none;
  color: var(--text-color-01);
}
.faqContainer .v-expansion-panel::before {
  box-shadow: none !important;
  border: none;
}
.faqContainer .v-expansion-panel {
  border: 1px solid var(--border-color-02);
}
.faqContainer .v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none;
}
</style>
