<template>
  <div class="featuredCard">
    <v-row>
      <v-col class="blogImg" v-for="image in card.cardImage" :key="image" cols="12">
        <img :class="imgGridCont" width="100%" :src="require(`@/assets/img/${image}.png`)" alt />
      </v-col>
      <v-col cols="12" :md="detailsmdCols" sm="12" xs="12">
        <div class="details" :class="contentStyle">
          <div class="d-inline-flex">
            <div class="flex specDetails">
              <img
                alt="location"
                :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-1'"
                class="shrink"
                contain
                :src="require(`@/assets/img/ico/locationGray.svg`)"
              />
              {{ card.location }}
            </div>
            <div class="flex specDetails">
              <img
                alt="location"
                :class="$i18n.locale == 'ar' ? 'ml-1 mr-1' : 'mr-1 ml-1'"
                class="shrink"
                contain
                :src="require(`@/assets/img/ico/calendar-2.svg`)"
              />
              {{ card.date }}
            </div>
          </div>
          <h4 class="mb-2" :class="titleStyle">{{ card.title }}</h4>

          <div class="flex mt-5 mb-5" :class="specContainer">
            <p>{{ $t("blogs.BlogPreviewText") }}</p>
          </div>

          <div class="btnContainer" :class="btnContainer" v-if="card.buttonTitle != null">
            <router-link
              color="primary"
              :to="`/blogs/${card.id}/${$route.params.lang}`"
              small
              class="py-3 px-3 mr-3 ml-3"
              :class="$i18n.locale == 'ar' ? 'float-left' : 'float-right'"
            >{{ $t("home." + card.buttonTitle) }}</router-link>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script >
export default {
  name: "BlogsCard",
  props: [
    "card",
    "titleStyle",
    "contentStyle",
    "detailsmdCols",
    "specContainer",
    "btnContainer",
    "imgGridCont"
  ],
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.featuredCard {
  background: var(--white);
  border-radius: 15px;
  margin-block: 10px;
  .blogImg {
    border-radius: 16px;
  }

  .details {
    position: relative;
    height: 100%;
  }
  .details h5 {
    font-weight: bold;
    color: var(--primary);
  }
  .details p {
    font-weight: normal;
    color: var(--text-mute-01);
    line-height: 28px;
  }
  .specDetails {
    font-size: 0.75rem;
    color: var(--text-mute-01);
    display: inline-flex;
    padding-right: 1px;
    align-self: center;
  }
}
.featuredCard .row {
  width: 100%;
  margin: 0;
}
.btnContainer {
  width: 100%;
}
.contentStyle {
  height: 90% !important;
}

.imgGridCont {
  height: 200px !important;
  image-rendering: pixelated;
}
@media only screen and (max-width: 600px) {
  .details {
    padding-bottom: 30px;
  }
  .propertiesContainer .featuredCard {
    margin-top: 50px;
  }
}
</style>
