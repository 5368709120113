















































import { Component, Vue } from "vue-property-decorator";
import CustomLabel from "@/components/shared/customLabel/CustomLabel.vue";

@Component({
  components: { CustomLabel },
  props: {
    classes: { default: "" },
    items: {
      type: Array,
      default: () => [],
    },
    label: { default: "" },
    labelClass: { default: "" },
    data: {
      type: [String, Number, Boolean, Array, Object],
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    appendedIcon: {
      type: String,
      default: "mdi-chevron-down",
    },
  },
})
export default class CustomSelect extends Vue {
  handleChange(value: unknown) {
    this.$emit("data-changed", value);
  }
}
