<template>
  <v-col
    class="profile-info-wrapper"
    cols="11"
    md="9"
    xl="9"
  >
    <ProfileInfo :user="user" />
    <Statistics />
    <SavedSearch v-if="this.$route.path === `/user/profile/${$route.params.lang}`" />
    <WorkExperience
      v-if="this.$route.path === `/realtor/profile/${$route.params.lang}`"
      :data="user.workExprience"
    />
    <Experties
      v-if="this.$route.path === `/realtor/profile/${$route.params.lang}`"
      :data="user.Expertise"
    />
  </v-col>
</template>

<script>
import ProfileInfo from "./profileInfoComponent.vue";
import Statistics from "./statisticsComponent.vue";
import SavedSearch from "./savedSearchComponent.vue";
import Experties from "./expertiseComponent.vue";
import WorkExperience from "./workExprienceComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ProfileInfo,
    Statistics,
    SavedSearch,
    WorkExperience,
    Experties,
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 959px) {
  .profile-info-wrapper {
    margin: auto;
    padding-top: 20px !important;
  }
}
</style>