<template>
  <div class="slider-modal text-center">
    <v-dialog v-model="dialog" width="500" content-class="rounded-xl">
      <div
        :class="['slider-modal__slider-container', classes ? classes : '']"
        v-if="items && items.length"
      >
        <slider-gallery :options="items" :selectedIndex="selectedIndex" />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import SliderGallery from "../shared/SliderGallery.vue";

export default {
  components: {
    SliderGallery,
  },
  props: ["items", "isVisible", "selectedIndex", "classes"],
  data() {
    return {};
  },
  methods: {},
  computed: {
    dialog: {
      get() {
        return this.isVisible;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-modal {
  &__slider-container {
    position: relative;
  }
}
</style>