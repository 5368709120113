<template>
  <div>
    <CustomHeader headerColor="transparent" />
    <div class="home">
      <div
        alt="home background"
        id="homeBack"
        class="background-img"
      >
        <CustomTabs class="tabClass" />
        <img
          alt="img"
          class="shrink arrow"
          width="35px"
          contain
          :src="require(`@/assets/img/ico/downArrow.svg`)"
          @click="scrollToView('features')"
        />
      </div>
      <sponsers-bar id="sponsorBar" />
      <details-card
        :post="homePosts[0]"
        imageClass="shadowStyle"
      />
      <featured-container id="features" />
      <details-card
        :post="homePosts[1]"
        contentClass="marginStyle"
      />
      <latest-container />
    </div>
    <CustomFooter />
  </div>
</template>

<style scoped>
.background-img {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-size: cover;
  overflow: hidden;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.792) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url("../assets/img/homeBackground.webp");
}

@media only screen and (max-width: 600px) {
  .background-img {
    min-height: 700px;
  }
  .tabClass {
    margin-top: 50% !important;
    width: 90% !important;
  }
}

.tabClass {
  width: fit-content;
  margin: auto;
  padding: 0;
  margin-top: 40vh;
}
.arrow {
  position: absolute;
  bottom: 50px;
  right: calc(50% - 17.5px);
  cursor: pointer;
}
</style>

<script >
import CustomHeader from "@/components/header/header.vue";
import CustomFooter from "@/components/footer/footer.vue";
import CustomTabs from "@/components/homeComponents/customTabs/CustomTabs.vue";
import SponsersBar from "@/components/homeComponents/SponsersBar.vue";
import DetailsCard from "@/components/homeComponents/DetailsCard.vue";
import FeaturedContainer from "@/components/homeComponents/featuredCards/FeaturedContainer.vue";
import LatestContainer from "@/components/homeComponents/latestCards/LatestContainer.vue";
export default {
  data() {
    return {
      homePosts: [
        {
          title: `post1`,
          description: "post2Description",
          cardImage: "building",
          buttonTitle: "readMore"
        },
        {
          title: "post2",
          description: `postDescription2`,
          cardImage: "mobiles",
          buttonTitle: "contactUs"
        }
      ]
    };
  },
  methods: {
    scrollToView(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    }
  },
  components: {
    CustomHeader,
    CustomTabs,
    SponsersBar,
    DetailsCard,
    FeaturedContainer,
    LatestContainer,
    CustomFooter
  }
};
</script>
