<template>
  <main>
    <div class="container">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-5">
        <DevelopersCard v-for="developer in developers" :key="developer.id" :developer="developer" />
      </div>
      <div class="mt-10 mb-24 flex justify-center">
        <v-btn
          isDense
          elevation="0"
          class="flex-grow-0 rounded-lg px-10 md:px-12 leading-tight normal-case"
          color="primary"
          large
        >{{$t('developers.ShowMore')}}</v-btn>
      </div>
    </div>
  </main>
</template>

<script>
import DevelopersCard from "./DevelopersCard.vue";
export default {
  components: { DevelopersCard },
  data() {
    return {
      developers: [
        {
          key: 1,
          name: "developer 1",
          image: "Business.png"
        },
        {
          key: 2,
          name: "developer 2",
          image: "Entrepreneur.png"
        },
        {
          key: 3,
          name: "developer 3",
          image: "Fortune.png"
        },
        {
          key: 4,
          name: "developer 4",
          image: "Microsoft.png"
        },
        {
          key: 5,
          name: "developer 1",
          image: "Business.png"
        },
        {
          key: 6,
          name: "developer 2",
          image: "Entrepreneur.png"
        },
        {
          key: 7,
          name: "developer 3",
          image: "Fortune.png"
        },
        {
          key: 8,
          name: "developer 4",
          image: "Microsoft.png"
        },
        {
          key: 9,
          name: "developer 1",
          image: "Business.png"
        },
        {
          key: 10,
          name: "developer 2",
          image: "Entrepreneur.png"
        },
        {
          key: 11,
          name: "developer 3",
          image: "Fortune.png"
        },
        {
          key: 12,
          name: "developer 4",
          image: "Microsoft.png"
        }
      ]
    };
  }
};
</script>